import loadable from '@loadable/component';
import { ConnectedRouter } from 'connected-react-router';
// import 'moment';
// import 'moment-timezone';
import { PersistGate } from 'redux-persist/integration/react';

import React from 'react';

import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Context as ResponsiveContext } from 'react-responsive';
import { Switch, Route, StaticRouter } from 'react-router-dom';

import { setupInterceptors } from 'services/api';

import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import PrivateRoute from 'components/Routes/PrivateRoute';
import PublicRoute from 'components/Routes/PublicRoute';

import './App.scss';
import i18n, { LANG_REGEX } from './i18n/i18n';
import { store, persistor, history, hitPage } from './store/configureStore';

const AboutForm = loadable(() => import('pages/AboutForm'), { fallback: <Loading fullPage /> });
const Artwork = loadable(() => import('pages/Artwork'), { fallback: <Loading fullPage /> });
const ArtworkForm = loadable(() => import('pages/ArtworkForm'), { fallback: <Loading fullPage /> });
const Discover = loadable(() => import('pages/Discover'), { fallback: <Loading fullPage /> });
const Favorites = loadable(() => import('pages/Favorites'), { fallback: <Loading fullPage /> });
const Flowers = loadable(() => import('pages/Flowers'), { fallback: <Loading fullPage /> });
const FlowForm = loadable(() => import('pages/FlowForm'), { fallback: <Loading fullPage /> });
const Forgot = loadable(() => import('pages/Forgot'), { fallback: <Loading fullPage /> });
const Home = loadable(() => import('pages/Home'), { fallback: <Loading fullPage /> });
const Login = loadable(() => import('pages/Login'), { fallback: <Loading fullPage /> });
const Messages = loadable(() => import('pages/Messages'), { fallback: <Loading fullPage /> });
const NewsForm = loadable(() => import('pages/NewsForm'), { fallback: <Loading fullPage /> });
const NewsPage = loadable(() => import('./pages/NewsPage'), { fallback: <Loading fullPage /> });
const NoMatch404 = loadable(() => import('pages/NoMatch404'), { fallback: <Loading fullPage /> });
const PackForm = loadable(() => import('pages/PackForm'), { fallback: <Loading fullPage /> });
const Payment = loadable(() => import('pages/Payment'), { fallback: <Loading fullPage /> });
const PlayerPage = loadable(() => import('pages/PlayerPage'), { fallback: <Loading fullPage /> });
const ProfileMe = loadable(() => import('pages/User/Me'), { fallback: <Loading fullPage /> });
const Register = loadable(() => import('pages/Register'), { fallback: <Loading fullPage /> });
const ResetPassword = loadable(() => import('pages/ResetPassword'), { fallback: <Loading fullPage /> });
const Settings = loadable(() => import('pages/Settings'), { fallback: <Loading fullPage /> });
const User = loadable(() => import('pages/User'), { fallback: <Loading fullPage /> });

const AdultPage = loadable(() => import('pages-html/Adult'), { fallback: <Loading fullPage /> });
const AdultPageFR = loadable(() => import('pages-html/fr/Adult'), { fallback: <Loading fullPage /> });
const ContactPage = loadable(() => import('pages-html/Contact'), { fallback: <Loading fullPage /> });
const ContactPageFR = loadable(() => import('pages-html/fr/Contact'), { fallback: <Loading fullPage /> });
const ContentPolicyPage = loadable(() => import('pages-html/ContentPolicy'), { fallback: <Loading fullPage /> });
const ContentPolicyPageFR = loadable(() => import('pages-html/fr/ContentPolicy'), { fallback: <Loading fullPage /> });
const PricesPage = loadable(() => import('pages-html/Prices'), { fallback: <Loading fullPage /> });
const PricesPageFR = loadable(() => import('pages-html/fr/Prices'), { fallback: <Loading fullPage /> });
const TermsAndConditionsPage = loadable(() => import('pages-html/TermsAndConditions'), {
  fallback: <Loading fullPage />,
});
const TermsAndConditionsPageFR = loadable(() => import('pages-html/fr/TermsAndConditions'), {
  fallback: <Loading fullPage />,
});
const ThankYouPage = loadable(() => import('pages-html/ThankYou'), { fallback: <Loading fullPage /> });
const ThankYouPageFR = loadable(() => import('pages-html/fr/ThankYou'), { fallback: <Loading fullPage /> });
const RainbowPage = loadable(() => import('pages-html/Rainbow'), { fallback: <Loading fullPage /> });
const DareXPage = loadable(() => import('pages-html/DareX'), { fallback: <Loading fullPage /> });
const RainbowPageFR = loadable(() => import('pages-html/fr/Rainbow'), { fallback: <Loading fullPage /> });
const ConfirmedEmailPage = loadable(() => import('pages-html/ConfirmedEmail'), { fallback: <Loading fullPage /> });
const ConfirmedEmailPageFR = loadable(() => import('pages-html/fr/ConfirmedEmail'), { fallback: <Loading fullPage /> });
const UnsubscribedNewsletterPage = loadable(() => import('pages-html/UnsubscribedNewsletter'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterPageFR = loadable(() => import('pages-html/fr/UnsubscribedNewsletter'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterDareXPage = loadable(() => import('pages-html/UnsubscribedNewsletterDareX'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterLGBTQPage = loadable(() => import('pages-html/UnsubscribedNewsletterLGBTQ'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterLGBTQPageFR = loadable(() => import('pages-html/fr/UnsubscribedNewsletterLGBTQ'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterCreatorPage = loadable(() => import('pages-html/UnsubscribedNewsletterCreator'), {
  fallback: <Loading fullPage />,
});
const UnsubscribedNewsletterCreatorPageFR = loadable(() => import('pages-html/fr/UnsubscribedNewsletterCreator'), {
  fallback: <Loading fullPage />,
});
const WelcomeEN = loadable(() => import('pages-html/Welcome'), { fallback: <Loading fullPage /> });
const WelcomeFR = loadable(() => import('pages-html/fr/Welcome'), { fallback: <Loading fullPage /> });

type Props = {
  store: Object,
  context: Object,
  serverLocation: String,
  isMobile: Boolean,
};

const App = ({ store: serverStore, context: serverContext, serverLocation, isMobile }: Props) => {
  const routes = () => (
    <React.StrictMode>
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/reset/:token" component={ResetPassword} />
            <Route exact path="/discover" component={Discover} />
            <Route exact path="/discover/:category" component={Discover} />

            <PrivateRoute exact path="/profile/settings" component={Settings} />
            <PrivateRoute exact path="/profile/payment" component={Payment} />
            <PrivateRoute exact path="/profile/about/edit" component={AboutForm} />
            <Route exact path="/profile/me" component={ProfileMe} />
            <Route exact path="/profile/:id" component={User} />

            <PrivateRoute exact path="/artwork/add" component={ArtworkForm} />
            <PrivateRoute path="/artwork/edit/:id" component={ArtworkForm} />
            <Route exact path="/artwork/:userName/:artworkName/news">
              <Artwork isNews />
            </Route>
            <Route path="/artwork/:userName/:artworkName/:folderType/:id" component={Artwork} />
            <Route exact path="/artwork/:userName/:artworkName" component={Artwork} />
            <Route exact path="/artwork/:artworkName" component={Artwork} />

            <PrivateRoute path="/flow/:id" component={FlowForm} />

            <PrivateRoute exact path="/pack/add/artwork/:artworkId" component={PackForm} />
            <PrivateRoute exact path="/pack/edit/:id" component={PackForm} />

            <PrivateRoute exact path="/news/add" component={NewsForm} />
            <PrivateRoute exact path="/news/edit/:id" component={NewsForm} />
            <PrivateRoute exact path="/news/:id" component={NewsPage} />

            <PrivateRoute exact path="/favorites/artworks" component={Favorites} />
            <PrivateRoute exact path="/favorites/news" component={NoMatch404} />

            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute exact path="/file/add" component={NoMatch404} />
            <PrivateRoute exact path="/file/artworks" component={NoMatch404} />
            <PrivateRoute exact path="/flowers" component={Flowers} />

            <PrivateRoute exact path="/home" component={Home} />

            <Route exact path="/player/:type/:id" component={PlayerPage} />
            <Route exact path="/player/:type/:id/:page" component={PlayerPage} />
            <Route exact path="/player/:type/:id/file/:fileId" component={PlayerPage} />
            <Route exact path="/player/:type/:id/file/:fileId/mode/:mode" component={PlayerPage} />
            <Route exact path="/read/:type/:id" component={PlayerPage} />
            <Route exact path="/read/:type/:id/file/:fileId" component={PlayerPage} />
            <Route exact path="/read/:artworkId/:type/:id/file/:fileId" component={PlayerPage} />
            <Route exact path="/read/:artworkId/:type/:id" component={PlayerPage} />
            <Route exact path="/read/:type/:id/file/:fileId/mode/:mode" component={PlayerPage} />

            <PublicRoute
              exact
              path="/prices"
              component={PricesPage}
              i18nRoutes={{ 'fr-FR': '/fr/prices', 'en-US': '/prices' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/prices`}
              i18nRoutes={{ 'fr-FR': '/fr/prices', 'en-US': '/prices' }}
              i18nComponents={{ 'fr-FR': PricesPageFR, 'en-US': PricesPage }}
            />

            <PublicRoute
              exact
              path="/terms-and-conditions"
              component={TermsAndConditionsPage}
              i18nRoutes={{ 'fr-FR': '/fr/terms-and-conditions', 'en-US': '/terms-and-conditions' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/terms-and-conditions`}
              i18nRoutes={{ 'fr-FR': '/fr/terms-and-conditions', 'en-US': '/terms-and-conditions' }}
              i18nComponents={{ 'fr-FR': TermsAndConditionsPageFR, 'en-US': TermsAndConditionsPage }}
            />

            <PublicRoute
              exact
              path="/content-policy"
              component={ContentPolicyPage}
              i18nRoutes={{ 'fr-FR': '/fr/content-policy', 'en-US': '/content-policy' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/content-policy`}
              i18nRoutes={{ 'fr-FR': '/fr/content-policy', 'en-US': '/content-policy' }}
              i18nComponents={{ 'fr-FR': ContentPolicyPageFR, 'en-US': ContentPolicyPage }}
            />

            <PublicRoute
              exact
              path="/ThankYou"
              component={ThankYouPage}
              i18nRoutes={{ 'fr-FR': '/fr/ThankYou', 'en-US': '/ThankYou' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/ThankYou`}
              i18nRoutes={{ 'fr-FR': '/fr/ThankYou', 'en-US': '/ThankYou' }}
              i18nComponents={{ 'fr-FR': ThankYouPageFR, 'en-US': ThankYouPage }}
            />

            <PublicRoute
              exact
              path="/Rainbow"
              component={RainbowPage}
              i18nRoutes={{ 'fr-FR': '/fr/Rainbow', 'en-US': '/Rainbow' }}
            />

            <PublicRoute
              exact
              path="/DareX"
              component={DareXPage}
              i18nRoutes={{ 'fr-FR': '/fr/DareX', 'en-US': '/DareX' }}
            />

            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/Rainbow`}
              i18nRoutes={{ 'fr-FR': '/fr/Rainbow', 'en-US': '/Rainbow' }}
              i18nComponents={{ 'fr-FR': RainbowPageFR, 'en-US': RainbowPage }}
            />

            <PublicRoute
              exact
              path="/ConfirmedEmail"
              component={ConfirmedEmailPage}
              i18nRoutes={{ 'fr-FR': '/fr/ConfirmedEmail', 'en-US': '/ConfirmedEmail' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/ConfirmedEmail`}
              i18nRoutes={{ 'fr-FR': '/fr/ConfirmedEmail', 'en-US': '/ConfirmedEmail' }}
              i18nComponents={{ 'fr-FR': ConfirmedEmailPageFR, 'en-US': ConfirmedEmailPage }}
            />

            <PublicRoute
              exact
              path="/UnsubscribedNewsletter"
              component={UnsubscribedNewsletterPage}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletter', 'en-US': '/UnsubscribedNewsletter' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/UnsubscribedNewsletter`}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletter', 'en-US': '/UnsubscribedNewsletter' }}
              i18nComponents={{ 'fr-FR': UnsubscribedNewsletterPageFR, 'en-US': UnsubscribedNewsletterPage }}
            />

            <PublicRoute
              exact
              path="/UnsubscribedNewsletterLGBTQ"
              component={UnsubscribedNewsletterLGBTQPage}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletterLGBTQ', 'en-US': '/UnsubscribedNewsletterLGBTQ' }}
            />
            <PublicRoute
              exact
              path="/UnsubscribedNewsletterDareX"
              component={UnsubscribedNewsletterDareXPage}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletterDareX', 'en-US': '/UnsubscribedNewsletterDareX' }}
            />

            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/UnsubscribedNewsletterLGBTQ`}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletterLGBTQ', 'en-US': '/UnsubscribedNewsletterLGBTQ' }}
              i18nComponents={{ 'fr-FR': UnsubscribedNewsletterLGBTQPageFR, 'en-US': UnsubscribedNewsletterLGBTQPage }}
            />

            <PublicRoute
              exact
              path="/UnsubscribedNewsletterCreator"
              component={UnsubscribedNewsletterCreatorPage}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletterCreator', 'en-US': '/UnsubscribedNewsletterCreator' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/UnsubscribedNewsletterCreator`}
              i18nRoutes={{ 'fr-FR': '/fr/UnsubscribedNewsletterCreator', 'en-US': '/UnsubscribedNewsletterCreator' }}
              i18nComponents={{
                'fr-FR': UnsubscribedNewsletterCreatorPageFR,
                'en-US': UnsubscribedNewsletterCreatorPage,
              }}
            />

            <PublicRoute
              exact
              path="/help/adult"
              component={AdultPage}
              i18nRoutes={{ 'fr-FR': '/fr/help/adult', 'en-US': '/help/adult' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/help/adult`}
              i18nRoutes={{ 'fr-FR': '/fr/help/adult', 'en-US': '/help/adult' }}
              i18nComponents={{ 'fr-FR': AdultPageFR, 'en-US': AdultPage }}
            />

            <PublicRoute
              exact
              path="/contact"
              component={ContactPage}
              i18nRoutes={{ 'fr-FR': '/fr/contact', 'en-US': '/contact' }}
            />
            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}/contact`}
              i18nRoutes={{ 'fr-FR': '/fr/contact', 'en-US': '/contact' }}
              i18nComponents={{ 'fr-FR': ContactPageFR, 'en-US': ContactPage }}
            />

            <PublicRoute
              exact
              path={`/:lang${LANG_REGEX}`}
              i18nRoutes={{ 'fr-FR': '/fr', 'en-US': '/' }}
              i18nComponents={{ 'fr-FR': WelcomeFR, 'en-US': WelcomeEN }}
            />
            <PublicRoute exact path="/" component={WelcomeEN} i18nRoutes={{ 'fr-FR': '/fr', 'en-US': '/' }} />
            <Route exact path="/pwa" component={() => <Loading fullPage />} />
            <Route component={NoMatch404} />
          </Switch>
        </I18nextProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );

  if (typeof document === 'undefined') {
    setupInterceptors(serverStore, history);
    return (
      <Provider store={serverStore}>
        <StaticRouter location={serverLocation} context={serverContext}>
          <ResponsiveContext.Provider value={{ width: isMobile ? '570' : '1080' }}>
            <Layout>{routes()}</Layout>
          </ResponsiveContext.Provider>
        </StaticRouter>
      </Provider>
    );
  }

  setupInterceptors(store, history);
  hitPage(history.location);
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Layout>{routes()}</Layout>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
