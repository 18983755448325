import fp from 'lodash/fp';

import {
  GET_USER_NEWS,
  GET_USER_NEWS_SUCCESS,
  GET_USER_NEWS_FAILURE,
  GET_ARTWORK_NEWS,
  GET_ARTWORK_NEWS_SUCCESS,
  GET_ARTWORK_NEWS_FAILURE,
  GET_ARTWORK_NEWS_BY_ID,
  GET_ARTWORK_NEWS_BY_ID_FAILURE,
  GET_ARTWORK_NEWS_BY_ID_SUCCESS,
  UPSERT_NEWS,
  UPSERT_NEWS_FAILURE,
  UPSERT_NEWS_SUCCESS,
} from 'constants/ActionTypes';

import hydraTool from '../tools/hydra';

const INITIAL_STATE = {
  isFetching: {
    getUserNews: false,
    getArtworkNews: false,
    getNewsById: false,
    upsertNews: false,
  },
  error: {
    getUserNews: false,
    getArtworkNews: false,
    getNewsById: false,
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    // User News
    case GET_USER_NEWS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getUserNews: true },
        error: { ...state.error, getUserNews: false },
      };
    }
    case GET_USER_NEWS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getUserNews: false },
        error: { ...state.error, getUserNews: true },
      };
    }
    case GET_USER_NEWS_SUCCESS: {
      let news = [];
      if (action.response.page === 1) {
        news = hydraTool.getItems(action.response.news);
      } else {
        news = [...state.userNews, ...hydraTool.getItems(action.response.news)];
      }
      return {
        ...state,
        userNews: news,
        isFetching: { ...state.isFetching, getUserNews: false },
        userNewsTotal: hydraTool.getTotal(action.response.news),
        userNewsLast: hydraTool.getLastPage(action.response.news),
      };
    }

    // Artwork News
    case GET_ARTWORK_NEWS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkNews: true },
        error: { ...state.error, getArtworkNews: false },
      };
    }
    case GET_ARTWORK_NEWS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkNews: false },
        error: { ...state.error, getArtworkNews: true },
      };
    }
    case GET_ARTWORK_NEWS_SUCCESS: {
      let news = null;
      if (action.response.page === 1) {
        news = hydraTool.getItems(action.response.news);
      } else {
        news = [...state.artworkNews, ...hydraTool.getItems(action.response.news)];
      }
      return {
        ...state,
        artworkNews: news,
        artworkNewsTotal: hydraTool.getTotal(action.response.news),
        artworkNewsLast: hydraTool.getLastPage(action.response.news),
        isFetching: { ...state.isFetching, getArtworkNews: false },
        error: { ...state.error, getArtworkNews: false },
      };
    }

    case GET_ARTWORK_NEWS_BY_ID: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getNewsById: true },
        error: { ...state.error, getNewsById: false },
      };
    }

    case GET_ARTWORK_NEWS_BY_ID_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getNewsById: false },
        error: { ...state.error, getNewsById: true },
      };
    }

    case GET_ARTWORK_NEWS_BY_ID_SUCCESS: {
      let userNews = [];
      if (state.userNews) {
        userNews = fp.map(c => {
          if (c.id === action.response.id) {
            return action.response;
          }
          return c;
        })(state.userNews);
      }
      return {
        ...state,
        userNews,
        current: action.response,
        isFetching: { ...state.isFetching, getNewsById: false },
        error: { ...state.error, getNewsById: false },
      };
    }

    case UPSERT_NEWS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertNews: true },
        error: { ...state.error, upsertNews: false },
      };
    }
    case UPSERT_NEWS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertNews: false },
        error: { ...state.error, upsertNews: true },
      };
    }

    case UPSERT_NEWS_SUCCESS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertNews: false },
        error: { ...state.error, upsertNews: false },
      };
    }

    // Default
    default:
      return state;
  }
}
