import React from 'react';

import _forEach from 'lodash/forEach';

const seo = {
  title(title) {
    return [
      <title key="title">{title}</title>,
      <meta property="og:title" content={title} key="ogtitle" />,
      <meta name="twitter:title" content={title} key="twittertitle" />,
    ];
  },
  description(description) {
    return [
      <meta name="description" content={description} key="description" />,
      <meta property="og:description" content={description} key="ogdescription" />,
      <meta name="twitter:description" content={description} key="twitterdescription" />,
    ];
  },
  image(image, isLarge) {
    return [
      <meta property="og:image" content={image} key="ogimage" />,
      // <meta name="og:image:secure_url" content={currentImage} key="ogimage" />,
      <meta name="twitter:image" content={image} key="twitterimage" />,
      <meta name="twitter:card" content={isLarge ? 'summary_large_image' : 'summary'} key="twittercard" />,
    ];
  },
  url(url) {
    return [<meta property="og:url" content={url} key="ogurl" />];
  },
  canonical(uri) {
    return [<link rel="canonical" href={uri} key="canonical" />];
  },
  pageLang(language, alternates) {
    let metas = [<html lang={language || 'en'} key="lang" />];
    _forEach(alternates, alternate => {
      metas = [...metas, <link rel="alternate" hrefLang={alternate.lang} href={alternate.url} key={alternate.url} />];
    });
    return metas;
  },
  noIndex() {
    return [<meta name="robots" content="noindex,nofollow" key="robots" />];
  },
};

export default seo;
