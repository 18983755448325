/* eslint-disable no-console */
import { takeEvery, put, call } from 'redux-saga/effects';

import {
  GET_FILE_COMMENTS,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_NEWS_COMMENTS,
  REMOVE_COMMENT_SUCCESS,
  REMOVE_COMMENT_FAILURE,
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_SUCCESS,
  GET_NEWS_COMMENTS_FAILURE,
  GET_NEWS_COMMENTS_SUCCESS,
  GET_FILE_COMMENTS_SUCCESS,
  GET_FILE_COMMENTS_FAILURE,
} from 'constants/ActionTypes';

import commentService from 'services/comment';

import { getArtworkNewsById } from 'actions/NewsActions';
import { showNotification } from 'actions/NotificationsActions';

import { REPORT_COMMENT, REPORT_COMMENT_SUCCESS, REPORT_COMMENT_FAILURE } from '../constants/ActionTypes';
import SagaHelper from './helper';

function* getFileComments(action) {
  try {
    const response = yield call(commentService.getByFile, action.id);
    yield put({ type: GET_FILE_COMMENTS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_FILE_COMMENTS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getNewsComments(action) {
  try {
    const response = yield call(commentService.getByArtworkNews, action.id);
    const params = {
      response,
      action,
    };
    yield put({ type: GET_NEWS_COMMENTS_SUCCESS, params });
  } catch (error) {
    yield put({ type: GET_NEWS_COMMENTS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* addComment(action) {
  try {
    const response = yield call(commentService.add, {
      file_id: action.params.fileId,
      comment_id: action.params.commentId,
      news_id: action.params.newsId,
      content: action.params.message,
    });
    const params = {
      action: action.params,
      response,
    };
    yield put({ type: ADD_COMMENT_SUCCESS, params });
    if (action.params.newsId) {
      yield put(getArtworkNewsById(action.params.newsId));
    }
  } catch (error) {
    yield put({ type: ADD_COMMENT_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* removeComment(action) {
  try {
    yield call(commentService.remove, action.id);
    yield put(showNotification({ level: 'info', message: 'Comment removed' }));
    yield put({ type: REMOVE_COMMENT_SUCCESS, action });
  } catch (error) {
    yield put({ type: REMOVE_COMMENT_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* reportComment(action) {
  try {
    const response = yield call(commentService.report, action.id, { reported: `${action.userId}` });
    yield put(showNotification({ level: 'info', message: 'Comment reported' }));
    const params = {
      response,
      action,
    };
    yield put({ type: REPORT_COMMENT_SUCCESS, params });
  } catch (error) {
    yield put({ type: REPORT_COMMENT_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

/* --- WATCHERS --- */
function* watcherGetFileComments() {
  yield takeEvery(GET_FILE_COMMENTS, getFileComments);
}

function* watcherGetNewsComments() {
  yield takeEvery(GET_NEWS_COMMENTS, getNewsComments);
}

function* watcherAddComment() {
  yield takeEvery(ADD_COMMENT, addComment);
}

function* watcherRemoveComment() {
  yield takeEvery(REMOVE_COMMENT, removeComment);
}

function* watcherReportComment() {
  yield takeEvery(REPORT_COMMENT, reportComment);
}

export default [
  watcherGetFileComments,
  watcherGetNewsComments,
  watcherAddComment,
  watcherRemoveComment,
  watcherReportComment,
];
