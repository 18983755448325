import fp from 'lodash/fp';
import _get from 'lodash/get';

import {
  GET_PACK,
  GET_PACK_SUCCESS,
  GET_PACK_FAILURE,
  GET_PACK_FILE,
  GET_PACK_FILE_SUCCESS,
  GET_PACK_FILE_FAILURE,
  GET_PACK_FILES,
  GET_PACK_FILES_SUCCESS,
  GET_PACK_FILES_FAILURE,
  UPDATE_PACK_FILES_SUCCESS,
  UPDATE_FILE_PACK_SUCCESS,
  REORDER_FILE_PACK_SUCCESS,
  MARK_AS_READ_FILE_PACK_SUCCESS,
  DELETE_FILE_PACK_SUCCESS,
  UPSERT_PACK,
  UPSERT_PACK_SUCCESS,
  UPSERT_PACK_FAILURE,
  RESET_PACK,
} from 'constants/ActionTypes';

import hydraTool from '../tools/hydra';

const INITIAL_STATE = {
  current: {},
  isFetching: {
    getPackById: false,
    getPackFile: false,
    getPackFiles: false,
    upsertPack: false,
  },
  error: {
    getPackById: false,
    getPackFile: false,
    getPackFiles: false,
    upsertPack: false,
  },
};

export default function pack(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PACK_FILE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackFile: true },
        error: { ...state.error, getPackFile: false },
      };
    }
    case GET_PACK_FILE_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackFile: false },
        error: { ...state.error, getPackFile: true },
      };
    }
    case GET_PACK_FILE_SUCCESS: {
      return {
        ...state,
        file: action.response,
        isFetching: { ...state.isFetching, getPackFile: false },
        error: { ...state.error, getPackFile: false },
      };
    }

    // Get files
    case GET_PACK_FILES: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackFiles: true },
        error: { ...state.error, getPackFiles: false },
      };
    }
    case GET_PACK_FILES_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackFiles: false },
        error: { ...state.error, getPackFiles: true },
      };
    }
    case GET_PACK_FILES_SUCCESS: {
      let files = null;
      if (action.response.page === 1 || action.response.page === 'all') {
        files = action.response.files['hydra:member'];
      } else {
        files = [...state[action.response.id], ...action.response.files['hydra:member']];
      }
      return {
        ...state,
        [action.response.id]: files,
        totalFiles: action.response.files['hydra:totalItems'],
        lastFiles: hydraTool.getLastPage(action.response.files),
        isFetching: { ...state.isFetching, getPackFiles: false },
        error: { ...state.error, getPackFiles: false },
      };
    }

    case UPSERT_PACK:
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertPack: true },
        error: { ...state.error, upsertPack: false },
      };

    case UPSERT_PACK_SUCCESS:
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertPack: false },
        error: { ...state.error, upsertPack: false },
      };

    case UPSERT_PACK_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, upsertPack: false },
        error: { ...state.error, upsertPack: true },
      };

    case UPDATE_PACK_FILES_SUCCESS:
      return {
        ...state,
        [action.response.id]: action.response.files,
      };

    case UPDATE_FILE_PACK_SUCCESS: {
      let newFiles;
      if (action.params.moved) {
        newFiles = state[action.params.packId].filter(f => f.file.id !== action.params.file.id);
      } else {
        newFiles = fp.map(file => {
          if (file.file.id === action.params.file.id) {
            return {
              ...file,
              file: action.params.file,
            };
          }
          return file;
        })(state[action.params.packId]);
      }
      return {
        ...state,
        [action.params.packId]: newFiles,
      };
    }

    case REORDER_FILE_PACK_SUCCESS:
      return {
        ...state,
        [action.response.id]: action.response.packFiles,
      };

    case MARK_AS_READ_FILE_PACK_SUCCESS: {
      return {
        ...state,
        lastFileRead: { id: _get(action, 'params.response.id') },
        lastFileReadDate: { id: action.params.date },
      };
    }

    case DELETE_FILE_PACK_SUCCESS:
      return {
        ...state,
        [action.action.packId]: state[action.action.packId].filter(file => file.file.id !== action.action.id),
        totalFiles: state.totalFiles - 1,
      };

    case GET_PACK: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackById: true },
        error: { ...state.error, getPackById: false },
      };
    }

    case GET_PACK_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getPackById: false },
        error: { ...state.error, getPackById: _get(action, 'error.status') || true },
      };
    }

    case GET_PACK_SUCCESS: {
      const updatedPack = { ...action.response };
      if (state.id === action.response.id) {
        updatedPack.lastFileRead = updatedPack.lastFileRead || state.current.lastFileRead;
        updatedPack.readingDate = updatedPack.readingDate || state.current.readingDate;
      }
      return {
        ...state,
        current: updatedPack,
        isFetching: { ...state.isFetching, getPackById: false },
        error: { ...state.error, getPackById: false },
      };
    }
    case RESET_PACK:
      return {};

    // Default
    default:
      return state;
  }
}
