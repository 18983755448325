import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';

import userService from 'services/user';

import Input from 'components/Form/Input';
import { LoadingButton } from 'components/Loading';

import schema from './Forgot.schema';

export const MODE_FORGOT = 'forgot';

interface IProps {
  onChangeMode(): void;
  onSuccess(): void;
}

export default function Forgot({ onChangeMode }: IProps) {
  const [mode, setMode] = useState(false);
  const location = useLocation();
  const { t } = useTranslation('forgot');

  const queryParam = new URLSearchParams(location.search);
  const email = queryParam.get('email') || '';
  const initialValues = {
    email,
  };

  const handleSubmit = (values, formikBag) => {
    setMode('loading');
    userService
      .requestPasswordReset(encodeURIComponent(values.email))
      .then(() => {
        setMode('success');
      })
      .catch(error => {
        if (error?.data?.violations) {
          error.data.violations.forEach(err => {
            formikBag.setFieldError(err.propertyPath, err.message);
          });
        } else if (error?.status === 401) {
          formikBag.setFieldError('email', 'input-email-incorrect', false);
        } else if (error?.status === 404) {
          formikBag.setFieldError('email', 'input-email-unknown', false);
        } else {
          formikBag.setFieldError('email', 'server-down', false);
        }
        setMode(false);
      });
  };

  return (
    <div className="Forgot">
      {mode === 'success' && (
        <div
          className="flowfont-thin text-center text-neutral fs-100"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('message-success') }}
        />
      )}
      {mode !== 'success' && (
        <div className="Forgot-form text-neutral flowfont-thin">
          <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
            {() => (
              <Form>
                {t('message')}.
                <Field name="email" type="email" label={t('input-email')} component={Input} t={t} />
                <LoadingButton
                  className="mt-4 ml-5 rounded-pill flowfont-regular px-4"
                  label={t('submit-button')}
                  isFetching={mode === 'loading'}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
      <div className="mt-4 text-center fs-100">
        <Link
          to="/login"
          onClick={e => {
            e.preventDefault();
            onChangeMode('login');
          }}
        >
          {t('login-link')}
        </Link>
      </div>
    </div>
  );
}
