import {
  GET_USER_NEWS,
  GET_ARTWORK_NEWS,
  GET_ARTWORK_NEWS_BY_ID,
  UPSERT_NEWS,
  DELETE_NEWS,
  GET_ARTWORK_MARK_NEWS_AS_READ,
} from 'constants/ActionTypes';

export function getUserNews(page) {
  return {
    type: GET_USER_NEWS,
    page,
  };
}

export function getArtworkNews(id, page) {
  return {
    type: GET_ARTWORK_NEWS,
    id,
    page,
  };
}

export function markArtworkNewsAsRead(id) {
  return {
    type: GET_ARTWORK_MARK_NEWS_AS_READ,
    id,
  };
}

export function getArtworkNewsById(id) {
  return {
    type: GET_ARTWORK_NEWS_BY_ID,
    id,
  };
}

export function saveNewsForm(formData) {
  return {
    type: UPSERT_NEWS,
    formData,
  };
}

export function deleteNews(id) {
  return {
    type: DELETE_NEWS,
    id,
  };
}
