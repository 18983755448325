import fp from 'lodash/fp';
import _get from 'lodash/get';

import {
  GET_ARTWORK,
  GET_ARTWORK_SUCCESS,
  GET_ARTWORK_FAILURE,
  GET_ARTWORK_FILE,
  GET_ARTWORK_FILE_SUCCESS,
  GET_ARTWORK_FILE_FAILURE,
  GET_ARTWORK_FILES,
  GET_ARTWORK_FILES_SUCCESS,
  GET_ARTWORK_FILES_FAILURE,
  GET_ARTWORKS_SUCCESS,
  GET_ALL_ARTWORKS,
  GET_ALL_ARTWORKS_SUCCESS,
  GET_ALL_ARTWORKS_FAILURE,
  UPDATE_FILE_ARTWORK_SUCCESS,
  REORDER_FILE_ARTWORK_SUCCESS,
  DELETE_FILE_ARTWORK_SUCCESS,
  UPDATE_ARTWORK_PACK_SUCCESS,
  UPSERT_ARTWORK,
  UPSERT_ARTWORK_SUCCESS,
  UPSERT_ARTWORK_FAILURE,
  RESET_ARTWORK,
  MARK_AS_READ_FILE_ARTWORK_SUCCESS,
  GET_SUGGESTIONS_SUCCESS,
  MARK_NOT_INTERESTED_SUCCESS,
  GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS,
} from '../constants/ActionTypes';
import hydraTool from '../tools/hydra';

const INITIAL_STATE = {
  current: {},
  suggestions: [],
  isFetching: {
    getArtworkById: false,
    getArtworkFile: false,
    getArtworkFiles: false,
    allArtworks: false,
    upsertArtwork: false,
  },
  error: {
    getArtworkById: false,
    getArtworkFile: false,
    getArtworkFiles: false,
    allArtworks: false,
    upsertArtwork: false,
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Artwork
    case GET_ARTWORK: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkById: true },
        error: { ...state.error, getArtworkById: false },
      };
    }
    case GET_ARTWORK_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkById: false },
        error: { ...state.error, getArtworkById: _get(action, 'error.status') || true },
      };
    }
    case GET_ARTWORK_SUCCESS: {
      // let updatedState = { ...state, current = action.response };
      // if (state.allArtworks) {
      //   const allArtworksUpdated = fp.map(artwork => {
      //     if (artwork.id === action.response.id) {
      //       return action.response;
      //     }
      //     return artwork;
      //   })(state.allArtworks);
      //   updatedState = {
      //     ...updatedState,
      //     allArtworks: allArtworksUpdated,
      //   };
      // }
      return {
        current: action.response.artwork,
        // ...updatedState,
        suggestions: state.suggestions,
        files: action.response.files['hydra:member'],
        totalFiles: action.response.files['hydra:totalItems'],
        lastFiles: hydraTool.getLastPage(action.response.files),
        isFetching: { ...state.isFetching, getArtworkById: false },
        error: { ...state.error, getArtworkById: false },
      };
    }

    case GET_ARTWORK_FILE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkFile: true },
        error: { ...state.error, getArtworkFile: false },
      };
    }
    case GET_ARTWORK_FILE_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkFile: false },
        error: { ...state.error, getArtworkFile: true },
      };
    }
    case GET_ARTWORK_FILE_SUCCESS: {
      return {
        ...state,
        file: action.response,
        isFetching: { ...state.isFetching, getArtworkFile: false },
        error: { ...state.error, getArtworkFile: false },
      };
    }

    case GET_ARTWORK_FILES: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkFiles: true },
        error: { ...state.error, getArtworkFiles: false },
      };
    }
    case GET_ARTWORK_FILES_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getArtworkFiles: false },
        error: { ...state.error, getArtworkFiles: true },
      };
    }
    case GET_ARTWORK_FILES_SUCCESS: {
      let files = null;
      if (action.response.page === 1) {
        files = action.response.files['hydra:member'];
      } else {
        files = [...state.files, ...action.response.files['hydra:member']];
      }
      return {
        ...state,
        files,
        totalFiles: action.response.files['hydra:totalItems'],
        lastFiles: hydraTool.getLastPage(action.response.files),
        isFetching: { ...state.isFetching, getArtworkFiles: false },
        error: { ...state.error, getArtworkFiles: false },
      };
    }

    // User Artworks
    case GET_ARTWORKS_SUCCESS:
      return {
        ...state,
        userArtworks: action.artworks,
      };

    // All Artworks
    case GET_ALL_ARTWORKS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, allArtworks: true },
        error: { ...state.error, allArtworks: false },
      };
    }
    case GET_ALL_ARTWORKS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, allArtworks: false },
        error: { ...state.error, allArtworks: true },
      };
    }
    case GET_ALL_ARTWORKS_SUCCESS: {
      let artworks = [];
      if (action.response.page === 1) {
        artworks = action.response.artworks?.['hydra:member'];
      } else {
        artworks = [...state.allArtworks, ...action.response.artworks?.['hydra:member']];
      }
      return {
        ...state,
        allArtworks: artworks,
        allArtworksTotal: action.response.artworks?.['hydra:totalItems'],
        allArtworksLast: hydraTool.getLastPage(action.response.artworks),
        isFetching: { ...state.isFetching, allArtworks: false },
        error: { ...state.error, allArtworks: false },
      };
    }

    case UPDATE_ARTWORK_PACK_SUCCESS: {
      let updatedState = { ...state };
      if (state.allArtworks) {
        const allArtworksUpdated = fp.map(artwork => {
          if (artwork.id === action.params.artworkId) {
            const packs = fp.map(pack => {
              if (pack.id === action.params.packId) {
                return {
                  ...pack,
                  ...action.params.pack,
                };
              }
              return pack;
            })(artwork.packs);
            return {
              ...artwork,
              packs,
            };
          }
          return artwork;
        })(state.allArtworks);

        updatedState = {
          ...updatedState,
          allArtworks: allArtworksUpdated,
        };
      }
      if (state.id === action.params.artworkId) {
        const updatedPack = fp.map(pack => {
          if (pack.id === action.params.packId) {
            return {
              ...pack,
              ...action.params.pack,
            };
          }
          return pack;
        })(state.packs);
        updatedState = {
          ...state,
          packs: updatedPack,
        };
      }
      return {
        ...updatedState,
      };
    }

    case UPSERT_ARTWORK:
      return {
        isFetching: { ...state.isFetching, upsertArtwork: true },
        error: { ...state.error, upsertArtwork: false },
      };
    case UPSERT_ARTWORK_FAILURE:
      return {
        isFetching: { ...state.isFetching, upsertArtwork: false },
        error: { ...state.error, upsertArtwork: true },
      };
    case UPSERT_ARTWORK_SUCCESS:
      return INITIAL_STATE;

    case RESET_ARTWORK:
      return {
        ...INITIAL_STATE,
        suggestions: state.suggestions,
      };

    case UPDATE_FILE_ARTWORK_SUCCESS: {
      let newFiles;
      if (action.params.moved) {
        newFiles = state.files.filter(f => f.file.id !== action.params.file.id);
      } else {
        newFiles = fp.map(file => {
          if (file.file.id === action.params.file.id) {
            return {
              ...file,
              file: action.params.file,
            };
          }
          return file;
        })(state.files);
      }
      return {
        ...state,
        files: newFiles,
      };
    }

    case REORDER_FILE_ARTWORK_SUCCESS:
      return {
        ...state,
        artworkFiles: action.artworkFiles.artworkFiles,
        files: action.artworkFiles.artworkFiles,
      };

    case MARK_AS_READ_FILE_ARTWORK_SUCCESS: {
      return {
        ...state,
        lastFileRead: { id: _get(action, 'params.response.id') },
        lastFileReadDate: { id: action.params.date },
      };
    }

    case DELETE_FILE_ARTWORK_SUCCESS:
      return {
        ...state,
        files: state.files.filter(file => file.file.id !== action.action.id),
        totalFiles: state.totalFiles - 1,
      };

    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.response,
      };

    case MARK_NOT_INTERESTED_SUCCESS:
      if (!state.suggestions) return state;
      return {
        ...state,
        suggestions: state.suggestions.filter(suggestion => suggestion.id !== action.response.id),
      };

    case GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS:
      return {
        ...state,
        current: { ...state.current, newsUnReadCount: action.response.newsUnReadCount },
      };

    // Default
    default:
      return state;
  }
}
