/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';

import Select from 'react-select';

import _intersectionWith from 'lodash/intersectionWith';
import _isEqual from 'lodash/isEqual';

import './Select.scss';

type Props = {
  label: string,
  defaultValue: string,
  helperText: string,
  options: any,
  placeholder: string,
  className: string,
  field: Object,
  form: Object,
  disabled: Boolean,
  isMulti: Boolean,
  maxItems: Number,
  onChange: Function,
};

class CustomSelect extends PureComponent<Props> {
  render() {
    const {
      label,
      defaultValue,
      options: optionsOriginal,
      placeholder,
      className,
      isMulti,
      maxItems,
      helperText,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      disabled,
      onChange,
    } = this.props;

    // Todo : find a better way to do this (on a higher level)
    if (field.value && Number.isInteger(field.value.id)) {
      field.value = field.value.id;
    }

    const options = optionsOriginal || [];
    let value;
    if (!isMulti) {
      value = options.find(option => option.value === field.value);
    } else {
      value = _intersectionWith(options, field.value, _isEqual);
    }

    return (
      <div className={`Input Select form-group ${className || ''}`}>
        {label && <label className="Input-label flowfont-medium text-uppercase">{label}</label>}

        <Select
          isMulti={isMulti || false}
          value={value}
          defaultValue={defaultValue}
          options={options}
          classNamePrefix="Select"
          placeholder={placeholder || 'Select...'}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'var(--secondary)',
              primary: 'var(--primary)',
            },
          })}
          onChange={newValue => {
            if (!isMulti) {
              console.log(field.name, newValue);
              setFieldValue(field.name, newValue);
              if (onChange) onChange(newValue);
              return;
            }
            const newSize = newValue ? newValue.length : 0;
            if (newSize <= (maxItems || 3)) {
              setFieldValue(field.name, newValue);
              if (onChange) onChange(newValue);
            }
          }}
          onBlur={setFieldTouched}
          isDisabled={disabled}
        />

        {helperText && !errors[field.name] && (
          <small className="form-text text-muted roboto font-italic">{helperText}</small>
        )}

        {touched[field.name] && errors[field.name] && (
          <div className="Input-error flowfont-medium text-danger">{errors[field.name]}</div>
        )}
      </div>
    );
  }
}

export default CustomSelect;
