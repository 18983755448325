/* eslint-disable no-console */
import { takeEvery, put, call } from 'redux-saga/effects';

import fileService from 'services/file';

import {
  ADD_FILE_FEEDBACK,
  ADD_FILE_FEEDBACK_SUCCESS,
  ADD_FILE_FEEDBACK_FAILURE,
  GET_FILE_FEEDBACKS,
  GET_FILE_FEEDBACKS_SUCCESS,
  GET_FILE_FEEDBACKS_FAILURE,
} from '../constants/ActionTypes';
import SagaHelper from './helper';

function* getFileFeedbacks(action) {
  try {
    const response = yield call(fileService.feedbacks, action.id);
    yield put({ type: GET_FILE_FEEDBACKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_FILE_FEEDBACKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* addFileFeedback(action) {
  try {
    const response = yield call(fileService.updateFeedback, action.id, { feedback: action.feedback });
    yield put({ type: ADD_FILE_FEEDBACK_SUCCESS, response });
    yield put({ type: GET_FILE_FEEDBACKS, id: action.id });
  } catch (error) {
    yield put({ type: ADD_FILE_FEEDBACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

/* --- WATCHERS --- */
function* watcherGetFileFeedbacks() {
  yield takeEvery(GET_FILE_FEEDBACKS, getFileFeedbacks);
}

function* watcherAddFileFeedback() {
  yield takeEvery(ADD_FILE_FEEDBACK, addFileFeedback);
}

export default [watcherAddFileFeedback, watcherGetFileFeedbacks];
