import { SHOW_PLAYER, HIDE_PLAYER } from 'constants/ActionTypes';

const INITIAL_STATE = { params: null, artwork: null, noDisclaimer: false };

export default function player(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_PLAYER: {
      return {
        ...state,
        params: action.params,
        artwork: action.artwork,
        noDisclaimer: !!action.noDisclaimer,
      };
    }

    case HIDE_PLAYER: {
      return {
        ...state,
        params: null,
        artwork: null,
        noDisclaimer: false,
      };
    }

    // Default
    default:
      return state;
  }
}
