import api from './api';

const pack = {
  getById: id => api.get(`packs/${id}`),

  getFile: id => api.get(`files/${id}`),

  getFiles: (id, page, itemsPerPage) => {
    if (page === 'all') {
      return api.get(`packs/${id}/pack_files.jsonld?pagination=false`);
    }
    return api.get(`packs/${id}/pack_files.jsonld?page=${page}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ''}`);
  },

  putFile: params => api.put(`files/${params.id}`, params),

  deleteFile: id => api.delete(`files/${id}`),

  reorderFile: (id, sort) => api.get(`files/${id}/sort/${sort}`),

  markFileAsRead: id => api.get(`files/${id}/mark_as_read`),

  markAsRead: id => api.get(`packs/${id}/mark_as_read`),

  post: params => api.post('packs', params),

  put: params => api.put(`packs/${params.id}`, params),

  delete: id => api.delete(`packs/${id}`),
};

export default pack;
