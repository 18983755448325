/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TranslatedRoute from './TranslatedRoute';

type Props = {
  component: Object,
  i18nRoutes: string[],
  i18nComponents: [],
};

const PublicRoute = ({ ...props }: Props) => {
  return <TranslatedRoute {...props} />;
};

export default PublicRoute;
