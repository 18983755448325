import { GET_TAXONOMY_CATEGORIES, GET_TAXONOMY_LANGUAGES, GET_TAXONOMY_COUNTRIES } from '../constants/ActionTypes';

export function getCategories() {
  return {
    type: GET_TAXONOMY_CATEGORIES,
  };
}

export function getLanguages() {
  return {
    type: GET_TAXONOMY_LANGUAGES,
  };
}

export function getCountries() {
  return {
    type: GET_TAXONOMY_COUNTRIES,
  };
}
