import cx from 'classnames';
import { Button } from 'react-bootstrap';

import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { useTimeout } from 'react-use';

import Icon from './Icon';
import styles from './LoadingBig.module.scss';

type Props = {
  className: string,
  fullPage: boolean,
};

const Loading = ({ className, fullPage }: Props) => {
  const { t } = useTranslation('global');
  const [showRefresh] = useTimeout(3500);
  const animated = showRefresh();
  return (
    <>
      <div className={cx(styles.Loading, className, { [styles.fullpage]: fullPage })}>
        <div className={styles.logo}>
          <Icon />
        </div>
        <div className={styles.loader} />
      </div>
      {fullPage && (
        <div className={cx(styles.actions, 'text-center pt-5 fadeIn delay-3s', { animated })}>
          <Button
            variant="link"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('get-stuck')} {t('refresh')}
          </Button>
        </div>
      )}
    </>
  );
};

export default memo(Loading);
