import React from 'react';

import './LoadingSmall.scss';

type Props = {
  className: String,
};

const LoadingSmall = ({ className }: Props) => {
  return (
    <span className={`LoadingSmall ${className || ''}`}>
      <div className="loader loader-double is-active" />
    </span>
  );
};

export default React.memo(LoadingSmall);
