/* eslint-disable no-console */
import { takeEvery, put, call } from 'redux-saga/effects';

import { push } from 'react-router-redux';

import packService from 'services/pack';

import { getArtworkById as getArtworkByIdAction } from 'actions/ArtworkActions';
import { showNotification } from 'actions/NotificationsActions';
import { getPackById as getPackByIdAction, getPackFiles as getPackFilesAction } from 'actions/PackActions';
import { getPrivateProfile as getPrivateProfileAction } from 'actions/UserActions';

import {
  GET_PACK,
  GET_PACK_SUCCESS,
  GET_PACK_FAILURE,
  GET_PACK_FILE,
  GET_PACK_FILE_SUCCESS,
  GET_PACK_FILE_FAILURE,
  GET_PACK_FILES,
  GET_PACK_FILES_SUCCESS,
  GET_PACK_FILES_FAILURE,
  UPDATE_FILE_PACK,
  UPDATE_FILE_PACK_SUCCESS,
  UPDATE_FILE_PACK_FAILURE,
  REORDER_FILE_PACK,
  REORDER_FILE_PACK_SUCCESS,
  REORDER_FILE_PACK_FAILURE,
  DELETE_FILE_PACK,
  DELETE_FILE_PACK_SUCCESS,
  DELETE_FILE_PACK_FAILURE,
  UPSERT_PACK,
  UPSERT_PACK_SUCCESS,
  UPSERT_PACK_FAILURE,
  DELETE_PACK,
  DELETE_PACK_SUCCESS,
  DELETE_PACK_FAILURE,
  RESET_PACK,
  MARK_AS_READ_FILE_PACK_SUCCESS,
  MARK_AS_READ_FILE_PACK_FAILURE,
  MARK_AS_READ_FILE_PACK,
  USER_MARK_AS_READ_SUCCESS,
  MARK_AS_READ_PACK_SUCCESS,
  MARK_AS_READ_PACK_FAILURE,
  MARK_AS_READ_PACK,
} from '../constants/ActionTypes';
import SagaHelper from './helper';

function* getPackById(action) {
  try {
    if (action.forceUpdate) {
      yield put({ type: RESET_PACK });
    }
    const response = yield call(packService.getById, action.id);
    yield put({ type: GET_PACK_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getPackFile(action) {
  try {
    const response = yield call(packService.getFile, action.id);
    yield put({ type: GET_PACK_FILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PACK_FILE_FAILURE, error });
  }
}

function* getPackFiles(action) {
  try {
    const page = action.page || 1;
    const response = {
      id: action.id,
      files: yield call(packService.getFiles, action.id, page, action.itemsPerPage),
      page,
    };
    yield put({ type: GET_PACK_FILES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PACK_FILES_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* updateFilePack(action) {
  try {
    const updatedFile = yield call(packService.putFile, action.file);
    const params = {
      file: updatedFile,
      packId: action.packId,
      moved: !!action.file.moveToPackId || !!action.file.moveToFlowId,
    };
    yield put({ type: UPDATE_FILE_PACK_SUCCESS, params });
    yield put(showNotification({ level: 'success', message: 'File updated' }));
  } catch (error) {
    yield put({ type: UPDATE_FILE_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_PACK });
    yield put(getPackByIdAction(action.packId));
  }
}

function* reorderFilePack(action) {
  try {
    const PackFiles = yield call(packService.reorderFile, action.id, action.order);
    const response = { ...PackFiles, id: action.packId };
    yield put(showNotification({ level: 'success', message: 'File updated' }));
    yield put({ type: REORDER_FILE_PACK_SUCCESS, response });
  } catch (error) {
    yield put({ type: REORDER_FILE_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_PACK });
    yield put(getPackByIdAction(action.packId));
    yield put(getPackFilesAction(action.packId));
  }
}

function* markAsReadFilePack(action) {
  try {
    const params = { file: action.file, packId: action.packId, artworkId: action.artworkId, date: new Date() };
    if (action.artworkId) {
      // if (action.artworkId && !action.hasAffinity) {
      yield put({ type: USER_MARK_AS_READ_SUCCESS, params });
    }
    const response = yield call(packService.markFileAsRead, action.file.file.id);
    params.response = response;
    yield put({ type: MARK_AS_READ_FILE_PACK_SUCCESS, params });
  } catch (error) {
    console.log(error);
    yield put({ type: MARK_AS_READ_FILE_PACK_FAILURE, error });
  }
}

function* markAsReadPack(action) {
  try {
    yield call(packService.markAsRead, action.id);
    yield put({ type: MARK_AS_READ_PACK_SUCCESS, action });
    yield put(getPrivateProfileAction());
    yield put(getArtworkByIdAction(action.artworkId));
  } catch (error) {
    yield put({ type: MARK_AS_READ_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* deleteFilePack(action) {
  try {
    yield call(packService.deleteFile, action.id);
    yield put({ type: DELETE_FILE_PACK_SUCCESS, action });
    yield put(showNotification({ level: 'success', message: 'File deleted' }));
  } catch (error) {
    yield put({ type: DELETE_FILE_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_PACK });
    yield put(getPackByIdAction(action.packId));
  }
}

function* upsertPack(action) {
  try {
    let response;
    if (Number.isInteger(action.formData.id)) {
      response = yield call(packService.put, action.formData);
      yield put(showNotification({ level: 'info', message: 'Flow updated' }));
    } else {
      response = yield call(packService.post, action.formData);
      yield put(showNotification({ level: 'info', message: 'Flow created' }));
    }

    yield put({ type: UPSERT_PACK_SUCCESS, response });
    yield put(getArtworkByIdAction(response.artwork.uniqueName));
    yield put(push(`/artwork/${response.artwork.author.username}/${response.artwork.uniqueName}`));
  } catch (error) {
    yield put({ type: UPSERT_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* deletePack(action) {
  try {
    yield call(packService.delete, action.id);
    yield put(showNotification({ level: 'info', message: 'Flow deleted' }));
    yield put({ type: DELETE_PACK_SUCCESS });
    yield put(push(`/profile/${action.username}`));
  } catch (error) {
    yield put({ type: DELETE_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

/* --- WATCHERS --- */
function* watcherGetPackById() {
  yield takeEvery(GET_PACK, getPackById);
}

function* watcherGetPackFile() {
  yield takeEvery(GET_PACK_FILE, getPackFile);
}

function* watcherGetPackFiles() {
  yield takeEvery(GET_PACK_FILES, getPackFiles);
}

function* watcherUpdateFilePack() {
  yield takeEvery(UPDATE_FILE_PACK, updateFilePack);
}

function* watcherReorderFilePack() {
  yield takeEvery(REORDER_FILE_PACK, reorderFilePack);
}

function* watcherMarkAsReadFilePack() {
  yield takeEvery(MARK_AS_READ_FILE_PACK, markAsReadFilePack);
}

function* watcherMarkAsReadPack() {
  yield takeEvery(MARK_AS_READ_PACK, markAsReadPack);
}

function* watcherDeleteFilePack() {
  yield takeEvery(DELETE_FILE_PACK, deleteFilePack);
}

function* watcherUpsertPack() {
  yield takeEvery(UPSERT_PACK, upsertPack);
}

function* watcherDeletePack() {
  yield takeEvery(DELETE_PACK, deletePack);
}

export default [
  watcherGetPackById,
  watcherGetPackFile,
  watcherGetPackFiles,
  watcherUpdateFilePack,
  watcherReorderFilePack,
  watcherMarkAsReadFilePack,
  watcherMarkAsReadPack,
  watcherDeleteFilePack,
  watcherUpsertPack,
  watcherDeletePack,
];
