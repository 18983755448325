import * as GTM from 'react-gtm-module';

export const TagManager = {
  initialize: data => {
    if (typeof document !== 'undefined') {
      GTM.initialize(data);
    }
  },
  dataLayer: data => {
    if (typeof document !== 'undefined') {
      GTM.dataLayer(data);
    }
  },
};
