import { takeEvery, put, call } from 'redux-saga/effects';

import taxonomyService from 'services/taxonomy';

import {
  GET_TAXONOMY_CATEGORIES,
  GET_TAXONOMY_CATEGORIES_SUCCESS,
  GET_TAXONOMY_CATEGORIES_FAILURE,
  GET_TAXONOMY_LANGUAGES,
  GET_TAXONOMY_LANGUAGES_SUCCESS,
  GET_TAXONOMY_LANGUAGES_FAILURE,
  GET_TAXONOMY_COUNTRIES,
  GET_TAXONOMY_COUNTRIES_SUCCESS,
  GET_TAXONOMY_COUNTRIES_FAILURE,
} from '../constants/ActionTypes';
import SagaHelper from './helper';

function* getCategories() {
  try {
    const response = yield call(taxonomyService.getCategories);
    yield put({ type: GET_TAXONOMY_CATEGORIES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_TAXONOMY_CATEGORIES_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* getLanguages() {
  try {
    const response = yield call(taxonomyService.getLanguages);
    yield put({ type: GET_TAXONOMY_LANGUAGES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_TAXONOMY_LANGUAGES_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* getCountries() {
  try {
    const response = yield call(taxonomyService.getCountries);
    yield put({ type: GET_TAXONOMY_COUNTRIES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_TAXONOMY_COUNTRIES_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

/* --- WATCHERS --- */
function* watcherGetCategories() {
  yield takeEvery(GET_TAXONOMY_CATEGORIES, getCategories);
}
function* watcherGetLanguages() {
  yield takeEvery(GET_TAXONOMY_LANGUAGES, getLanguages);
}
function* watcherGetCountries() {
  yield takeEvery(GET_TAXONOMY_COUNTRIES, getCountries);
}

export default [watcherGetCategories, watcherGetLanguages, watcherGetCountries];
