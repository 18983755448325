/* eslint-disable prettier/prettier */
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import * as MobileDetect from 'mobile-detect';

import React from 'react';

import { hydrate } from 'react-dom';

import App from './App';
import './polyfills';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.RAZZLE_SENTRY_DSN,
  release: process.env.RAZZLE_VERSION_ID,
  environment: process.env.RAZZLE_ENVIRONMENT,
  debug: process.env.RAZZLE_ENVIRONMENT === 'dev',
  beforeSend: event => {
    if (process.env.RAZZLE_ENVIRONMENT === 'dev') {
      console.error(event);
      return null; // this drops the event and nothing will be send to sentry
    }
    return event;
  },
});

loadableReady(() => {
  // eslint-disable-next-line react/jsx-filename-extension
  hydrate(<App />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // eslint-disable-next-line no-undef
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.phone()) {
    serviceWorker.register({
      // onUpdate: () => {
      //   document.getElementById('pwa-update-banner').style.display = 'block';
      // },
      // onSuccess: () => {
      //   document.getElementById('pwa-offline-banner').style.display = 'block';
      // },
    });
  } else {
    serviceWorker.unregister();
  }
});
