import _findIndex from 'lodash/findIndex';
import fp from 'lodash/fp';
import _get from 'lodash/get';

import {
  HISTORY_LOCATION,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_PRIVATE_PROFILE,
  GET_PRIVATE_PROFILE_SUCCESS,
  GET_PRIVATE_PROFILE_FAILURE,
  GET_PUBLIC_PROFILE,
  GET_PUBLIC_PROFILE_SUCCESS,
  GET_PUBLIC_PROFILE_FAILURE,
  GET_USER_ARTWORKS_SUCCESS,
  SUBSCRIBE_ARTWORK,
  SUBSCRIBE_ARTWORK_SUCCESS,
  ACCEPT_ARTWORK_SUCCESS,
  UNSUBSCRIBE_ARTWORK,
  UNSUBSCRIBE_ARTWORK_SUCCESS,
  CART_SHOW,
  CART_HIDE,
  BUY_PACK,
  BUY_PACK_SUCCESS,
  BUY_PACK_FAILURE,
  ADD_USER_CREDITCARD,
  ADD_USER_CREDITCARD_SUCCESS,
  ADD_USER_CREDITCARD_FAILURE,
  REMOVE_USER_CREDITCARD,
  REMOVE_USER_CREDITCARD_SUCCESS,
  REMOVE_USER_CREDITCARD_FAILURE,
  ORDERS_PLACE_ORDER,
  ORDERS_PLACE_ORDER_SUCCESS,
  ORDERS_PLACE_ORDER_FAILURE,
  ORDERS_PLACE_QUOTE,
  ORDERS_PLACE_QUOTE_SUCCESS,
  ORDERS_PLACE_QUOTE_FAILURE,
  CART_RESET,
  GET_USER_BILLINGS_SUCCESS,
  GET_USER_INCOMES,
  GET_USER_INCOMES_FAILURE,
  GET_USER_INCOMES_SUCCESS,
  GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS,
  ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS,
  USER_DISMISS_NOTIFICATIONS,
  GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS,
  GET_USER_MARK_NEWS_AS_READ_SUCCESS,
  GET_ACCESSED_PACKS_SUCCESS,
  GET_ACCESSED_PACKS,
  GET_ACCESSED_PACKS_FAILURE,
  USER_MARK_AS_READ_SUCCESS,
  GET_FAVORITE_ARTWORKS,
  GET_FAVORITE_ARTWORKS_FAILURE,
  GET_FAVORITE_ARTWORKS_SUCCESS,
  ACCEPT_TUTORIAL_SUCCESS,
  SETUP_USER_PAYMENT_METHOD,
  SETUP_USER_PAYMENT_METHOD_FAILURE,
  SETUP_USER_PAYMENT_METHOD_SUCCESS,
  UPDATE_THEME,
} from '../constants/ActionTypes';
import hydraTool from '../tools/hydra';

const INITIAL_STATE = {
  privateProfile: {},
  publicProfile: {},
  favoriteArtworks: [],
  history: [],
  cartMode: false,
  isFetching: {
    favoriteArtworks: false,
    packAccesses: false,
    private: false,
    update: false,
    incomes: false,
  },
  status: {
    login: false,
    register: false,
    quote: false,
  },
  error: {
    publicProfile: false,
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Login
    case LOGIN:
      return {
        ...state,
        status: {
          ...state.status,
          login: 'fetching',
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          login: 'success',
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          login: 'failure',
        },
      };

    // Register
    case REGISTER_USER:
      return {
        ...state,
        status: {
          ...state.status,
          register: 'fetching',
        },
      };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        ...action.response,
        status: {
          ...state.status,
          register: 'success',
        },
      };

    case REGISTER_USER_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          register: 'failure',
        },
      };

    case UPDATE_THEME:
      return {
        ...state,
        privateProfile: {
          ...state.privateProfile,
          theme: action.theme,
        },
      };

    // Update user
    case UPDATE_USER:
      return {
        ...state,
        isFetching: { ...state.isFetching, update: true },
        error: { ...state.error, update: false },
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isNotificationEmail: action.response.isNotificationEmail,
        isNotificationAutoRead: action.response.isNotificationAutoRead,
        privateProfile: {
          ...state.private,
          ...action.response,
        },
        isFetching: { ...state.isFetching, update: false },
        error: { ...state.error, update: false },
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isFetching: { ...state.isFetching, update: false },
        error: { ...state.error, update: true },
      };

    // Get user profile
    case GET_PRIVATE_PROFILE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          private: true,
        },
      };
    case GET_PRIVATE_PROFILE_SUCCESS:
      return {
        ...state,
        privateProfile: {
          ...action.response,
        },
        isFetching: {
          ...state.isFetching,
          private: false,
        },
      };
    case GET_PRIVATE_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          private: false,
        },
      };

    // Get user profile
    case GET_PUBLIC_PROFILE:
      return {
        ...state,
        error: { ...state.error, publicProfile: false },
      };
    case GET_PUBLIC_PROFILE_SUCCESS:
      return {
        ...state,
        publicProfile: action.response,
        error: { ...state.error, publicProfile: false },
      };

    case GET_PUBLIC_PROFILE_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          publicProfile: _get(action, 'error.status') || true,
        },
      };

    case GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...state,
        privateProfile: { ...state.privateProfile, notificationUnReadCount: action.response.notificationUnReadCount },
      };

    case GET_USER_MARK_NEWS_AS_READ_SUCCESS:
      return {
        ...state,
        privateProfile: { ...state.privateProfile, newsUnReadCount: action.response.newsUnReadCount },
      };

    case GET_USER_ARTWORKS_SUCCESS: {
      if (action.params.userId === 'me') {
        return {
          ...state,
          artworks: action.params.response,
        };
      }
      return {
        ...state,
        publicArtworks: action.params.response,
      };
    }

    case GET_FAVORITE_ARTWORKS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favoriteArtworks: true,
        },
      };

    case GET_FAVORITE_ARTWORKS_FAILURE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          favoriteArtworks: false,
        },
      };

    case GET_FAVORITE_ARTWORKS_SUCCESS: {
      let favoriteArtworks = [];
      if (!action.params.page || action.params.page === 1 || !state.favoriteArtworks) {
        favoriteArtworks = hydraTool.getItems(action.params.response);
      } else {
        favoriteArtworks = [...state.favoriteArtworks, ...hydraTool.getItems(action.params.response)];
      }
      return {
        ...state,
        favoriteArtworks,
        isFetching: { ...state.isFetching, favoriteArtworks: false },
        favoriteArtworksTotal: hydraTool.getTotal(action.params.response),
        favoriteArtworksLast: hydraTool.getLastPage(action.params.response),
      };
    }

    case GET_ACCESSED_PACKS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          packAccesses: true,
        },
      };

    case GET_ACCESSED_PACKS_FAILURE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          packAccesses: false,
        },
      };

    case GET_ACCESSED_PACKS_SUCCESS:
      return {
        ...state,
        packAccesses: action.response,
        isFetching: {
          ...state.isFetching,
          packAccesses: false,
        },
      };

    case USER_MARK_AS_READ_SUCCESS: {
      const updatedState = { ...state };
      if (action.params.packId) {
        let visitorPackAccesses = state.visitorPackAccesses ? [...state.visitorPackAccesses] : [];
        let found = false;
        const pack = {
          id: action.params.packId,
          artworkId: action.params.artworkId,
          readingDate: action.params.file.file.createdAt,
          lastFileRead: { id: action.params.file.file.id },
          lastFileReadDate: action.params.date,
        };
        visitorPackAccesses = fp.map(p => {
          if (p.id === action.params.packId) {
            found = true;
            if (pack.readingDate > p.readingDate) {
              return pack;
            }
            return {
              ...p,
              lastFileRead: pack.lastFileRead,
              lastFileReadDate: pack.lastFileReadDate,
            };
          }
          return p;
        })(visitorPackAccesses);
        if (!found) {
          visitorPackAccesses.push(pack);
        }
        updatedState.visitorPackAccesses = visitorPackAccesses;
      } else {
        let visitorArtworkAccesses = state.visitorArtworkAccesses ? [...state.visitorArtworkAccesses] : [];
        let found = false;
        const artwork = {
          id: action.params.artworkId,
          readingDate: action.params.file.createdAt,
          lastFileRead: { id: action.params.file.file.id },
          lastFileReadDate: action.params.date,
        };
        visitorArtworkAccesses = fp.map(a => {
          if (a.id === action.params.artworkId) {
            found = true;
            if (artwork.readingDate > a.readingDate) {
              return artwork;
            }
            return {
              ...a,
              lastFileRead: artwork.lastFileRead,
              lastFileReadDate: artwork.lastFileReadDate,
            };
          }
          return a;
        })(visitorArtworkAccesses);
        if (!found) {
          visitorArtworkAccesses.push(artwork);
        }
        updatedState.visitorArtworkAccesses = visitorArtworkAccesses;
      }
      return updatedState;
    }

    case SUBSCRIBE_ARTWORK:
    case UNSUBSCRIBE_ARTWORK:
      return {
        ...state,
        subscribeAction: true,
      };

    case SUBSCRIBE_ARTWORK_SUCCESS:
      return {
        ...state,
        toggleSubscribeFetching: false,
      };

    case UNSUBSCRIBE_ARTWORK_SUCCESS:
      return {
        ...state,
        toggleSubscribeFetching: false,
      };

    case ACCEPT_ARTWORK_SUCCESS: {
      const acceptedArtworks = state.acceptedArtworks ? [...state.acceptedArtworks] : [];
      return {
        ...state,
        acceptedArtworks: [...acceptedArtworks, action.action.artworkId],
      };
    }

    case ACCEPT_TUTORIAL_SUCCESS: {
      const acceptedTutorials = state.acceptedTutorials ? [...state.acceptedTutorials] : [];
      return {
        ...state,
        acceptedTutorials: [...acceptedTutorials, action.action.tutorialId],
      };
    }

    case USER_DISMISS_NOTIFICATIONS: {
      return {
        ...state,
        dismissNotifications: action.date,
      };
    }

    case CART_SHOW: {
      return {
        ...state,
        cartMode: 'cart',
      };
    }

    case CART_HIDE: {
      return {
        ...state,
        cartMode: false,
        cartLastOrder: null,
      };
    }

    case BUY_PACK: {
      return {
        ...state,
        cartMode: 'fetching',
      };
    }
    case BUY_PACK_SUCCESS: {
      return {
        ...state,
        cartMode: 'success_free',
      };
    }
    case BUY_PACK_FAILURE: {
      return {
        ...state,
        cartMode: false,
      };
    }

    case SETUP_USER_PAYMENT_METHOD: {
      return {
        ...state,
        cardFetching: true,
      };
    }

    case SETUP_USER_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        stripeSetupIntentSecret: action.response.stripeSetupIntentSecret,
        cardFetching: false,
      };
    }

    case SETUP_USER_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        cardFetching: false,
      };
    }

    case ADD_USER_CREDITCARD: {
      return {
        ...state,
        cardFetching: true,
      };
    }

    case ADD_USER_CREDITCARD_SUCCESS: {
      return {
        ...state,
        privateProfile: { ...state.privateProfile, card: action.response.card },
        cardFetching: false,
      };
    }

    case ADD_USER_CREDITCARD_FAILURE: {
      return {
        ...state,
        cardFetching: false,
      };
    }

    case REMOVE_USER_CREDITCARD: {
      return {
        ...state,
        cardFetching: true,
      };
    }

    case REMOVE_USER_CREDITCARD_SUCCESS: {
      return {
        ...state,
        privateProfile: { ...state.privateProfile, card: null },
        cardFetching: false,
      };
    }

    case REMOVE_USER_CREDITCARD_FAILURE: {
      return {
        ...state,
        cardFetching: false,
      };
    }

    case ORDERS_PLACE_QUOTE: {
      return {
        ...state,
        // cartMode: 'fetching',
        status: {
          ...state.status,
          quote: 'fetching',
        },
      };
    }
    case ORDERS_PLACE_QUOTE_SUCCESS: {
      const {
        purchase: { packId, artworkId },
        artwork,
      } = action.params.action;
      const cart = state.cart ? { ...state.cart } : { packAccesses: [], subscriptionBillings: [] };
      // PACKS
      let index = _findIndex(cart.packAccesses, purchase => purchase.pack.id === packId);
      if (index !== -1) {
        // remove from cart
        cart.packAccesses = cart.packAccesses.filter(purchase => purchase.pack.id !== packId);
      } else {
        // add to cart
        action.params.response.packAccesses.forEach(purchase => {
          if (_findIndex(cart.packAccesses, p => p.pack.id === purchase.pack.id) === -1) {
            cart.packAccesses.push(purchase);
          }
        });
        cart.packAccesses = fp.map(purchase => {
          if (purchase.pack.id === packId) {
            return {
              ...purchase,
              artwork,
            };
          }
          return purchase;
        })(cart.packAccesses);
      }
      // PACKS
      index = _findIndex(cart.subscriptionBillings, purchase => purchase.artwork.id === artworkId);
      if (index !== -1) {
        // remove from cart
        cart.subscriptionBillings = cart.subscriptionBillings.filter(purchase => purchase.artwork.id !== artworkId);
      } else {
        // add to cart
        action.params.response.subscriptionBillings.forEach(purchase => {
          if (_findIndex(cart.subscriptionBillings, p => p.artwork.id === purchase.artwork.id) === -1) {
            cart.subscriptionBillings.push(purchase);
          }
        });
        cart.subscriptionBillings = fp.map(purchase => {
          if (purchase.artwork.id === artworkId) {
            return {
              ...purchase,
              artwork: {
                ...purchase.artwork,
                ...artwork,
              },
            };
          }
          return purchase;
        })(cart.subscriptionBillings);
      }

      // UPDATE STATE
      const stateUpdated = {
        ...state,
        cart: {
          ...action.params.response,
          packAccesses: cart.packAccesses,
          subscriptionBillings: cart.subscriptionBillings,
        },
        status: {
          ...state.status,
          quote: 'success',
        },
        // cartMode: 'cart',
      };
      if (artwork) {
        stateUpdated.cartArtworkId = artwork.id;
      }
      return stateUpdated;
    }
    case ORDERS_PLACE_QUOTE_FAILURE: {
      return {
        ...state,
        status: {
          ...state.status,
          quote: 'failure',
        },
        // cartMode: 'cart',
      };
    }

    case ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS: {
      return {
        ...state,
        quoteMonthly: action.response,
      };
    }

    case CART_RESET: {
      return {
        ...state,
        cart: null,
        cartArtworkId: null,
        cartMode: false,
        cartLastOrder: null,
      };
    }

    case ORDERS_PLACE_ORDER: {
      return {
        ...state,
        cartMode: 'fetching',
        cartLastOrder: null,
      };
    }
    case ORDERS_PLACE_ORDER_SUCCESS: {
      return {
        ...state,
        cartMode: 'success',
        cart: null,
        cartLastOrder: action.response,
      };
    }
    case ORDERS_PLACE_ORDER_FAILURE: {
      return {
        ...state,
        cartMode: 'cart',
      };
    }

    case GET_USER_INCOMES: {
      return {
        ...state,
        isFetching: { ...state.isFetching, incomes: true },
        error: { ...state.error, incomes: false },
      };
    }

    case GET_USER_INCOMES_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, incomes: false },
        error: { ...state.error, incomes: true },
      };
    }

    case GET_USER_INCOMES_SUCCESS: {
      let incomes = null;
      if (action.response.page === 1) {
        incomes = action.response.incomes['hydra:member'];
      } else {
        incomes = [...state.incomes, ...action.response.incomes['hydra:member']];
      }
      return {
        ...state,
        incomes,
        incomesTotal: action.response.incomes['hydra:totalItems'],
        incomesLast: hydraTool.getLastPage(action.response.incomes),
        isFetching: { ...state.isFetching, incomes: false },
        error: { ...state.error, incomes: false },
      };
    }

    case GET_USER_BILLINGS_SUCCESS: {
      return {
        ...state,
        billings: action.response,
      };
    }

    case GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS: {
      return {
        ...state,
        subscriptionBillings: action.response,
      };
    }

    // Logout
    case LOGOUT_SUCCESS:
      return INITIAL_STATE;

    case HISTORY_LOCATION: {
      if (action.location.pathname.includes('/read/')) {
        return state;
      }
      const historyData = state.history ? [...state.history] : [];
      if (historyData.length > 0 && historyData[0] === action.location.pathname + action.location.search) {
        return state;
      }
      return {
        ...state,
        history: [action.location.pathname + action.location.search, ...historyData].slice(0, 6),
      };
    }

    // Default
    default:
      return state;
  }
}
