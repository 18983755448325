/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

import { isExpiredError } from 'services/api';

import { showNotification } from 'actions/NotificationsActions';

const helper = {
  handleError: (error, context, showDetails, title, no404) => {
    if (context) {
      Sentry.addBreadcrumb({
        category: 'saga',
        data: context,
        level: 'info',
      });
    }
    if (!no404 && (error?.status === 404 || isExpiredError(error))) {
      return { type: 'DUMMY_404' };
    }
    if (error?.data?.title) {
      Sentry.captureMessage(JSON.stringify(error.data));
      if (showDetails) {
        return showNotification({ level: 'error', title: `${error.data.title}`, message: `${error.data.detail}` });
      }
      return showNotification({ level: 'error', title, message: `${error.data.title}` });
    }
    console.log(error);
    Sentry.captureException(error);
    return showNotification({ level: 'error', title, message: 'An internal error occurred, please try again later' });
  },
};

export default helper;
