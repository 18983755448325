/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import acl from 'tools/acl';

import { logout } from 'actions/UserActions';

type Props = {
  component: Object,
  logoutAction: Function,
};

export default function PrivateRoute({ component: RouteComponent, ...rest }: Props) {
  const token = acl.getToken();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      dispatch(logout());
    }
  }, [token, dispatch]);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect
            push
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
