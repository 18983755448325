import { takeEvery, put, call } from 'redux-saga/effects';

import {
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAILURE,
} from 'constants/ActionTypes';

import notificationService from 'services/notification';

function* getUserNotification(action) {
  try {
    const news = yield call(notificationService.userNotifications, action.page ? action.page : 1);
    const response = {
      page: action.page ? action.page : 1,
      news,
    };
    yield put({ type: GET_USER_NOTIFICATIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_NOTIFICATIONS_FAILURE, error });
  }
}

/* --- WATCHERS --- */
function* watcherGetUserNotifications() {
  yield takeEvery(GET_USER_NOTIFICATIONS, getUserNotification);
}

export default [watcherGetUserNotifications];
