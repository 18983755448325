/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';

import './Input.scss';

type Props = {
  label: string,
  type: string,
  helperText: string,
  children: any,
  append: any,
  className: string,
  errorClassName: String,
  field: Object,
  form: Object,
  min: Number,
  max: Number,
  t: Object,
};

class Input extends PureComponent<Props> {
  render() {
    const {
      label,
      type,
      helperText,
      children,
      append,
      className,
      errorClassName,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      t,
      ...props
    } = this.props;
    const fieldUpdated = { ...field, value: field.value || '' };
    return (
      <div className={`Input form-group ${className || ''}`}>
        {label && <label className="Input-label flowfont-medium text-uppercase">{label}</label>}
        {append && (
          <div className="input-group mb-3">
            <input
              type={type}
              value={field.value || undefined}
              {...fieldUpdated}
              {...props}
              className="Input-field form-control"
            />
            <div className="input-group-append">{append}</div>
          </div>
        )}

        {!append && (
          <input
            type={type}
            value={field.value || undefined}
            {...fieldUpdated}
            {...props}
            className="Input-field form-control"
          />
        )}

        {helperText && !errors[field.name] && <small className="form-text text-muted">{helperText}</small>}

        {touched[field.name] && errors[field.name] && (
          <div className={`Input-error flowfont-medium text-danger ${errorClassName || ''}`}>
            {t ? t(errors[field.name]) : errors[field.name]}
          </div>
        )}

        {children && children}
      </div>
    );
  }
}

export default Input;
