import _indexOf from 'lodash/indexOf';

const urlTool = {
  urlParams: params =>
    Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&'),
  artworksUrl: () => [/artwork\/[.0-9a-zA-Z_-]+\/[.0-9a-zA-Z_-]+/, /artwork\/[.0-9a-zA-Z_-]+/],
  isPlayer: pathname => pathname.indexOf('/player/') === 0 || pathname.indexOf('/read/') === 0,
  isUserSettings: pathname => pathname === '/profile/settings',
  hasHeader: pathname => {
    const urls = [
      '/profile/settings',
      '/favorites/artworks',
      '/profile/',
      '/artwork/',
      '/flow/',
      '/pack/',
      '/news/add',
      '/flowers',
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
      if (pathname.indexOf(urls[i]) !== -1) {
        return true;
      }
    }
    return false;
  },
  hasNoLogoMenu: pathname => {
    if (pathname === '/favorites/artworks') {
      return true;
    }
    const urls = urlTool.artworksUrl();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
      const match = pathname.match(urls[i]);
      if (match && pathname.indexOf(match[0]) !== -1) {
        return true;
      }
    }
    return false;
  },
  isArtworkPage: pathname => {
    const urls = urlTool.artworksUrl();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
      const match = pathname.match(urls[i]);
      if (match && pathname.indexOf(match[0]) !== -1) {
        return true;
      }
    }
    return false;
  },
  isPackPage: pathname => urlTool.isArtworkPage(pathname) && pathname.indexOf('/pack'),
  isFullPage: pathname => {
    const urls = ['/', '/prices', '/terms-and-conditions', '/content-policy', '/help/adult', '/contact'];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
      const match = pathname.match(urls[i]);
      if (match && pathname.indexOf(match[0]) !== -1) {
        return true;
      }
    }
    return false;
  },
  isPlayerPage: pathname => {
    const urls = ['/read/'];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < urls.length; i++) {
      const match = pathname.match(urls[i]);
      if (match && pathname.indexOf(match[0]) !== -1) {
        return true;
      }
    }
    return false;
  },
  hasDiscovery: pathname => _indexOf(['/home'], pathname) !== -1,
  isVerticallyCentered: pathname => _indexOf(['/register', '/login', '/forgot', '/reset'], pathname) !== -1,
};

export default urlTool;
