/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import seo from 'tools/seo';

import { getLangFromPath, getPreferedLanguage, setPreferedLanguage } from '../../i18n/i18n';
import CustomRedirect from './Redirect/Redirect';

type Props = {
  component: Object,
  i18nRoutes: string[],
  i18nComponents: [],
};

const TranslatedRoute = ({ component: DefaultComponent, i18nRoutes, i18nComponents, ...rest }: Props) => {
  const { i18n, ready } = useTranslation();
  const pageLangPath = rest.computedMatch.params.lang;
  const pageLang = getLangFromPath(pageLangPath);
  const displayLanguage = useSelector(state => state?.user?.privateProfile?.displayLanguage);
  // console.log(pageLangPath, pageLang, i18n.language, i18nRoutes, i18nComponents);

  const preferedLanguage = getPreferedLanguage();
  if (
    typeof document !== 'undefined' &&
    !displayLanguage &&
    !preferedLanguage &&
    i18nRoutes &&
    i18nRoutes[i18n.language]
  ) {
    setPreferedLanguage(i18n.language);
    if (i18n.language !== pageLang) {
      return <CustomRedirect to={i18nRoutes[i18n.language]} />;
    }
  }

  let RouteComponent = DefaultComponent;
  if (ready && !displayLanguage && pageLang && pageLang !== i18n.language) {
    i18n.changeLanguage(pageLang);
  }
  if (i18nComponents && i18nComponents[pageLang]) {
    RouteComponent = i18nComponents[pageLang];
  }
  const alternates = Object.keys(i18nRoutes || [])
    .filter(key => key !== pageLang)
    .map(langKey => ({
      lang: langKey,
      url: i18nRoutes[langKey],
    }));
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Helmet>{seo.pageLang(pageLang, alternates)}</Helmet>
          <RouteComponent {...props} />
        </>
      )}
    />
  );
};

export default TranslatedRoute;
