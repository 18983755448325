import {
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAILURE,
} from 'constants/ActionTypes';

import hydraTool from '../tools/hydra';

const INITIAL_STATE = {
  isFetching: {
    userNotifications: false,
  },
  error: {
    userNotifications: false,
  },
};

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    // User notifications
    case GET_USER_NOTIFICATIONS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, userNotifications: true },
        error: { ...state.error, userNotifications: false },
      };
    }
    case GET_USER_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, userNotifications: false },
        error: { ...state.error, userNotifications: true },
      };
    }
    case GET_USER_NOTIFICATIONS_SUCCESS: {
      let news = [];
      if (action.response.page === 1) {
        news = hydraTool.getItems(action.response.news);
      } else {
        news = [...state.userNotifications, ...hydraTool.getItems(action.response.news)];
      }
      return {
        ...state,
        userNotifications: news,
        userNotificationsTotal: hydraTool.getTotal(action.response.news),
        userNotificationsLast: hydraTool.getLastPage(action.response.news),
        isFetching: { ...state.isFetching, userNotifications: false },
        error: { ...state.error, userNotifications: false },
      };
    }

    // Default
    default:
      return state;
  }
}
