import Notifications from 'react-notification-system-redux';

/**
 * Return a notification with specified level (success, error, warning and info)
 * @param {object} params - the message and the level of the message to show
 */
export function showNotification(params) {
  if (params && params.level) {
    switch (params.level) {
      case 'success':
        return Notifications.success(params);
      case 'error':
        return Notifications.error(params);
      // return Notifications.error({ ...params, autoDismiss: 0 });
      case 'warning':
        return Notifications.warning(params);
      case 'global':
        return Notifications.info({ ...params, position: 'tc', autoDismiss: 0, uid: 'global' });
      case 'info':
      default:
        return Notifications.info(params);
    }
  }
  return false;
}
