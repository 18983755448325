const hydraTool = {
  getItems: params => params?.['hydra:member'],
  getTotal: params => {
    if (params?.['hydra:totalItems']) {
      return params['hydra:totalItems'];
    }
    return 0;
  },
  getLastPage: params => {
    if (params && params['hydra:view'] && params['hydra:view']['hydra:last']) {
      const page = params['hydra:view']['hydra:last'];
      const last = page.replace(/.*page=(.*)/, '$1');
      try {
        return parseInt(last, 10);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  },
};

export default hydraTool;
