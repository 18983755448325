import { takeEvery, put, call, all } from 'redux-saga/effects';

import { push } from 'react-router-redux';

import {
  GET_USER_NEWS,
  GET_USER_NEWS_SUCCESS,
  GET_USER_NEWS_FAILURE,
  GET_ARTWORK_NEWS_BY_ID,
  GET_ARTWORK_NEWS_BY_ID_SUCCESS,
  GET_ARTWORK_NEWS_BY_ID_FAILURE,
  GET_ARTWORK_NEWS,
  GET_ARTWORK_NEWS_SUCCESS,
  GET_ARTWORK_NEWS_FAILURE,
  UPSERT_NEWS,
  UPSERT_NEWS_SUCCESS,
  UPSERT_NEWS_FAILURE,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILURE,
} from 'constants/ActionTypes';

import newsService from 'services/news';

import { showNotification } from 'actions/NotificationsActions';

import SagaHelper from './helper';

function* getUserNews(action) {
  try {
    const news = yield call(newsService.getUserNews, action.page ? action.page : 1);
    const response = {
      page: action.page ? action.page : 1,
      news,
    };
    yield put({ type: GET_USER_NEWS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_NEWS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getArtworkNews(action) {
  try {
    const news = yield call(newsService.getArtworkNews, action.id, action.page ? action.page : 1);
    const response = {
      page: action.page ? action.page : 1,
      news,
    };
    yield put({ type: GET_ARTWORK_NEWS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ARTWORK_NEWS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getArtworkNewsById(action) {
  try {
    const response = yield call(newsService.getById, action.id);
    yield put({ type: GET_ARTWORK_NEWS_BY_ID_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ARTWORK_NEWS_BY_ID_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put(push('/home'));
  }
}

function* upsertNews(action) {
  try {
    let response;
    // console.log(action.formData);

    if (Number.isInteger(action.formData.id)) {
      response = yield call(newsService.put, {
        id: action.formData.id,
        ...action.formData.formData,
      });
      yield put(showNotification({ level: 'info', message: 'News updated' }));
    } else {
      response = yield call(newsService.post, action.formData.formData);
      yield put(showNotification({ level: 'info', message: 'News created' }));
    }

    if (action.formData.newsFiles) {
      yield all(
        action.formData.newsFiles.map(nf =>
          call(newsService.linkArtworkNewsAndFiles, { artworkNews: response.id, file: nf.fileId }),
        ),
      );
    }

    yield put({ type: UPSERT_NEWS_SUCCESS, response });
    yield put(push(action.formData.url || '/home'));
  } catch (error) {
    yield put({ type: UPSERT_NEWS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* deleteNews(action) {
  try {
    yield call(newsService.delete, action.id);
    yield put(showNotification({ level: 'info', message: 'News deleted' }));
    yield put({ type: DELETE_NEWS_SUCCESS });
    yield put({ type: GET_USER_NEWS });
  } catch (error) {
    yield put({ type: DELETE_NEWS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

/* --- WATCHERS --- */
function* watcherGetUserNews() {
  yield takeEvery(GET_USER_NEWS, getUserNews);
}

function* watcherGetArtworkNews() {
  yield takeEvery(GET_ARTWORK_NEWS, getArtworkNews);
}

function* watcherGetArtworkNewsById() {
  yield takeEvery(GET_ARTWORK_NEWS_BY_ID, getArtworkNewsById);
}

function* watcherUpsertNews() {
  yield takeEvery(UPSERT_NEWS, upsertNews);
}

function* watcherDeleteNews() {
  yield takeEvery(DELETE_NEWS, deleteNews);
}

export default [
  watcherGetUserNews,
  watcherGetArtworkNews,
  watcherGetArtworkNewsById,
  watcherUpsertNews,
  watcherDeleteNews,
];
