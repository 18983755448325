import { fork, all } from 'redux-saga/effects';

import ArtworkSaga from './ArtworkSaga';
import CommentSaga from './CommentSaga';
import FileSaga from './FileSaga';
import NewsSaga from './NewsSaga';
import NotificationSaga from './NotificationSaga';
import PackSaga from './PackSaga';
import TaxonomySaga from './TaxonomySaga';
import UserSaga from './UserSaga';

const sagas = [
  ...UserSaga,
  ...ArtworkSaga,
  ...NewsSaga,
  ...PackSaga,
  ...CommentSaga,
  ...NotificationSaga,
  ...TaxonomySaga,
  ...FileSaga,
];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}
