import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SocialSVGs from 'svgs/social';

import { getLinkPrefix } from '../../../i18n/i18n';
import './Footer.scss';

export default function Footer() {
  const { t, i18n } = useTranslation('footer');
  const LangPrefix = getLinkPrefix(i18n);
  return (
    <div className="Footer flowfont-regular">
      {/* <a href="https://flowfo.helpscoutdocs.com" target="_blank" rel="noopener noreferrer">
          Q&amp;A
        </a>
        &nbsp;-&nbsp; */}
      <Link to={`${LangPrefix}/prices`}>{t('prices')}</Link>
      <br />
      <Link to={`${LangPrefix}/terms-and-conditions`}>{t('terms-conditions')}</Link>
      <br />
      <Link to={`${LangPrefix}/content-policy`}>{t('content-policy')}</Link>
      <br />
      <Link to={`${LangPrefix}/contact`}>{t('about-us')}</Link>
      <br />
      <a href="https://twitter.com/Flowfo_" target="_blank" rel="noopener noreferrer">
        <SocialSVGs.Twitter width="30" height="30" />
      </a>
    </div>
  );
}
