import React from 'react';

import { Route, Redirect } from 'react-router-dom';

type Props = {
  to: string,
  code: number,
};

const CustomRedirect = ({ to, code }: Props) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code || 302;
        return <Redirect to={to || '/'} />;
      }}
    />
  );
};

export default CustomRedirect;
