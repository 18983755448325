import {
  GET_TAXONOMY_LANGUAGES_SUCCESS,
  GET_TAXONOMY_CATEGORIES_SUCCESS,
  GET_TAXONOMY_COUNTRIES_SUCCESS,
  GET_TAXONOMY_COUNTRIES_FAILURE,
  GET_TAXONOMY_COUNTRIES,
} from '../constants/ActionTypes';

const INITIAL_STATE = {
  isFetching: {
    getCountries: false,
  },
  error: {
    getCountries: false,
  },
};

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TAXONOMY_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.response,
      };
    }
    case GET_TAXONOMY_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.response,
      };
    }
    case GET_TAXONOMY_COUNTRIES: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getCountries: true },
        error: { ...state.error, getCountries: false },
      };
    }
    case GET_TAXONOMY_COUNTRIES_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, getCountries: false },
        error: { ...state.error, getCountries: true },
      };
    }
    case GET_TAXONOMY_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.response,
        isFetching: { ...state.isFetching, getCountries: false },
        error: { ...state.error, getCountries: false },
      };
    }
    // Default
    default:
      return state;
  }
}
