import React from 'react';

export default function Icon() {
  return (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_152_4013)">
        <path
          d="M7.47317 13.6643L2.86586 25.4763C2.16379 27.6411 4.32849 28.8376 5.4986 29.0082C6.20555 29.1057 9.138 29.3007 9.37202 29.3007C9.60604 29.3007 9.56704 29.447 9.51829 29.5201C9.34765 29.7151 8.1782 31.3332 7.52744 32.8248C6.91334 34.2323 7.72912 34.9236 8.53172 35.2612C11.1401 36.3581 14.068 38.0995 14.7701 38.4505C15.4646 38.7978 15.2223 39.5846 15.1226 39.9082L15.1194 39.9186C14.8513 40.5036 14.2327 42.3244 13.5891 43.67C12.9455 45.0156 13.8681 44.748 14.3313 44.4067C16.574 42.8466 21.8646 39.1087 22.6252 38.5236C23.3857 37.9386 22.8852 36.9228 22.4221 36.6059C21.4713 35.9965 16.9429 33.4046 16.1893 33.025C15.17 32.5116 15.17 32.1973 16.1893 31.9224C16.6984 31.785 22.8689 30.8729 24.008 31.0189C27.8108 31.5065 37.545 32.6886 39.6512 32.8056C41.7574 32.9226 41.7151 31.5191 41.6663 30.7634C41.2346 23.6886 40.8203 10.4173 40.7513 8.20648L40.7482 8.10632C40.6897 6.23414 38.5298 6.00987 37.4572 6.13176C29.9247 7.57002 14.1867 10.5782 11.4954 11.1047C8.80417 11.6313 7.69256 13.0305 7.47317 13.6643Z"
          fill="#242424"
        />
        <path
          d="M6.15672 27.3138L12.2187 28.0831L9.87844 32.9182C9.29339 34.0151 9.90282 34.1127 10.2441 34.3077C10.2441 34.3077 16.5883 37.5296 17.1753 37.8694C17.7624 38.2093 17.0858 39.416 16.8177 39.7086L15.0521 42.8801C16.0272 42.1976 19.3254 39.1277 20.0275 38.4842C20.7296 37.8406 20.7084 37.3802 20.0275 36.9484C19.028 36.3146 16.0012 34.6166 14.7808 33.9283C13.5202 33.2173 13.9376 31.7961 14.2635 31.3875C15.0914 30.3499 20.0275 29.4857 24.165 29.3286C26.359 29.2453 35.0893 30.4635 37.254 30.5805C39.4188 30.6975 39.8137 29.1179 39.7405 28.3134C39.3261 22.8773 38.2324 12.2439 38.1153 10.3718C37.9983 8.49958 36.1652 8.47032 35.2632 8.68972C28.7057 10.0305 14.5518 12.6681 12.5626 13.0776C10.5734 13.4872 9.73485 14.7109 9.56421 15.2716L4.94319 25.063C4.29963 27.0522 5.62494 27.2468 6.15672 27.3138Z"
          fill="white"
        />
        <path
          d="M19.0278 2.90577C18.3799 11.5005 14.6105 29.9085 12.8068 37.2332C13.254 37.2332 20.5755 35.5322 24.4218 34.6817L26.2351 22.979H31.9335C32.4352 19.847 32.8829 18.0266 33.3703 14.4502H27.7077L28.152 11.8844H34.5373L36.4404 2.03138C35.5751 1.97978 24.458 2.5928 19.0278 2.90577Z"
          fill="#242424"
        />
        <path
          d="M34.3797 4.05859L20.432 4.7634C19.9788 10.3957 16.6096 26.7897 14.9816 34.4838L22.6826 32.9133L24.627 21.3189L30.5118 21.4568L31.3733 16.1626H25.3237L26.6174 10.4102L33.2544 10.234L34.3797 4.05859Z"
          fill="#6061C4"
        />
        <g clipPath="url(#clip1_152_4013)">
          <path
            d="M7.47292 13.4339L2.86561 25.2458C2.16355 27.4106 4.32825 28.6072 5.49836 28.7777C6.2053 28.8752 9.13776 29.0703 9.37178 29.0703C9.6058 29.0703 9.5668 29.2165 9.51804 29.2896C9.3474 29.4847 8.17796 31.1027 7.5272 32.5943C6.9131 34.0019 7.72888 34.6932 8.53147 35.0307C11.1398 36.1277 14.0678 37.869 14.7698 38.22C15.4644 38.5673 15.2221 39.3541 15.1224 39.6778L15.1192 39.6881C14.8511 40.2731 14.2324 42.0939 13.5889 43.4395C12.9453 44.7851 13.8679 44.5175 14.331 44.1763C16.5737 42.6161 21.8644 38.8782 22.6249 38.2932C23.3855 37.7081 22.885 36.6923 22.4218 36.3754C21.4711 35.766 16.9426 33.1741 16.189 32.7945C15.1698 32.2811 15.1698 31.9669 16.189 31.6919C16.6982 31.5546 22.8686 30.6424 24.0077 30.7885C27.8106 31.276 37.5448 32.4581 39.6509 32.5752C41.7571 32.6922 41.7148 31.2886 41.6661 30.5329C41.2344 23.4581 40.8201 10.1868 40.751 7.97601L40.7479 7.87585C40.6894 6.00367 38.5296 5.7794 37.457 5.90129C29.9244 7.33955 14.1864 10.3477 11.4952 10.8743C8.80392 11.4008 7.69232 12.8001 7.47292 13.4339Z"
            fill="#242424"
          />
          <path
            d="M6.1566 27.0843L12.2185 27.8536L9.87832 32.6887C9.29327 33.7857 9.9027 33.8832 10.244 34.0782C10.244 34.0782 16.5882 37.3001 17.1752 37.6399C17.7623 37.9798 17.0857 39.1866 16.8176 39.4791L15.052 42.6506C16.0271 41.9681 19.3253 38.8983 20.0274 38.2547C20.7294 37.6111 20.7083 37.1507 20.0274 36.7189C19.0279 36.0851 16.0011 34.3871 14.7807 33.6988C13.5201 32.9878 13.9374 31.5666 14.2634 31.158C15.0912 30.1204 20.0274 29.2562 24.1649 29.0991C26.3589 29.0158 35.0892 30.234 37.2539 30.351C39.4186 30.468 39.8135 28.8884 39.7404 28.0839C39.326 22.6478 38.2322 12.0144 38.1152 10.1423C37.9982 8.27008 36.165 8.24083 35.2631 8.46023C28.7056 9.80098 14.5517 12.4386 12.5625 12.8481C10.5733 13.2577 9.73472 14.4814 9.56408 15.0421L4.94307 24.8335C4.29951 26.8227 5.62482 27.0173 6.1566 27.0843Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_152_4013">
          <rect width="45" height="45" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip1_152_4013">
          <rect width="38.9656" height="12.2553" fill="white" transform="translate(2.72852 32.1914)" />
        </clipPath>
      </defs>
    </svg>
  );
}
