import loadable from '@loadable/component';
import classNames from 'classnames';
import { getDisplayLangFromName } from 'i18n/i18n';

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Notifications from 'react-notification-system-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import _isNil from 'lodash/isNil';

import seo from 'tools/seo';
import { useTheme } from 'tools/theme';
import url from 'tools/url';

import { dismissNotifications } from 'actions/UserActions';

import GlobalNotification from 'components/GlobalNotification';

import DecorationSVGs from 'svgs/decoration';

import './Layout.scss';
import Menu from './Menu';
import SecondaryNav from './SecondaryNav';

const PlayerModal = loadable(() => import('components/Player/PlayerModal'));
const CartModal = loadable(() => import('components/Modal/CartModal'));

type Props = {
  children: Object,
};

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const { i18n, ready } = useTranslation();
  const theme = useTheme();

  const notifications = useSelector(state => state.notifications);
  const userCart = useSelector(state => state.user?.cart);
  const playerState = useSelector(state => state.player);
  const cartMode = useSelector(state => state.user?.cartMode);
  const hasGlobalNotif = useSelector(state => state.user?.privateProfile?.billingFailure);
  const displayLanguage = useSelector(state => state?.user?.privateProfile?.displayLanguage);

  if (
    ready &&
    !url.isUserSettings(location.pathname) &&
    displayLanguage?.name &&
    getDisplayLangFromName(displayLanguage.name) !== i18n.language
  ) {
    i18n.changeLanguage(getDisplayLangFromName(displayLanguage.name));
  }

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.getElementsByTagName('html')[0].className = theme;
    }
  }, [theme]);

  const dispatch = useDispatch();
  const handleCloseGlobalNotif = () => {
    dispatch(dismissNotifications(new Date()));
  };

  const isPlayer = url.isPlayer(location.pathname);
  const noHeader = url.hasHeader(location.pathname);
  const isVerticallyCentered = url.isVerticallyCentered(location.pathname);
  const isWebFlow = url.isFullPage(location.pathname);
  return (
    <>
      {/* DEFAULT SEO METAS */}
      <Helmet>
        {seo.title('Comics, BD, mangas, webtoons')}
        {seo.description(
          'Read great stories for free. Support indie creators and unlock exclusive contents. Publish your own webcomic, manga or webtoon online. Action, fantasy, gay romance... You will find what you love.',
        )}
        {seo.image('https://flowfo.me/flowfo-preview.png', true)}
        <meta name="twitter:site" content="@Flowfo_" />
        <meta property="og:logo" content="https://flowfo.me/favicon.png" />
        <link rel="apple-touch-icon" href="/assets/images/flowfo-icon-192.png" />
        <link rel="preconnect" href={process.env.RAZZLE_URL_API} />
        <link rel="preconnect" href="https://images.weserv.nl" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch" href="https://sentry.io" />
        <script type="application/ld+json">{`
          [
            {
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "Flowfo",
                "url": "https://flowfo.me/",
                "potentialAction": [{
                    "@type": "SearchAction",
                    "target": "https://flowfo.me/discover?search={search_term_string}",
                    "query-input": "required name=search_term_string"
                }]
            },
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Flowfo",
                "url": "https://flowfo.me/",
                "logo": "https://flowfo.me/favicon.png",
                "sameAs": [
                    "https://twitter.com/Flowfo_"
                ]
            }
          ]        
        `}</script>
      </Helmet>
      {isPlayer && children}
      {!isPlayer && (
        <>
          {hasGlobalNotif && (
            <GlobalNotification type="subscriptions" userCart={userCart} onClose={handleCloseGlobalNotif} />
          )}
          <div
            className={classNames(
              'Layout',
              theme,
              { 'no-header': noHeader },
              { 'vertical-center': isVerticallyCentered },
              { full: isWebFlow },
              { 'global-notif': hasGlobalNotif },
            )}
          >
            <Menu />
            <SecondaryNav />
            <div className="MainContent container-fluid">
              {/* <div className="MainContent-bgPack">
                <DecorationSVGs.LogoFlower width="500px" height="500px" fill="#fff" />
              </div>
            */}
              <div className="MainContent-bgFlow">
                <DecorationSVGs.LogoLayout />
              </div>

              {children}
            </div>
            {!_isNil(cartMode) && cartMode !== false && <CartModal />}
            {playerState?.params && (
              <PlayerModal
                playerParams={playerState.params}
                artwork={playerState.artwork}
                noDisclaimer={playerState.noDisclaimer}
              />
            )}
            <GlobalNotification type="pwa-update" />
            <GlobalNotification type="pwa-offline" />
          </div>
        </>
      )}
      <Notifications notifications={notifications} />
    </>
  );
};

export default Layout;
