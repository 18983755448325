import fp from 'lodash/fp';

import {
  GET_FILE_COMMENTS,
  GET_NEWS_COMMENTS,
  GET_FILE_COMMENTS_SUCCESS,
  GET_NEWS_COMMENTS_SUCCESS,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT,
  REMOVE_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  REPORT_COMMENT_SUCCESS,
} from '../constants/ActionTypes';

const INITIAL_STATE = {
  isFetching: {
    addComment: false,
  },
  error: {
    addComment: false,
  },
};

export default function comment(state = INITIAL_STATE, action) {
  switch (action.type) {
    // File Comments
    case GET_FILE_COMMENTS: {
      return {
        ...state,
        fileComments: null,
      };
    }
    case GET_FILE_COMMENTS_SUCCESS: {
      return {
        ...state,
        fileComments: action.response.comments,
      };
    }

    // News Comments
    case GET_NEWS_COMMENTS: {
      const newsComments = state.newsComments || {};
      newsComments[action.id] = null;
      return {
        ...state,
        newsComments,
      };
    }
    case GET_NEWS_COMMENTS_SUCCESS: {
      const newsComments = state.newsComments || {};
      return {
        ...state,
        newsComments: { ...newsComments, [action.params.action.id]: action.params.response.comments },
      };
    }

    case ADD_COMMENT: {
      return {
        ...state,
        isFetching: { ...state.isFetching, addComment: true },
        error: { ...state.error, addComment: false },
      };
    }
    case ADD_COMMENT_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, addComment: false },
        error: { ...state.error, addComment: true },
      };
    }
    case ADD_COMMENT_SUCCESS: {
      // first level comment on file
      if (action.params.response.file) {
        return {
          ...state,
          fileComments: [action.params.response, ...state.fileComments],
          isFetching: { ...state.isFetching, addComment: false },
          error: { ...state.error, addComment: false },
        };
      }
      // first level comment on news
      if (action.params.response.artworkNews) {
        const newsComments = state.newsComments || {};
        return {
          ...state,
          newsComments: {
            ...newsComments,
            [action.params.response.artworkNews.id]: [
              action.params.response,
              ...state.newsComments[action.params.response.artworkNews.id],
            ],
          },
          isFetching: { ...state.isFetching, addComment: false },
          error: { ...state.error, addComment: false },
        };
      }
      // second level comment on existing comment
      let found = false;
      const fileComments = fp.map(c => {
        if (c.id === action.params.action.commentId) {
          found = true;
          return {
            ...c,
            comments: [...c.comments, action.params.response],
          };
        }
        return c;
      })(state.fileComments);
      if (found) {
        return {
          ...state,
          fileComments,
          isFetching: { ...state.isFetching, addComment: false },
          error: { ...state.error, addComment: false },
        };
      }
      if (state.newsComments) {
        const newsComments = fp.map(c => {
          if (c.id === action.params.action.commentId) {
            return {
              ...c,
              comments: [...c.comments, action.params.response],
            };
          }
          return c;
        })(state.newsComments[action.params.action.parentId]);
        return {
          ...state,
          fileComments,
          newsComments: { ...state.newsComments, [action.params.action.parentId]: newsComments },
          isFetching: { ...state.isFetching, addComment: false },
          error: { ...state.error, addComment: false },
        };
      }
      return {
        ...state,
        isFetching: { ...state.isFetching, addComment: false },
        error: { ...state.error, addComment: false },
      };
    }

    case REMOVE_COMMENT_SUCCESS: {
      const match = c => c.id === action.action.id;
      const noMatch = c => c.id !== action.action.id;
      // NEWS
      if (state.newsComments && state.newsComments[action.action.parentId]) {
        if (state.newsComments[action.action.parentId].find(match)) {
          return {
            ...state,
            newsComments: {
              ...state.newsComments,
              [action.action.parentId]: state.newsComments[action.action.parentId].filter(noMatch),
            },
          };
        }
        // subcomment
        let found = false;
        const newsComments = fp.map(c => {
          if (c.comments.find(match)) {
            found = true;
            return {
              ...c,
              comments: c.comments.filter(noMatch),
            };
          }
          return c;
        })(state.newsComments[action.action.parentId]);
        if (found) {
          return {
            ...state,
            newsComments: { ...state.newsComments, [action.action.parentId]: newsComments },
          };
        }
      }
      // FILE
      if (state.fileComments) {
        if (state.fileComments.find(match)) {
          return {
            ...state,
            fileComments: state.fileComments.filter(noMatch),
          };
        }
        // subcomment
        let found = false;
        const fileComments = fp.map(c => {
          if (c.comments.find(match)) {
            found = true;
            return {
              ...c,
              comments: c.comments.filter(noMatch),
            };
          }
          return c;
        })(state.fileComments);
        if (found) {
          return {
            ...state,
            fileComments,
          };
        }
      }
      return state;
    }

    case REPORT_COMMENT_SUCCESS: {
      const match = c => c.id === action.params.action.id;
      // NEWS
      let found = false;
      if (state.newsComments && state.newsComments[action.params.action.parentId]) {
        let newsComments = fp.map(c => {
          if (match(c)) {
            found = true;
            return action.params.response;
          }
          return c;
        })(state.newsComments[action.params.action.parentId]);
        if (!found) {
          // subcomment
          newsComments = fp.map(c => {
            if (c.comments.find(match)) {
              found = true;
              return {
                ...c,
                comments: fp.map(sc => {
                  if (match(sc)) {
                    return action.params.response;
                  }
                  return sc;
                })(c.comments),
              };
            }
            return c;
          })(state.newsComments[action.params.action.parentId]);
        }
        if (found) {
          return {
            ...state,
            newsComments: { ...state.newsComments, [action.params.action.parentId]: newsComments },
          };
        }
      }
      // FILE
      if (state.fileComments) {
        let fileComments = fp.map(c => {
          if (match(c)) {
            found = true;
            return action.params.response;
          }
          return c;
        })(state.fileComments);
        // subcomment
        if (!found) {
          fileComments = fp.map(c => {
            if (c.comments.find(match)) {
              found = true;
              return {
                ...c,
                comments: fp.map(sc => {
                  if (match(sc)) {
                    return action.params.response;
                  }
                  return sc;
                })(c.comments),
              };
            }
            return c;
          })(state.fileComments);
        }
        if (found) {
          return {
            ...state,
            fileComments,
          };
        }
      }
      return state;
    }

    // Default
    default:
      return state;
  }
}
