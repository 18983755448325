import * as Yup from 'yup';

export default Yup.object().shape({
  username: Yup.string()
    .min(3, 'Sorry, min. 3 characters.')
    .max(30, 'Sorry, max. 30 characters.')
    .matches(/^[a-zA-Z0-9\-_ ]+$/, { message: 'input-username-invalid' })
    .required('input-username-required'),
  residenceCountry: Yup.mixed().required(),
  email: Yup.string().email('input-email-invalid').required('input-email-required'),
  plainPassword: Yup.string().min(7, 'input-password-invalid').required('input-password-required'),
  optin: Yup.bool().oneOf([true], 'input-optin-required'),
});
