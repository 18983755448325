import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { reducer as notifications } from 'react-notification-system-redux';

import animation from './AnimationReducer';
import artwork from './ArtworkReducer';
import comment from './CommentReducer';
import file from './FileReducer';
import news from './NewsReducer';
import notification from './NotificationReducer';
import pack from './PackReducer';
import player from './PlayerReducer';
import taxonomy from './TaxonomyReducer';
import user from './UserReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    animation,
    user,
    artwork,
    news,
    pack,
    notifications,
    comment,
    file,
    player,
    notification,
    taxonomy,
  });
