import React from 'react';

import artworkUtils from 'tools/artwork';

import './Avatar.scss';

type Props = {
  className: string,
  src: string,
  srcLq: string,
  alt: string,
  noLazy: Boolean,
};

const Avatar = ({ className, src, srcLq, alt, noLazy }: Props) => {
  const srcLazy = srcLq || artworkUtils.getAvatarUrl(false, 100, null, null, 50);

  return (
    <div key={src} className={`${className || ''} Avatar`}>
      <img src={noLazy ? src : srcLazy} data-src={src} alt={alt} className="Avatar-image lazyload" />
    </div>
  );
};

export default React.memo(Avatar);
