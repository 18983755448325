import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import _isEmpty from 'lodash/isEmpty';

import { Formik, Form, Field } from 'formik';

import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';

import { getCountries } from 'actions/TaxonomyActions';
import { register } from 'actions/UserActions';

import Checkbox from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { LoadingButton, LoadingBig as Loading } from 'components/Loading';

import schema from './Register.schema';

export const MODE_REGISTER = 'register';

interface IProps {
  onChangeMode(): void;
  onSuccess(): void;
}

export default function RegisterForm({ onChangeMode, onSuccess }: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation('register');

  const userProfile = useSelector(state => state.user?.privateProfile);
  const registerStatus = useSelector(state => state.user?.status?.register);
  const isFetchingCountries = useSelector(state => state.taxonomy?.isFetching?.getCountries);
  const isErrorCountries = useSelector(state => state.taxonomy?.error?.getCountries);
  const countries = useSelector(state => state.taxonomy?.countries);

  const isLoading = registerStatus === 'fetching' || (registerStatus === 'success' && !acl.isConnected(userProfile));

  useEffect(() => {
    if (onSuccess && registerStatus === 'success' && acl.isConnected(userProfile)) {
      onSuccess();
    }
  }, [registerStatus, userProfile, onSuccess]);

  // SSR
  if (typeof document === 'undefined' && _isEmpty(countries)) {
    dispatch(getCountries());
  }

  // SPA
  useEffect(() => {
    if (_isEmpty(countries)) {
      dispatch(getCountries());
    }
  }, [countries, dispatch]);

  const handleSubmit = (values, formikBag) => {
    const updatedValues = {
      ...values,
      username: values.username.replace(/ /g, '_'),
    };
    if (values.residenceCountry) {
      updatedValues.residenceCountry = { id: values.residenceCountry.value };
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'sign_up',
      },
      dataLayerName: 'PageDataLayer',
    });
    dispatch(register(updatedValues, formikBag));
  };

  let countriesValues = null;
  let defaultCountry = null;
  if (!_isEmpty(countries)) {
    defaultCountry = countries?.find(c => c.default);
    if (defaultCountry) {
      defaultCountry = { value: defaultCountry.id, label: defaultCountry.label };
    }
    countriesValues = countries?.map(o => ({ value: o.id, label: o.label }));
  }

  const initialValues = {
    username: '',
    email: '',
    plainPassword: '',
    residenceCountry: defaultCountry,
    optin: false,
  };

  return (
    <div className="Register-form">
      {(isFetchingCountries || _isEmpty(countriesValues)) && <Loading className="mt-5" />}
      {isErrorCountries && <div className="text-danger mt-3">{t('error-label')}</div>}
      {!isFetchingCountries && !_isEmpty(countriesValues) && (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {() => {
            return (
              <Form>
                <Field
                  name="username"
                  type="text"
                  label={t('input-username')}
                  component={Input}
                  errorClassName="Input-error-block"
                  t={t}
                />
                <Field name="email" type="email" label={t('input-email')} component={Input} t={t} />
                <Field name="plainPassword" type="password" label={t('input-password')} component={Input} t={t} />
                <Field
                  component={Select}
                  options={countriesValues}
                  defaultValue={defaultCountry}
                  name="residenceCountry"
                  label={t('input-country')}
                />

                <Field name="optin" type="checkbox" label={t('input-optin')} center component={Checkbox} t={t} />

                <LoadingButton
                  className="d-flex btn-primary btn-pill mt-4 mx-auto px-10"
                  id="FlowfoButtonRegister"
                  variant="flowfont-regular fs-113"
                  label={t('signup-button')}
                  isFetching={isLoading}
                />
              </Form>
            );
          }}
        </Formik>
      )}
      <div className="mt-4 text-center">
        <p className="mb-1">{t('login-title')}</p>
        <div className="flowfont-medium">
          <Link
            to="/login"
            onClick={e => {
              e.preventDefault();
              onChangeMode('login');
            }}
          >
            {t('login-link')}
          </Link>
        </div>
      </div>
    </div>
  );
}
