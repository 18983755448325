export const THEME_DARK = 'darkMode';
export const THEME_LIGHT = 'lightMode';
export const THEME_BROWSER = 'browserMode';
export const THEMES = [THEME_DARK, THEME_LIGHT, THEME_BROWSER];
export const DEFAULT_THEME = THEME_DARK;

export const CURRENCY = '€';
export const CURRENCY_LABEL = 'EURO (€)';

// see _custom-variables.scss
export const MEDIA_XS = 0;
export const MEDIA_SM = 576;
export const MEDIA_MD = 768;
export const MEDIA_LG = 992;
export const MEDIA_XL = 1100;

export const COLOR_NEUTRAL = '#707070';
export const COLOR_NEUTRAL_DARK = '#bbb';
