import api from './api';

const artwork = {
  remove: id => api.delete(`comments/${id}`),

  add: payload => api.post('comments', payload),

  getByFile: id => api.get(`files/${id}/comments`),

  getByArtworkNews: id => api.get(`artwork_news/${id}/comments`),

  report: id => api.get(`comments/${id}/report`),
};

export default artwork;
