/* eslint-disable no-console */
import { takeEvery, all, put, call } from 'redux-saga/effects';

import { push } from 'react-router-redux';

import _get from 'lodash/get';

import acl from 'tools/acl';

import artworkService from 'services/artwork';

import { getArtworkById as getArtworkByIdAction, MODE_ALLFILES, MODE_FULLPAGE } from 'actions/ArtworkActions';
import { showNotification } from 'actions/NotificationsActions';
import {
  getPrivateProfile as getPrivateProfileAction,
  getUserArtworks as getUserArtworksAction,
} from 'actions/UserActions';

import {
  GET_ARTWORK,
  GET_ARTWORK_SUCCESS,
  GET_ARTWORK_FAILURE,
  GET_ARTWORK_FILE,
  GET_ARTWORK_FILE_SUCCESS,
  GET_ARTWORK_FILE_FAILURE,
  GET_ARTWORK_FILES,
  GET_ARTWORK_FILES_SUCCESS,
  GET_ARTWORK_FILES_FAILURE,
  GET_ARTWORKS,
  GET_ARTWORKS_SUCCESS,
  GET_ARTWORKS_FAILURE,
  GET_ALL_ARTWORKS,
  GET_ALL_ARTWORKS_SUCCESS,
  GET_ALL_ARTWORKS_FAILURE,
  UPSERT_ARTWORK,
  UPSERT_ARTWORK_SUCCESS,
  UPSERT_ARTWORK_FAILURE,
  DELETE_ARTWORK,
  DELETE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAILURE,
  UPDATE_FILE_ARTWORK,
  UPDATE_FILE_ARTWORK_SUCCESS,
  UPDATE_FILE_ARTWORK_FAILURE,
  REORDER_FILE_ARTWORK,
  REORDER_FILE_ARTWORK_SUCCESS,
  REORDER_FILE_ARTWORK_FAILURE,
  MARK_AS_READ_FILE_ARTWORK,
  MARK_AS_READ_FILE_ARTWORK_SUCCESS,
  MARK_AS_READ_FILE_ARTWORK_FAILURE,
  DELETE_FILE_ARTWORK,
  DELETE_FILE_ARTWORK_SUCCESS,
  DELETE_FILE_ARTWORK_FAILURE,
  RESET_ARTWORK,
  USER_MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FLOW,
  MARK_AS_READ_FLOW_SUCCESS,
  MARK_AS_READ_FLOW_FAILURE,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  MARK_NOT_INTERESTED,
  MARK_NOT_INTERESTED_SUCCESS,
  MARK_NOT_INTERESTED_FAILURE,
  GET_ARTWORK_MARK_NEWS_AS_READ,
  GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS,
  GET_ARTWORK_MARK_NEWS_AS_READ_FAILURE,
} from '../constants/ActionTypes';
import SagaHelper from './helper';

function* getArtworkById(action) {
  try {
    if (action.forceUpdate) {
      yield put({ type: RESET_ARTWORK });
    }
    const page = action.mode === MODE_ALLFILES ? 'all' : action.page || 1;
    const pageSize = action.mode === MODE_FULLPAGE ? false : 4;
    const [artwork, files] = yield all([
      call(artworkService.getById, action.id),
      call(artworkService.getFiles, action.id, page, page !== 'all' ? pageSize : false),
    ]);

    const response = {
      artwork,
      files,
      page,
    };

    yield put({ type: GET_ARTWORK_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getArtworkFile(action) {
  try {
    const response = yield call(artworkService.getFile, action.id);
    yield put({ type: GET_ARTWORK_FILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ARTWORK_FILE_FAILURE, error });
  }
}

function* getArtworkFiles(action) {
  try {
    const files = yield call(artworkService.getFiles, action.id, action.page || 1);

    const response = {
      files,
      page: action.page || 1,
    };
    yield put({ type: GET_ARTWORK_FILES_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ARTWORK_FILES_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* upsertArtwork(action) {
  try {
    let response;
    if (Number.isInteger(action.formData.id)) {
      response = yield call(artworkService.put, action.formData);
      yield put(
        showNotification({
          level: 'info',
          title: 'Congratulations !',
          message: 'Artwork updated',
        }),
      );
    } else {
      response = yield call(artworkService.post, action.formData);
      yield put(
        showNotification({
          level: 'info',
          title: 'Congratulations !',
          message: 'Artwork created',
        }),
      );
    }

    yield put({ type: UPSERT_ARTWORK_SUCCESS, response });
    localStorage.setItem('invitation_code', null);
    yield put(getUserArtworksAction('me')); // update menu name
    // yield put(getArtworkByIdAction(response.id)); // update artwork page
    yield put(push(`/artwork/${response.author.username}/${response.uniqueName}`));
  } catch (error) {
    yield put({ type: UPSERT_ARTWORK_FAILURE, error });

    if (_get(error, 'data.violations')) {
      error.data.violations.forEach(err => {
        action.formikBag.setFieldError(err.propertyPath, err.message);
      });
    } else {
      yield put(SagaHelper.handleError(error, action));
    }
  }
}

function* getArtworks(action) {
  try {
    const artworks = yield all(action.artworks.map(artwork => call(artworkService.getById, artwork.uniqueName)));
    yield put({ type: GET_ARTWORKS_SUCCESS, artworks });
  } catch (error) {
    yield put({ type: GET_ARTWORKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getAllArtworks(action) {
  try {
    const artworks = yield call(
      artworkService.getAll,
      action.search || '',
      action.page || 1,
      action.orderBy || '',
      action.languages || [],
      action.categories || [],
      action.exclusions || [],
    );
    const response = {
      page: action.page ? action.page : 1,
      artworks,
    };
    yield put({ type: GET_ALL_ARTWORKS_SUCCESS, response });
    // if (acl.getToken()) {
    //   yield put(getPrivateProfileAction());
    // }
  } catch (error) {
    yield put({ type: GET_ALL_ARTWORKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* deleteArtwork(action) {
  try {
    yield call(artworkService.delete, action.id);
    yield put({ type: DELETE_ARTWORK_SUCCESS, action });
    yield put(showNotification({ level: 'info', message: 'Artwork deleted' }));
    yield put(push(`/profile/${action.username}`));
  } catch (error) {
    yield put({ type: DELETE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* updateFileArtwork(action) {
  try {
    const updatedFile = yield call(artworkService.putFile, action.file);
    const params = {
      file: updatedFile,
      artworkId: action.artworkId,
      moved: !!action.file.moveToPackId || !!action.file.moveToFlowId,
    };
    yield put({ type: UPDATE_FILE_ARTWORK_SUCCESS, params });
    yield put(showNotification({ level: 'success', message: 'File updated' }));
  } catch (error) {
    yield put({ type: UPDATE_FILE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_ARTWORK });
    yield put(getArtworkByIdAction(action.artworkId));
  }
}

function* reorderFileArtwork(action) {
  try {
    const artworkFiles = yield call(artworkService.reorderFile, action.id, action.order);
    yield put(showNotification({ level: 'success', message: 'File updated' }));
    yield put({ type: REORDER_FILE_ARTWORK_SUCCESS, artworkFiles });
  } catch (error) {
    yield put({ type: REORDER_FILE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_ARTWORK });
    yield put(getArtworkByIdAction(action.artworkId));
  }
}

function* markAsReadFileArtwork(action) {
  try {
    const params = { file: action.file, artworkId: action.artworkId, date: new Date() };
    if (action.artworkId) {
      // if (action.artworkId && !action.hasAffinity) {
      yield put({ type: USER_MARK_AS_READ_SUCCESS, params });
    }
    if (acl.isConnected(action.user)) {
      const response = yield call(artworkService.markFileAsRead, action.file.file.id);
      params.response = response;
    }
    yield put({ type: MARK_AS_READ_FILE_ARTWORK_SUCCESS, params });
  } catch (error) {
    console.log(error);
    yield put({ type: MARK_AS_READ_FILE_ARTWORK_FAILURE, error });
  }
}

function* deleteFileArtwork(action) {
  try {
    yield call(artworkService.deleteFile, action.id);
    yield put({ type: DELETE_FILE_ARTWORK_SUCCESS, action });
    yield put(showNotification({ level: 'success', message: 'File deleted' }));
  } catch (error) {
    yield put({ type: DELETE_FILE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
    yield put({ type: RESET_ARTWORK });
    yield put(getArtworkByIdAction(action.artworkId));
  }
}

function* markAsReadFlow(action) {
  try {
    const response = yield call(artworkService.markFlowAsRead, action.id);
    yield put({ type: MARK_AS_READ_FLOW_SUCCESS, response });
    yield put(getPrivateProfileAction());
    yield put(getArtworkByIdAction(action.id));
  } catch (error) {
    yield put({ type: MARK_AS_READ_FLOW_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getSuggestions(action) {
  try {
    const response = yield call(artworkService.suggest, action.id);
    yield put({ type: GET_SUGGESTIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_SUGGESTIONS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* markNotInterested(action) {
  try {
    const response = yield call(artworkService.markNotInterested, action.id);
    yield put({ type: MARK_NOT_INTERESTED_SUCCESS, response });
  } catch (error) {
    yield put({ type: MARK_NOT_INTERESTED_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* markNewsAsRead(action) {
  try {
    const response = yield call(artworkService.markNewsAsRead, action.id);
    yield put({ type: GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS, response });
    yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: GET_ARTWORK_MARK_NEWS_AS_READ_FAILURE, error });
    SagaHelper.handleError(error, action);
  }
}

/* --- WATCHERS --- */
function* watcherGetArtworkById() {
  yield takeEvery(GET_ARTWORK, getArtworkById);
}

function* watcherGetArtworkFile() {
  yield takeEvery(GET_ARTWORK_FILE, getArtworkFile);
}

function* watcherGetArtworkFiles() {
  yield takeEvery(GET_ARTWORK_FILES, getArtworkFiles);
}

function* watcherUpsertArtwork() {
  yield takeEvery(UPSERT_ARTWORK, upsertArtwork);
}

function* watcherGetAllArtworks() {
  yield takeEvery(GET_ALL_ARTWORKS, getAllArtworks);
}

function* watcherGetArtworks() {
  yield takeEvery(GET_ARTWORKS, getArtworks);
}

function* watcherDeleteArtwork() {
  yield takeEvery(DELETE_ARTWORK, deleteArtwork);
}

function* watcherUpdateFileArtwork() {
  yield takeEvery(UPDATE_FILE_ARTWORK, updateFileArtwork);
}

function* watcherReorderFileArtwork() {
  yield takeEvery(REORDER_FILE_ARTWORK, reorderFileArtwork);
}

function* watcherMarkAsReadFileArtwork() {
  yield takeEvery(MARK_AS_READ_FILE_ARTWORK, markAsReadFileArtwork);
}

function* watcherDeleteFileArtwork() {
  yield takeEvery(DELETE_FILE_ARTWORK, deleteFileArtwork);
}

function* watcherMarkAsReadArtwork() {
  yield takeEvery(MARK_AS_READ_FLOW, markAsReadFlow);
}

function* watcherGetSuggestions() {
  yield takeEvery(GET_SUGGESTIONS, getSuggestions);
}

function* watcherMarkNotInterested() {
  yield takeEvery(MARK_NOT_INTERESTED, markNotInterested);
}

function* watcherMarkNewsAsRead() {
  yield takeEvery(GET_ARTWORK_MARK_NEWS_AS_READ, markNewsAsRead);
}

export default [
  watcherGetArtworkById,
  watcherGetArtworkFile,
  watcherGetArtworkFiles,
  watcherUpsertArtwork,
  watcherGetArtworks,
  watcherGetAllArtworks,
  watcherDeleteArtwork,
  watcherUpdateFileArtwork,
  watcherReorderFileArtwork,
  watcherMarkAsReadFileArtwork,
  watcherDeleteFileArtwork,
  watcherMarkAsReadArtwork,
  watcherGetSuggestions,
  watcherMarkNotInterested,
  watcherMarkNewsAsRead,
];
