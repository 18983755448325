import cx from 'classnames';
import { getLinkPrefix } from 'i18n/i18n';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useLocation } from 'react-router-dom';

import Badge from 'react-bootstrap/Badge';

import { MEDIA_LG, THEME_DARK } from 'constants/Constants';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import cartUtils from 'tools/cart';
import { useTheme } from 'tools/theme';
import url from 'tools/url';

import { toggleSecondaryNav } from 'actions/AnimationActions';
import { logout, showCart } from 'actions/UserActions';

import Avatar from 'components/Avatar';
import LoginModal from 'components/Login/LoginModal';

import CommunSVGs from 'svgs/commun';
import PaymentSVGs from 'svgs/payment';

import Footer from '../Footer';
import './Menu.scss';

const renderMenuBadge = count => {
  if (count > 0) {
    return <div className="Menu-badge">{count > 9 ? '9+' : count}</div>;
  }
  return null;
};

const Menu = () => {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { i18n, t } = useTranslation('menu');
  const langPrefix = getLinkPrefix(i18n);

  // store selectors
  const userProfile = useSelector(state => state.user.privateProfile);
  const userArtworks = useSelector(state => state.user.artworks);
  const userCart = useSelector(state => state.user.cart);
  // const [showInvitation, setShowInvitation] = React.useState(false);
  const [loginModalMode, setLoginModalMode] = useState(false);

  // actions
  const getFavoriteUnreadCount = () => {
    const { artworkFileUnReadCount, packFileUnReadCount } = userProfile;
    let count = artworkFileUnReadCount > 0 ? artworkFileUnReadCount : 0;
    count += packFileUnReadCount > 0 ? packFileUnReadCount : 0;
    if (count > 9) {
      return '9+';
    }
    if (count > 0) {
      return count;
    }
    return userProfile.hasNewPack;
  };

  const loginModalModeModal = mode => e => {
    e.preventDefault();
    setLoginModalMode(mode);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleToggleSecondaryNav = () => {
    dispatch(toggleSecondaryNav());
  };

  const handleShowCart = () => {
    dispatch(showCart());
  };

  // const handleConfirmInvitation = () => {
  //   setShowInvitation(false);
  //   history.push('/artwork/add');
  // };

  const hasHeader = url.hasHeader(location.pathname);
  const hasNoLogoMenu = url.hasNoLogoMenu(location.pathname);
  const hasDiscovery = url.hasDiscovery(location.pathname);
  const userHasArtwork = userProfile?.artworksCount > 0;
  let menuLogoClassname = 'Menu-logo';
  if (isMobile && hasHeader) menuLogoClassname += ' Menu-header';
  if (isMobile && hasNoLogoMenu) menuLogoClassname += ' Menu-none';

  const cartSize = cartUtils.getCartSize(userCart);

  return (
    // <div className="Menu bg-secondary">
    <div className="Menu bgGradient">
      <div className="Menu-content">
        <div className={menuLogoClassname}>
          {(!isMobile || !hasHeader) && (
            <>
              {hasDiscovery && (
                <Link to="/discover" className="Menu-link Menu-discovery d-block d-md-none">
                  <CommunSVGs.Discover height="25" fill="var(--primary)" />
                </Link>
              )}
              {/* The Menu-Link is disable because we use now only one logo with its brand
              <Link to={`${langPrefix}/`} className="Menu-link">
                <img
                  //  Usually the name of the pict is flowfo-flower
                  // When in June (pride month) change to flowfo-flower-rainbow
                  src={`/assets/images/flowfo-flower${theme === THEME_DARK ? '-darkmode' : ''}.svg`}
                  alt="Flowfo"
                  height="90%"
                />
              </Link> */}
              <Link to={`${langPrefix}/`} className="Submenu-link">
                <img src={`/assets/images/flowfo-text${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
              </Link>
            </>
          )}
          {isMobile && (
            <div
              className="Menu-link Menu-avatar d-block d-md-none"
              onClick={handleToggleSecondaryNav}
              role="presentation"
            >
              <CommunSVGs.Menu height="25" fill="var(--neutral)" />
            </div>
          )}
        </div>

        <ul className="Menu-list">
          {acl.isConnected(userProfile) && (
            <>
              {!isMobile && (
                <li className="Menu-item d-none d-md-block">
                  <NavLink
                    to={`/profile/${userProfile.username}#artworks`}
                    isActive={() => false}
                    className="Menu-link Menu-avatar"
                  >
                    <Avatar
                      src={artworkUtils.getAvatarUrl(userProfile.avatarFile, 45)}
                      alt={userProfile.username}
                      className="border border-neutralSubtle"
                    />
                  </NavLink>
                  <ul className="Submenu-list Submenu-user flowfont-title">
                    <li className="Submenu-item Submenu-username">
                      <NavLink
                        to={`/profile/${userProfile.username}#artworks`}
                        isActive={() => false}
                        activeClassName="active"
                        className="Submenu-link"
                      >
                        {userProfile.username}
                      </NavLink>
                    </li>
                    <li className="Submenu-item">
                      <NavLink to="/profile/settings" activeClassName="active" className="Submenu-link">
                        {t('settings')}
                      </NavLink>
                    </li>
                    <li className="Submenu-item">
                      <NavLink to="/profile/payment" activeClassName="active" className="Submenu-link">
                        {t('payment')}
                      </NavLink>
                    </li>
                    <li className="Submenu-item Submenu-createArtwork">
                      <NavLink to="/artwork/add" activeClassName="active" className="Submenu-link">
                        {/* <span
                        className="Submenu-link"
                        onClick={() => setState({ showInvitation: true })}
                        role="presentation"
                      > */}
                        {/* <CommunSVGs.AddPage width="24px" height="24px" className="mb-2" />
                        <br /> */}
                        {t('create-an-artwork')}
                        {/* </span> */}
                      </NavLink>
                    </li>
                    <li className="Submenu-item logout text-neutral" onClick={handleLogout} role="presentation">
                      <span className="Submenu-link">{t('logout')}</span>
                    </li>
                  </ul>
                </li>
              )}
              {userProfile.artworksCount > 0 && (
                <li className="Menu-item">
                  <NavLink
                    to={`/profile/${userProfile.username}#artworks`}
                    activeClassName="active"
                    className="Menu-link"
                    isActive={(match, loc) => {
                      if (!url.isArtworkPage(loc.pathname)) {
                        return false;
                      }
                      return loc.pathname.includes(`/artwork/${userProfile.username}`);
                    }}
                  >
                    <CommunSVGs.AddPage width="24px" height="24px" />
                  </NavLink>
                  <ul className="Submenu-list flowfont-title">
                    {userProfile.artworksCount > 4 && (
                      <li className="Submenu-item">
                        <NavLink
                          to={`/profile/${userProfile.username}#artworks`}
                          activeClassName="active"
                          className="Submenu-link"
                        >
                          {t('my-artworks')}
                        </NavLink>
                      </li>
                    )}
                    {userProfile.artworksCount <= 4 &&
                      userArtworks &&
                      userArtworks.map(artwork => (
                        <li className="Submenu-item Submenu-item-artwork" key={artwork.id}>
                          <NavLink
                            to={`/artwork/${userProfile.username}/${artwork.uniqueName}`}
                            activeClassName="active"
                            className="Submenu-link"
                          >
                            <div className="Submenu-item-ellipsis">{artwork.name}</div>
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </li>
              )}
              {/* {userHasArtwork && ( */}
              <li className="Menu-item">
                <NavLink to="/flowers" activeClassName="active" className="Menu-link">
                  <CommunSVGs.Flower width="24px" height="24px" />
                  {renderMenuBadge(userProfile.notificationUnReadCount)}
                </NavLink>
                <ul className="Submenu-list flowfont-title">
                  <li className="Submenu-item">
                    <NavLink to="/flowers" activeClassName="active" className="Submenu-link">
                      {t('flowers')}
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* )} */}
              <li className="Menu-item">
                <NavLink to="/home" activeClassName="active" className="Menu-link news">
                  <CommunSVGs.News width="24px" height="24px" />
                  {renderMenuBadge(userProfile.newsUnReadCount)}
                </NavLink>
                <ul className="Submenu-list flowfont-title">
                  <li className="Submenu-item">
                    <NavLink to="/home" activeClassName="active" className="Submenu-link">
                      {t('news-feed')}
                    </NavLink>
                  </li>
                  {userHasArtwork && (
                    <li className="Submenu-item">
                      <NavLink to="/news/add" activeClassName="active" className="Submenu-link">
                        {t('write-news')}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
              <li className="Menu-item">
                <NavLink to="/favorites/artworks" activeClassName="active" className="Menu-link">
                  <CommunSVGs.Favorites width="24px" height="24px" />
                  {getFavoriteUnreadCount() && (
                    <div className={`Menu-badge${userProfile.hasNewPack ? ' newflow' : ''}`}>
                      {getFavoriteUnreadCount()}
                    </div>
                  )}
                </NavLink>
                <ul className="Submenu-list flowfont-title">
                  <li className="Submenu-item">
                    <NavLink to="/favorites/artworks" activeClassName="active" className="Submenu-link">
                      {t('favorites')}
                    </NavLink>
                  </li>
                  {/* <li className="Submenu-item">
                      <NavLink to="/favorites/news" activeClassName="active" className="Submenu-link">Liked news</NavLink>
                    </li> */}
                </ul>
              </li>
              {/* <li className="Menu-item">
                  <NavLink to="/messages" activeClassName="active" className="Menu-link">
                    <CommunSVGs.Envelop width="24px" height="24px" />
                  </NavLink>
                  <ul className="Submenu-list flowfont-title">
                    <li className="Submenu-item">
                      <NavLink to="/messages" activeClassName="active" className="Submenu-link">
                        Messages
                      </NavLink>
                    </li>
                  </ul>
                </li> */}
              {/* {userHasArtwork && (
                  <li className="Menu-item">
                    <NavLink to="/news/add" activeClassName="active" className="Menu-link">
                      <CommunSVGs.Add width="24px" height="24px" />
                    </NavLink>
                    <ul className="Submenu-list flowfont-title">
                      <li className="Submenu-item">
                        <NavLink to="/news/add" activeClassName="active" className="Submenu-link">
                          Write news
                        </NavLink>
                      </li>
                      <li className="Submenu-item">
                        <NavLink to="/file/add" activeClassName="active" className="Submenu-link">Upload a file</NavLink>
                      </li>
                    </ul>
                  </li>
                )} */}
            </>
          )}
          {!acl.isConnected(userProfile) && (
            <>
              {!isMobile && (
                <li className="Menu-item Menu-item-open">
                  <NavLink
                    to="/login"
                    onClick={loginModalModeModal('login')}
                    activeClassName="active"
                    className="Menu-link Menu-avatar"
                  >
                    <Avatar src="/assets/images/user-default.svg" alt="user-default" />
                  </NavLink>
                  <ul className="Submenu-list flowfont-title Submenu-list-open">
                    <li className="Submenu-item">
                      <NavLink
                        to="/login"
                        onClick={loginModalModeModal('login')}
                        activeClassName="active"
                        className="Submenu-link"
                      >
                        {t('login')}
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              <li className="Menu-item Menu-item-open">
                <NavLink to="/register" activeClassName="active" className="Menu-link">
                  <CommunSVGs.Login width="24px" height="24px" />
                </NavLink>
                <ul className="Submenu-list flowfont-title Submenu-list-open">
                  <li className="Submenu-item">
                    <NavLink
                      to="/register"
                      onClick={loginModalModeModal('register')}
                      activeClassName="active"
                      className="Submenu-link"
                    >
                      {t('signup')}
                    </NavLink>
                  </li>
                </ul>
              </li>
            </>
          )}

          <li className={`Menu-item ${!acl.isConnected(userProfile) ? 'Menu-item-open' : ''}`}>
            <NavLink to="/discover" activeClassName="active" className="Menu-link">
              <CommunSVGs.Discover width="24px" height="24px" />
            </NavLink>
            <ul className={`Submenu-list flowfont-title ${!acl.isConnected(userProfile) ? 'Submenu-list-open' : ''}`}>
              <li className="Submenu-item">
                <NavLink to="/discover" activeClassName="active" className="Submenu-link">
                  {t('discover')}
                </NavLink>
              </li>
            </ul>
          </li>
          {!isMobile && cartSize > 0 && (
            <li className="Menu-item Menu-Cart">
              <div className="Menu-link active" onClick={handleShowCart} role="presentation">
                <PaymentSVGs.Cart
                  width="30"
                  height="30"
                  fill="#fff"
                  className={cx({ animated: true, rubberBand: true })}
                />
                <Badge className={cx({ animated: true, rubberBand: true })}>{cartSize}</Badge>
              </div>
              <ul className="Submenu-list flowfont-title">
                <li className="Submenu-item">
                  <div className="Submenu-link active" onClick={handleShowCart} role="presentation">
                    {t('see-my-cart')}
                  </div>
                </li>
              </ul>
            </li>
          )}
          {isMobile && cartSize > 0 && (
            <div className="Menu-Cart-mobile" onClick={handleShowCart} role="presentation">
              <div className={cx('Menu-Cart-mobile-icon', { animated: true, rubberBand: true })}>
                <PaymentSVGs.Cart width="30" height="30" fill="#fff" />
                <Badge>{cartSize}</Badge>
              </div>
              <div className="Menu-Cart-mobile-text">{t('see-my-cart')}</div>
            </div>
          )}
        </ul>
      </div>
      <div className="Submenu">{!isMobile && <Footer />}</div>
      {/* {showInvitation && (
          <InvitationModal onCancel={() => setShowInvitation(false)} onConfirm={handleConfirmInvitation} />
        )} */}
      {loginModalMode && <LoginModal mode={loginModalMode} onClose={() => setLoginModalMode(false)} />}
    </div>
  );
};

export default Menu;
