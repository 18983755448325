const cartUtils = {
  getQuoteFromCart: cart => {
    const quote = { packs: [], artworks: [] };
    if (cart && cart.packAccesses) {
      cart.packAccesses.forEach(p => {
        quote.packs.push(p.pack.id);
      });
    }
    if (cart && cart.subscriptionBillings) {
      cart.subscriptionBillings.forEach(s => {
        quote.artworks.push(s.artwork.id);
      });
    }
    return quote;
  },
  getCartSize: cart => {
    let cartSize = 0;
    if (cart && cart.packAccesses) {
      cartSize += cart.packAccesses.length;
    }
    if (cart && cart.subscriptionBillings) {
      cartSize += cart.subscriptionBillings.length;
    }
    return cartSize;
  },
  isInQuote: (quote, flow) => {
    return quote.packs?.indexOf(flow?.packId) >= 0 || quote.artworks?.indexOf(flow?.artworkId) >= 0;
  },
};

export default cartUtils;
