/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { push } from 'connected-react-router';
import i18n from 'i18n/i18n';
import { takeEvery, put, call } from 'redux-saga/effects';

import _get from 'lodash/get';

import acl from 'tools/acl';

import userService from 'services/user';

import { getArtworkById as getArtworkByIdAction, getAllArtworks as getAllArtworksAction } from 'actions/ArtworkActions';
import { showNotification } from 'actions/NotificationsActions';
import { getPackFiles as getPackFilesAction } from 'actions/PackActions';
import {
  getPrivateProfile as getPrivateProfileAction,
  placeQuoteMonthly as placeQuoteMonthlyAction,
  getUserArtworks as getUserArtworksAction,
} from 'actions/UserActions';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  GET_PUBLIC_PROFILE,
  GET_PUBLIC_PROFILE_SUCCESS,
  GET_PUBLIC_PROFILE_FAILURE,
  GET_PRIVATE_PROFILE,
  GET_PRIVATE_PROFILE_SUCCESS,
  GET_PRIVATE_PROFILE_FAILURE,
  GET_USER_ARTWORKS,
  GET_USER_ARTWORKS_SUCCESS,
  GET_USER_ARTWORKS_FAILURE,
  GET_FAVORITE_ARTWORKS,
  GET_FAVORITE_ARTWORKS_SUCCESS,
  GET_FAVORITE_ARTWORKS_FAILURE,
  ADD_FOLLOWED_ARTWORK,
  ADD_FOLLOWED_ARTWORK_SUCCESS,
  ADD_FOLLOWED_ARTWORK_FAILURE,
  DELETE_FOLLOWED_ARTWORK,
  DELETE_FOLLOWED_ARTWORK_SUCCESS,
  DELETE_FOLLOWED_ARTWORK_FAILURE,
  SUBSCRIBE_ARTWORK,
  SUBSCRIBE_ARTWORK_SUCCESS,
  SUBSCRIBE_ARTWORK_FAILURE,
  UNSUBSCRIBE_ARTWORK,
  UNSUBSCRIBE_ARTWORK_SUCCESS,
  UNSUBSCRIBE_ARTWORK_FAILURE,
  ACCEPT_ARTWORK,
  ACCEPT_ARTWORK_SUCCESS,
  ACCEPT_TUTORIAL,
  ACCEPT_TUTORIAL_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  BUY_PACK,
  BUY_PACK_SUCCESS,
  BUY_PACK_FAILURE,
  ORDERS_PLACE_QUOTE,
  ORDERS_PLACE_QUOTE_SUCCESS,
  ORDERS_PLACE_QUOTE_FAILURE,
  ORDERS_PLACE_ORDER,
  ORDERS_PLACE_ORDER_SUCCESS,
  ORDERS_PLACE_ORDER_FAILURE,
  CART_RESET,
  ADD_USER_CREDITCARD,
  REMOVE_USER_CREDITCARD,
  SETUP_USER_PAYMENT_METHOD,
  SETUP_USER_PAYMENT_METHOD_FAILURE,
  SETUP_USER_PAYMENT_METHOD_SUCCESS,
  ADD_USER_CREDITCARD_SUCCESS,
  ADD_USER_CREDITCARD_FAILURE,
  REMOVE_USER_CREDITCARD_SUCCESS,
  REMOVE_USER_CREDITCARD_FAILURE,
  GET_USER_INCOMES,
  GET_USER_BILLINGS,
  GET_USER_INCOMES_SUCCESS,
  GET_USER_INCOMES_FAILURE,
  GET_USER_BILLINGS_SUCCESS,
  GET_USER_BILLINGS_FAILURE,
  GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS,
  GET_USER_SUBSCRIPTION_BILLINGS_FAILURE,
  GET_USER_SUBSCRIPTION_BILLINGS,
  ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS,
  ORDERS_PLACE_QUOTE_MONTHLY_FAILURE,
  ORDERS_PLACE_QUOTE_MONTHLY,
  GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS,
  GET_USER_MARK_NOTIFICATIONS_AS_READ_FAILURE,
  GET_USER_MARK_NOTIFICATIONS_AS_READ,
  GET_USER_MARK_NEWS_AS_READ,
  GET_USER_MARK_NEWS_AS_READ_SUCCESS,
  GET_USER_MARK_NEWS_AS_READ_FAILURE,
  GET_ACCESSED_PACKS_SUCCESS,
  GET_ACCESSED_PACKS_FAILURE,
  GET_ACCESSED_PACKS,
  MARK_ALL_AS_READ,
  REMOVE_LOCKED_FILES_ALERT,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  DELETE_USER_ACCOUNT,
} from '../constants/ActionTypes';
import SagaHelper from './helper';

function* login(action) {
  try {
    const response = yield call(userService.login, action.formData);
    yield put({ type: LOGIN_SUCCESS, response });

    localStorage.setItem('user_token', response.token);
    yield put({ type: GET_PRIVATE_PROFILE });
    yield put(getUserArtworksAction('me'));
    // helpscout.setUser(response);
    if (action.redirect) {
      if (!action.isRegister) {
        yield put(push('/home'));
      } else {
        yield put(push('/discover'));
      }
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, error });

    if (_get(error, 'data.violations')) {
      error.data.violations.forEach(err => {
        action.formikBag.setFieldError(err.propertyPath, err.message);
      });
    }

    if (_get(error, 'status') === 401) {
      yield put(
        showNotification({
          level: 'error',
          title: error.data.message,
          message: 'The email or password you entered is incorrect',
        }),
      );
    }
    if (!error) {
      yield put(
        showNotification({
          level: 'error',
          title: 'Ooops',
          message: 'An unexpected error has occurred. Please try again later.',
        }),
      );
      Sentry.captureException(error);
    }
    console.log(error);
  }
}

function* register(action) {
  try {
    const registerResponse = yield call(userService.register, action.formData);

    yield put({ type: REGISTER_USER_SUCCESS, registerResponse });

    const formData = {
      email: action.formData.email,
      password: action.formData.plainPassword,
    };

    yield put({ type: LOGIN, formData, formikBag: {}, isRegister: true });
  } catch (error) {
    yield put({ type: REGISTER_USER_FAILURE, error });

    if (error && error.data.violations) {
      error.data.violations.forEach(err => {
        action.formikBag.setFieldError(err.propertyPath, err.message);
      });
    }
    if (error && error.status === 401) {
      yield put(
        showNotification({
          level: 'error',
          title: error.data.message,
          message: 'The email or password you entered is incorrect',
        }),
      );
    } else if (error && error.status === 500) {
      yield put(showNotification({ level: 'error', message: `${error.data.title}` }));
    } else if (!error) {
      yield put(
        showNotification({
          level: 'error',
          title: 'Ooops',
          message: 'An unexpected error has occurred. Please try again later.',
        }),
      );
      Sentry.captureException(error);
    }
    console.log(error);
  }
}

function* logout() {
  localStorage.removeItem('user_token');
  localStorage.removeItem('user');
  yield put({ type: LOGOUT_SUCCESS });
  yield put(push('/discover'));
}

function* getPublicProfile(action) {
  try {
    const response = yield call(userService.profilePublic, action.id);
    yield put({ type: GET_PUBLIC_PROFILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PUBLIC_PROFILE_FAILURE, error });
    SagaHelper.handleError(error);
  }
}

function* getPrivateProfile() {
  try {
    const response = yield call(userService.profilePrivate);
    localStorage.setItem('user', JSON.stringify(response));

    yield put({ type: GET_PRIVATE_PROFILE_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_PRIVATE_PROFILE_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* markNoficationAsRead() {
  try {
    const response = yield call(userService.markNoficationsAsRead);
    yield put({ type: GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS, response });
    // yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: GET_USER_MARK_NOTIFICATIONS_AS_READ_FAILURE, error });
    SagaHelper.handleError(error);
  }
}

function* markNewsAsRead() {
  try {
    const response = yield call(userService.markNewsAsRead);
    yield put({ type: GET_USER_MARK_NEWS_AS_READ_SUCCESS, response });
    // yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: GET_USER_MARK_NEWS_AS_READ_FAILURE, error });
    SagaHelper.handleError(error);
  }
}

function* getUserArtworks(action) {
  try {
    const response = yield call(userService.getArtworks, action.id);

    if (action.id === 'me') {
      const user = {
        ...JSON.parse(localStorage.getItem('user')),
        artworks: response,
      };
      localStorage.setItem('user', JSON.stringify(user));
    }
    const params = {
      userId: action.id,
      response,
    };

    yield put({ type: GET_USER_ARTWORKS_SUCCESS, params });
  } catch (error) {
    yield put({ type: GET_USER_ARTWORKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getFavoriteArtworks(action) {
  try {
    const response = yield call(userService.getFavoriteArtworks, action.page);
    const params = {
      response,
      ...action,
    };
    yield put({ type: GET_FAVORITE_ARTWORKS_SUCCESS, params });
  } catch (error) {
    yield put({ type: GET_FAVORITE_ARTWORKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* markAllAsRead(action) {
  try {
    const response = yield call(userService.markAllArtworkAsRead, action.id);
    yield put({ type: MARK_ALL_AS_READ_SUCCESS, response });
    yield put(getPrivateProfileAction());
    yield put({ type: GET_FAVORITE_ARTWORKS });
  } catch (error) {
    yield put({ type: MARK_ALL_AS_READ_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* removeLockedFilesAlert() {
  yield put(showNotification({ level: 'info', message: 'This feature needs time to grow' }));
}

function* getPackAccesses(action) {
  try {
    const response = yield call(userService.getPackAccesses, action.id);
    yield put({ type: GET_ACCESSED_PACKS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_ACCESSED_PACKS_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* addFollowedArtwork(action) {
  try {
    const response = yield call(userService.addFollowedArtwork, action.params);
    // const params = {
    //   ...action.params,
    //   response,
    // };
    yield put({ type: ADD_FOLLOWED_ARTWORK_SUCCESS, response });
    yield put(getArtworkByIdAction(action.params.artworkId));
    yield put(getPrivateProfileAction());
    yield put({ type: GET_FAVORITE_ARTWORKS });
  } catch (error) {
    yield put({ type: ADD_FOLLOWED_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* deleteFollowedArtwork(action) {
  try {
    const response = yield call(userService.deleteFollowedArtwork, action.params);
    // const params = {
    //   ...action.params,
    //   response,
    // };
    yield put({ type: DELETE_FOLLOWED_ARTWORK_SUCCESS, response });
    yield put(getArtworkByIdAction(action.params.artworkId));
    yield put(getPrivateProfileAction());
    yield put({ type: GET_FAVORITE_ARTWORKS });
  } catch (error) {
    yield put({ type: DELETE_FOLLOWED_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* subscribeArtwork(action) {
  try {
    const response = yield call(userService.subscribeArtwork, action.params);
    yield put({ type: SUBSCRIBE_ARTWORK_SUCCESS, response });
    yield put(getArtworkByIdAction(action.params.artworkId));
    yield put(getPrivateProfileAction());
    yield put({ type: GET_FAVORITE_ARTWORKS });
  } catch (error) {
    yield put({ type: SUBSCRIBE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* unsubscribeArtwork(action) {
  try {
    const response = yield call(userService.unsubscribeArtwork, action.params);
    yield put({ type: UNSUBSCRIBE_ARTWORK_SUCCESS, response });
    yield put({ type: GET_FAVORITE_ARTWORKS });
    yield put(getArtworkByIdAction(action.params.artworkId));
    yield put(getPrivateProfileAction());
    yield put(placeQuoteMonthlyAction());
  } catch (error) {
    yield put({ type: UNSUBSCRIBE_ARTWORK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* acceptArtwork(action) {
  yield put({ type: ACCEPT_ARTWORK_SUCCESS, action });
  if (action.uniqueName) {
    yield put(push(`/artwork/${action.username}/${action.uniqueName}`));
  }
}

function* acceptTutorial(action) {
  yield put({ type: ACCEPT_TUTORIAL_SUCCESS, action });
}

function* updateUser(action) {
  try {
    const response = yield call(userService.updateUser, action.formData);
    yield put({ type: UPDATE_USER_SUCCESS, response });
    localStorage.setItem('user', JSON.stringify(response));
    localStorage.setItem('user_token', response.token);
    if (!action.noRedirect) {
      yield put(push(`/profile/${response.username}#${action.tabName || 'artworks'}`));
    }
  } catch (error) {
    yield put({ type: UPDATE_USER_FAILURE, error });

    if (_get(error, 'data.violations')) {
      error.data.violations.forEach(err => {
        action.formikBag.setFieldError(err.propertyPath, err.message);
      });
    } else {
      yield put(SagaHelper.handleError(error, action));
    }
  }
}

function* setupUserPaymentMethod(action) {
  try {
    const response = yield call(userService.paymentMethodSetup);
    yield put({ type: SETUP_USER_PAYMENT_METHOD_SUCCESS, response });
    yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: SETUP_USER_PAYMENT_METHOD_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* addUserCreditCard(action) {
  try {
    // const data = { card_token: action.params.token.id };
    const response = yield call(userService.updatePayment, action.params);
    yield put({ type: ADD_USER_CREDITCARD_SUCCESS, response });
    yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: ADD_USER_CREDITCARD_FAILURE, error });
    yield put(SagaHelper.handleError(error, action, true));
  }
}

function* removeUserCreditCard() {
  try {
    const data = { card_token: '' };
    const response = yield call(userService.paymentMethodSetup, data);
    yield put({ type: SETUP_USER_PAYMENT_METHOD_SUCCESS, response });
    yield put({ type: REMOVE_USER_CREDITCARD_SUCCESS, response });
  } catch (error) {
    yield put({ type: REMOVE_USER_CREDITCARD_FAILURE, error });
    yield put(SagaHelper.handleError(error, null, true));
  }
}

function* buyPack(action) {
  try {
    const response = yield call(userService.buyPack, action.packId);
    yield put({ type: BUY_PACK_SUCCESS, response });
    if (action.artworkId) {
      yield put(getArtworkByIdAction(action.artworkId));
    } else {
      yield put(getAllArtworksAction());
    }
    yield put(getPackFilesAction(action.packId));
  } catch (error) {
    yield put({ type: BUY_PACK_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* placeQuote(action) {
  try {
    if (action.quote.packs.length !== 0 || action.quote.artworks.length !== 0) {
      const response = yield call(userService.placeQuote, action.quote);
      const params = {
        response,
        action,
      };
      yield put({ type: ORDERS_PLACE_QUOTE_SUCCESS, params });
      if (action.purchase.name) {
        yield put(
          showNotification({
            level: 'info',
            title: i18n.t('pack:cart-added', { name: action.purchase.name }),
          }),
        );
      }
    } else {
      yield put({ type: CART_RESET, action });
    }
  } catch (error) {
    yield put({ type: ORDERS_PLACE_QUOTE_FAILURE, error });
    yield put(
      SagaHelper.handleError(error, action, false, i18n.t('pack:cart-add-fail', { name: action.purchase.name })),
    );
  }
}

function* placeQuoteMonthly() {
  try {
    const response = yield call(userService.placeQuoteMonthly);
    yield put({ type: ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS, response });
  } catch (error) {
    yield put({ type: ORDERS_PLACE_QUOTE_MONTHLY_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* placeOrder(action) {
  try {
    const response = yield call(userService.placeOrder, action.quote);
    yield put({ type: ORDERS_PLACE_ORDER_SUCCESS, response });
    if (action.isPlayer) {
      document.location.reload();
      return;
    }
    if (action.artworkId) {
      yield put(getArtworkByIdAction(action.artworkId));
    } else {
      yield put(getAllArtworksAction());
    }
    // console.log(action.quote);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < action.quote.packs.length; i++) {
      yield put(getPackFilesAction(action.quote.packs[i]));
    }
    yield put(getPrivateProfileAction());
  } catch (error) {
    yield put({ type: ORDERS_PLACE_ORDER_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getIncomes(action) {
  try {
    const incomes = yield call(userService.getIncomes, action.page || 1);
    let response = {
      incomes,
      page: action.page || 1,
    };
    yield put({ type: GET_USER_INCOMES_SUCCESS, response });

    response = yield call(userService.getSubscriptionBillings);
    yield put({ type: GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_INCOMES_FAILURE, error });
    yield put(SagaHelper.handleError(error, action));
  }
}

function* getBillings() {
  try {
    const response = yield call(userService.getBillings);
    yield put({ type: GET_USER_BILLINGS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_BILLINGS_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* getSubscriptionBillings() {
  try {
    const response = yield call(userService.getSubscriptionBillings);
    yield put({ type: GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS, response });
  } catch (error) {
    yield put({ type: GET_USER_SUBSCRIPTION_BILLINGS_FAILURE, error });
    yield put(SagaHelper.handleError(error));
  }
}

function* deleteAccount() {
  try {
    const response = yield call(userService.deleteAccount, acl.getUserId());
    yield put({ type: DELETE_USER_ACCOUNT_SUCCESS, response });
    yield put(showNotification({ level: 'info', message: 'Account deleted' }));
    yield put({ type: LOGOUT });
  } catch (error) {
    yield put({ type: DELETE_USER_ACCOUNT_FAILURE, error });
    yield put(SagaHelper.handleError(error, null, false, null, true));
  }
}

/* --- WATCHERS --- */
function* watcherLogin() {
  yield takeEvery(LOGIN, login);
}

function* watcherRegister() {
  yield takeEvery(REGISTER_USER, register);
}

function* watcherGetPublicProfile() {
  yield takeEvery(GET_PUBLIC_PROFILE, getPublicProfile);
}

function* watcherGetPrivateProfile() {
  yield takeEvery(GET_PRIVATE_PROFILE, getPrivateProfile);
}

function* watcherMarkUserNotificationsAsRead() {
  yield takeEvery(GET_USER_MARK_NOTIFICATIONS_AS_READ, markNoficationAsRead);
}

function* watcherMarkNewsAsRead() {
  yield takeEvery(GET_USER_MARK_NEWS_AS_READ, markNewsAsRead);
}

function* watcherGetFavoriteArtworks() {
  yield takeEvery(GET_FAVORITE_ARTWORKS, getFavoriteArtworks);
}

function* watcherAarkAllAsRead() {
  yield takeEvery(MARK_ALL_AS_READ, markAllAsRead);
}

function* watcherRemoveLockedFilesAlert() {
  yield takeEvery(REMOVE_LOCKED_FILES_ALERT, removeLockedFilesAlert);
}

function* watcherGetUserArtworks() {
  yield takeEvery(GET_USER_ARTWORKS, getUserArtworks);
}

function* watcherGetPackAccesses() {
  yield takeEvery(GET_ACCESSED_PACKS, getPackAccesses);
}

function* watcherAddFollowedArtwork() {
  yield takeEvery(ADD_FOLLOWED_ARTWORK, addFollowedArtwork);
}

function* watcherDeleteFollowedArtwork() {
  yield takeEvery(DELETE_FOLLOWED_ARTWORK, deleteFollowedArtwork);
}

function* watcherSubscribeArtwork() {
  yield takeEvery(SUBSCRIBE_ARTWORK, subscribeArtwork);
}

function* watcherUnsubscribeArtwork() {
  yield takeEvery(UNSUBSCRIBE_ARTWORK, unsubscribeArtwork);
}

function* watcherAcceptArtwork() {
  yield takeEvery(ACCEPT_ARTWORK, acceptArtwork);
}

function* watcherAcceptTutorial() {
  yield takeEvery(ACCEPT_TUTORIAL, acceptTutorial);
}

function* watcherBuyPack() {
  yield takeEvery(BUY_PACK, buyPack);
}

function* watcherPlaceQuote() {
  yield takeEvery(ORDERS_PLACE_QUOTE, placeQuote);
}

function* watcherPlaceQuoteMonthly() {
  yield takeEvery(ORDERS_PLACE_QUOTE_MONTHLY, placeQuoteMonthly);
}

function* watcherPlaceOrder() {
  yield takeEvery(ORDERS_PLACE_ORDER, placeOrder);
}

function* watcherUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUser);
}

function* watcherAddUserCreditCard() {
  yield takeEvery(ADD_USER_CREDITCARD, addUserCreditCard);
}

function* watcherSetupUserPaymentMethod() {
  yield takeEvery(SETUP_USER_PAYMENT_METHOD, setupUserPaymentMethod);
}

function* watcherRemoveUserCreditCard() {
  yield takeEvery(REMOVE_USER_CREDITCARD, removeUserCreditCard);
}

function* watcherGetIncomes() {
  yield takeEvery(GET_USER_INCOMES, getIncomes);
}

function* watcherGetBillings() {
  yield takeEvery(GET_USER_BILLINGS, getBillings);
}

function* watcherGetSubscriptionBillings() {
  yield takeEvery(GET_USER_SUBSCRIPTION_BILLINGS, getSubscriptionBillings);
}

function* watcherLogout() {
  yield takeEvery(LOGOUT, logout);
}

function* watcherDeleteAccount() {
  yield takeEvery(DELETE_USER_ACCOUNT, deleteAccount);
}

export default [
  watcherLogin,
  watcherRegister,
  watcherLogout,
  watcherGetPublicProfile,
  watcherGetPrivateProfile,
  watcherMarkUserNotificationsAsRead,
  watcherMarkNewsAsRead,
  watcherGetUserArtworks,
  watcherGetFavoriteArtworks,
  watcherAarkAllAsRead,
  watcherRemoveLockedFilesAlert,
  watcherGetPackAccesses,
  watcherAddFollowedArtwork,
  watcherDeleteFollowedArtwork,
  watcherSubscribeArtwork,
  watcherUnsubscribeArtwork,
  watcherAcceptArtwork,
  watcherAcceptTutorial,
  watcherUpdateUser,
  watcherSetupUserPaymentMethod,
  watcherAddUserCreditCard,
  watcherRemoveUserCreditCard,
  watcherBuyPack,
  watcherPlaceQuote,
  watcherPlaceQuoteMonthly,
  watcherPlaceOrder,
  watcherGetIncomes,
  watcherGetBillings,
  watcherGetSubscriptionBillings,
  watcherDeleteAccount,
];
