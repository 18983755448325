import { GET_FILE_FEEDBACKS, GET_FILE_FEEDBACKS_SUCCESS, GET_FILE_FEEDBACKS_FAILURE } from 'constants/ActionTypes';

const INITIAL_STATE = {
  feedbacks: {},
  isFetching: {
    getFileFeedbacks: false,
  },
  error: {
    getFileFeedbacks: false,
  },
};

export default function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_FILE_FEEDBACKS: {
      return {
        ...state,
        isFetching: { ...state.isFetching, fileFeedbacks: true },
        error: { ...state.error, fileFeedbacks: false },
      };
    }
    case GET_FILE_FEEDBACKS_FAILURE: {
      return {
        ...state,
        isFetching: { ...state.isFetching, fileFeedbacks: false },
        error: { ...state.error, fileFeedbacks: true },
      };
    }
    case GET_FILE_FEEDBACKS_SUCCESS: {
      return {
        ...state,
        feedbacks: action.response,
        isFetching: { ...state.isFetching, fileFeedbacks: false },
        error: { ...state.error, fileFeedbacks: false },
      };
    }

    // Default
    default:
      return state;
  }
}
