import {
  UPSERT_ARTWORK,
  GET_ARTWORK,
  GET_ARTWORK_FILE,
  GET_ARTWORK_FILES,
  GET_ARTWORKS,
  GET_ALL_ARTWORKS,
  DELETE_ARTWORK,
  UPDATE_FILE_ARTWORK,
  REORDER_FILE_ARTWORK,
  MARK_AS_READ_FILE_ARTWORK,
  DELETE_FILE_ARTWORK,
  MARK_AS_READ_FLOW,
  GET_SUGGESTIONS,
  MARK_NOT_INTERESTED,
} from '../constants/ActionTypes';

export const MODE_FULLPAGE = 'fullpage';
export const MODE_ALLFILES = 'allfiles';

export function saveArtworkForm(formData, formikBag) {
  return {
    type: UPSERT_ARTWORK,
    formData,
    formikBag,
  };
}

export function getArtworkById(id, forceUpdate, mode) {
  return {
    type: GET_ARTWORK,
    id,
    forceUpdate,
    mode,
  };
}

export function getArtworkFile(id) {
  return {
    type: GET_ARTWORK_FILE,
    id,
  };
}

export function getArtworkFiles(id, page) {
  return {
    type: GET_ARTWORK_FILES,
    id,
    page,
  };
}

export function getArtworks(artworks) {
  return {
    type: GET_ARTWORKS,
    artworks,
  };
}

export function getAllArtworks(search, page, orderBy, languages, categories, exclusions) {
  return {
    type: GET_ALL_ARTWORKS,
    search,
    page,
    orderBy,
    languages,
    categories,
    exclusions,
  };
}

export function deleteArtwork(id, username) {
  return {
    type: DELETE_ARTWORK,
    id,
    username,
  };
}

export function updateFileArtwork(file, artworkId) {
  return {
    type: UPDATE_FILE_ARTWORK,
    file,
    artworkId,
  };
}

export function reorderFileArtwork(id, order, artworkId) {
  return {
    type: REORDER_FILE_ARTWORK,
    id,
    order,
    artworkId,
  };
}

export function markAsReadFileArtwork(file, artworkId, hasAffinity, user) {
  return {
    type: MARK_AS_READ_FILE_ARTWORK,
    file,
    artworkId,
    hasAffinity,
    user,
  };
}

export function deleteFileArtwork(id, artworkId) {
  return {
    type: DELETE_FILE_ARTWORK,
    id,
    artworkId,
  };
}

export function markAsReadFlow(id) {
  return {
    type: MARK_AS_READ_FLOW,
    id,
  };
}

export function getSuggestions(id) {
  return {
    type: GET_SUGGESTIONS,
    id,
  };
}

export function markNotInterested(id) {
  return {
    type: MARK_NOT_INTERESTED,
    id,
  };
}
