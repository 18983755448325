import api from './api';

const artwork = {
  getUserNews: page => api.get(`user_news.jsonld?page=${page}`),

  getArtworkNews: (id, page) => api.get(`artworks/${id}/news.jsonld?page=${page}`),

  linkArtworkNewsAndFiles: params => api.post('artwork_news_files', params),

  getById: id => api.get(`artwork_news/${id}`),

  post: params => api.post('artwork_news', params),

  put: params => api.put(`artwork_news/${params.id}`, params),

  delete: id => api.delete(`artwork_news/${id}`),

  deleteFile: id => api.delete(`files/${id}`),

  addFile: payload => api.post('files', payload),
};

export default artwork;
