import {
  GET_PACK,
  GET_PACK_FILE,
  GET_PACK_FILES,
  UPDATE_FILE_PACK,
  REORDER_FILE_PACK,
  DELETE_FILE_PACK,
  UPSERT_PACK,
  DELETE_PACK,
  MARK_AS_READ_FILE_PACK,
  MARK_AS_READ_PACK,
} from '../constants/ActionTypes';

export function getPackById(id, forceUpdate, mode) {
  return {
    type: GET_PACK,
    id,
    forceUpdate,
    mode,
  };
}

export function getPackFile(id) {
  return {
    type: GET_PACK_FILE,
    id,
  };
}

export function getPackFiles(id, page, itemsPerPage) {
  return {
    type: GET_PACK_FILES,
    id,
    page,
    itemsPerPage,
  };
}

export function updateFilePack(file, packId) {
  return {
    type: UPDATE_FILE_PACK,
    file,
    packId,
  };
}

export function reorderFilePack(id, order, packId) {
  return {
    type: REORDER_FILE_PACK,
    id,
    order,
    packId,
  };
}

export function deleteFilePack(id, packId) {
  return {
    type: DELETE_FILE_PACK,
    id,
    packId,
  };
}

export function savePackForm(formData) {
  return {
    type: UPSERT_PACK,
    formData,
  };
}

export function deletePack(id, username) {
  return {
    type: DELETE_PACK,
    id,
    username,
  };
}

export function markAsReadFilePack(file, packId, artworkId, hasAffinity, user) {
  return {
    type: MARK_AS_READ_FILE_PACK,
    file,
    packId,
    artworkId,
    hasAffinity,
    user,
  };
}

export function markAsReadPack(id, artworkId) {
  return {
    type: MARK_AS_READ_PACK,
    id,
    artworkId,
  };
}
