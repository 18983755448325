import api from './api';

const user = {
  socialLinks: [
    { name: 'twitter', key: 'externalIdTwitter', url: 'https://twitter.com/' },
    {
      name: 'instagram',
      key: 'externalIdInstagram',
      url: 'https://www.instagram.com/',
    },
    {
      name: 'facebook',
      key: 'externalIdFacebook',
      url: 'https://www.facebook.com/',
    },
    { name: 'tumblr', key: 'externalIdTumblr', url: '' },
    { name: 'discord', key: 'externalIdDiscord', url: '' },
    { name: 'youtube', key: 'externalIdYoutube', url: '' },
    { name: 'linkedin', key: 'externalIdLinkedin', url: '' },
    { name: 'society6', key: 'externalIdSociety6', url: '' },
    { name: 'website', key: 'externalIdWebsite', url: '' },
    { name: 'website2', key: 'externalIdWebsite2', url: '' },
  ],
  register: params => api.post('register', params),

  login: params => api.post('login', params),

  requestPasswordReset: email => api.get(`request_password_reset?email=${email}`),

  passwordReset: (token, params) => api.post(`reset/${token}`, params),

  allProfilePublic: () => api.get(`users`),

  profilePublic: id => api.get(`users/${id}`),

  profilePrivate: () => api.get('users/me/private'),

  markNoficationsAsRead: () => api.get('users/me/notifications/mark_as_read'),

  markNewsAsRead: () => api.get('users/me/news/mark_as_read'),

  markAllArtworkAsRead: () => api.get('/artworks/mark_all_as_read'),

  getFavoriteArtworks: page => api.get(`/artworks.jsonld?favorite=true&limit=500&page=${page || 1}`),

  getPackAccesses: id => api.get(`users/${id}/accessed_packs`),

  getArtworks: id => api.get(`users/${id}/artworks`),

  addFollowedArtwork: params => api.post(`artwork_follow/${params.artworkId}`, {}),

  deleteFollowedArtwork: params => api.post(`artwork_unfollow/${params.artworkId}`, {}),

  subscribeArtwork: params => api.post(`artwork_subscribe/${params.artworkId}`, {}),

  unsubscribeArtwork: params => api.post(`artwork_unsubscribe/${params.artworkId}`, {}),

  updateUser: params => api.put('users/me', params),

  updatePayment: params => api.get('users/me/payment_method_check', params),

  paymentMethodSetup: params => api.get('users/me/payment_method_setup', params),

  buyPack: id => api.post(`packs/${id}/purchase`),

  placeOrder: params => api.post('orders/place', params),

  placeQuote: params => api.post('orders/quote', params),

  placeQuoteMonthly: () => api.get('orders/quoteMonthly'),

  getBillings: () => api.get('orders'),

  getIncomes: page => api.get(`pack_accesses.jsonld?page=${page}`),

  getSubscriptionBillings: () => api.get('subscription_billings'),

  deleteAccount: id => api.post(`users/${id}/unregister`, {}),
};

export default user;
