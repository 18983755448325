/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Link } from 'react-router-dom';

import cartUtils from 'tools/cart';

import './GlobalNotification.scss';

type Props = {
  userCart: Object,
  type: string,
  debug: Boolean,
  // onClose: Function,
};

const GlobalNotification = ({ userCart, type, debug }: Props) => {
  const isPwa = type !== 'subscriptions';
  const cartSize = userCart ? cartUtils.getCartSize(userCart) : 0;
  return (
    <div
      className={`GlobalNotification${cartSize > 0 ? ' with-cart' : ''} ${type}`}
      id={`${type}-banner`}
      style={{ display: isPwa && !debug ? 'none' : 'initial' }}
    >
      {type === 'subscriptions' && (
        <>
          <div className="GlobalNotification-message">
            Your subscriptions are paused. Check your <Link to="/profile/payment#nextmonth">payment method</Link>.
          </div>
          {/* <div className="GlobalNotification-dismiss" onClick={onClose} role="presentation">
            <CommunSVGs.Close width="24" height="24" />
          </div> */}
        </>
      )}
      {type === 'pwa-offline' && <div className="GlobalNotification-message">Content is cached for offline use</div>}
      {type === 'pwa-update' && (
        <div className="GlobalNotification-message">
          A new version is available.
          <br />
          Close all your Flowfo tabs, and{' '}
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              window.location.reload(true);
            }}
          >
            refresh here
          </a>
        </div>
      )}
    </div>
  );
};

export default React.memo(GlobalNotification);
