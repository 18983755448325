import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';

import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';

import { login } from 'actions/UserActions';

import Input from 'components/Form/Input';
import { LoadingButton } from 'components/Loading';

import { MODE_FORGOT } from './ForgotForm';
import schema from './Login.schema';
import { MODE_REGISTER } from './RegisterForm';

export const MODE_LOGIN = 'login';
interface IProps {
  shouldRedirect: boolean;
  onChangeMode(): void;
  onSuccess(): void;
}

export default function LoginForm({ shouldRedirect, onChangeMode, onSuccess }: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation('login');

  const loginStatus = useSelector(state => state.user?.status?.login);
  const userProfile = useSelector(state => state.user?.privateProfile);
  const isLoading = loginStatus === 'fetching' || (loginStatus === 'success' && !acl.isConnected(userProfile));

  const initialValues = {
    email: '',
    password: '',
  };

  useEffect(() => {
    if (onSuccess && loginStatus === 'success' && acl.isConnected(userProfile)) {
      onSuccess();
    }
  }, [loginStatus, userProfile, onSuccess]);

  const handleSubmit = (values, formikBag) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Log in',
      },
      dataLayerName: 'PageDataLayer',
    });
    dispatch(login(values, formikBag, false, shouldRedirect));
  };

  return (
    <div className="Login-form">
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <Field name="email" type="email" label={t('input-email')} component={Input} t={t} />
            <Field name="password" type="password" label={t('input-password')} component={Input} t={t}>
              <span className="float-right text-neutral fs-09">
                <Link
                  role="link"
                  to="/forgot"
                  onClick={e => {
                    e.preventDefault();
                    onChangeMode(MODE_FORGOT);
                  }}
                >
                  {t('forgot-link')}
                </Link>
              </span>
              <div className="clearfix" />
            </Field>
            <LoadingButton
              className="d-flex btn-primary btn-pill mt-4 mx-auto px-10"
              variant="flowfont-regular"
              label={t('login-button')}
              isFetching={isLoading}
            />
          </Form>
        )}
      </Formik>

      <div className="mt-5 text-center">
        <p className="mb-1">{t('signup-title')}</p>
        <div className="flowfont-medium">
          <Link
            to="/register"
            onClick={e => {
              e.preventDefault();
              onChangeMode(MODE_REGISTER);
            }}
          >
            {t('signup-link')}
          </Link>
        </div>
      </div>
    </div>
  );
}
