import axios from 'axios';

import acl from 'tools/acl';

import { showNotification } from 'actions/NotificationsActions';
import { logout } from 'actions/UserActions';

const urlApi = process.env.RAZZLE_URL_API;

let initialized = false;

const http = axios.create({
  baseURL: urlApi,
});

export function isExpiredError(error) {
  return (
    error?.status === 401 &&
    (error?.message === 'Expired JWT Token' ||
      error?.data?.message === 'Expired JWT Token' ||
      error?.message === 'JWT Token not found' ||
      error?.data?.message === 'JWT Token not found' ||
      (error?.data?.message || '').includes('Unable to load an user with property'))
  );
}

export const setupInterceptors = (store, history) => {
  if (initialized) return;
  http.interceptors.request.use(request => {
    const token = acl.getToken();
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });
  http.interceptors.response.use(
    response => response.data,
    error => {
      if (isExpiredError(error?.response)) {
        store.dispatch(logout());
        store.dispatch(
          showNotification({
            level: 'warning',
            title: 'Session expired',
            message: 'Please log in',
          }),
        );
        history.push('/login');
      }
      return Promise.reject(error.response);
    },
  );
  initialized = true;
};

export default http;
