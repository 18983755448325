import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import createRootReducer from 'reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
// defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';

import { HISTORY_LOCATION, GET_PRIVATE_PROFILE } from '../constants/ActionTypes';

// change version ton reset old state
const REDUX_VERSION = 4;

const migrations = {
  [REDUX_VERSION]: () => ({}),
};

const isServerSide = typeof document === 'undefined';

let preloadedState;
if (!isServerSide) {
  // Grab the state from a global variable injected into the server-generated HTML
  // eslint-disable-next-line no-underscore-dangle
  preloadedState = window.__PRELOADED_STATE__;
  // Allow the passed state to be garbage-collected
  // eslint-disable-next-line no-underscore-dangle
  delete window.__PRELOADED_STATE__;

  if (process.env.RAZZLE_GTM_TRACKING_ID) {
    TagManager.initialize({
      gtmId: process.env.RAZZLE_GTM_TRACKING_ID,
      auth: process.env.RAZZLE_GTM_AUTH || undefined,
      preview: process.env.RAZZLE_GTM_PREVIEW_ENV || undefined,
      dataLayerName: 'PageDataLayer',
    });
  }
}

export function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage,
    version: REDUX_VERSION,
    migrate: createMigrate(migrations),
    whitelist: ['user'],
    transforms: [
      createBlacklistFilter('user', ['isFetching', 'publicProfile', 'publicArtworks']),
      createBlacklistFilter('player'),
    ],
  };

  const history = isServerSide ? createMemoryHistory() : createBrowserHistory();

  // root reducer with router state
  const rootReducer = isServerSide
    ? createRootReducer(history)
    : persistReducer(persistConfig, createRootReducer(history));

  const sagaMiddleware = createSagaMiddleware();

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers =
    isServerSide || process.env.NODE_ENV !== 'development'
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware,
      ),
    ),
  );

  let lastLocation = null;
  const hitPage = location => {
    const userId = acl.getUserId();
    // ReactGA.set({ userId, page: location.pathname, dimension1: process.env.RAZZLE_VERSION_ID });
    // ReactGA.pageview(location.pathname + location.search);
    TagManager.dataLayer({
      dataLayer: {
        userId,
        page: location.pathname + location.search,
      },
      dataLayerName: 'PageDataLayer',
    });
    store.dispatch({ type: HISTORY_LOCATION, location });
    if (lastLocation !== location.pathname) {
      lastLocation = location.pathname;
      if (userId) {
        store.dispatch({ type: GET_PRIVATE_PROFILE });
      }
    }
    if (!isServerSide) window.scroll(0, 0);
  };
  history.listen(hitPage);

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.runSagaTask();

  const exportData = { store, history, hitPage };
  if (!isServerSide) {
    exportData.persistor = persistStore(store);
  }

  return exportData;
}

export const { persistor, store, history, hitPage } = configureStore(preloadedState);
