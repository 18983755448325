import * as Sentry from '@sentry/react';

import React from 'react';

import { withTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import CommunSVGs from 'svgs/commun';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.log(error, errorInfo);
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    const { t, children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="text-center pt-5">
          <img src="/assets/images/error_500.svg" alt="Something goes wrong..." />
          <br />
          <Button
            variant="link"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('get-stuck')} {t('refresh')}
          </Button>
          <br />
          <Button variant="link" onClick={() => window.history.back()}>
            <CommunSVGs.ArrowLeft width="16px" height="16px" fill="#EDEDED" /> Go back
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default withTranslation('global')(ErrorBoundary);
