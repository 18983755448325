import classNames from 'classnames';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { toggleSecondaryNav } from 'actions/AnimationActions';
import { logout } from 'actions/UserActions';

import Avatar from 'components/Avatar';
import LoginModal from 'components/Login/LoginModal';

import CommunSVGs from 'svgs/commun';

import Footer from '../Footer';
import './SecondaryNav.scss';

export default function SecondaryNav() {
  const history = useHistory();
  const { t } = useTranslation('secondarynav');

  const isOpen = useSelector(state => state.animation.isSecondaryNavOpen);
  const userProfile = useSelector(state => state.user.privateProfile);
  const userArtworks = useSelector(state => state.user.artworks);
  const [loginModalMode, setLoginModalMode] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
    dispatch(toggleSecondaryNav());
  };

  const showLoginModal = mode => e => {
    e.preventDefault();
    setLoginModalMode(mode);
    dispatch(toggleSecondaryNav());
  };

  const handleToggle = () => {
    dispatch(toggleSecondaryNav());
  };

  return (
    <>
      <div
        className={classNames(['SecondaryNav-backdrop', { open: isOpen }])}
        onClick={handleToggle}
        role="presentation"
      />
      <div className={classNames(['SecondaryNav d-block d-md-none', { open: isOpen }])}>
        <div className="SecondaryNav-close" onClick={handleToggle} role="presentation">
          <CommunSVGs.Close width="24" height="24" fill="var(--neutral)" />
        </div>
        <Avatar
          src={artworkUtils.getAvatarUrl(userProfile?.avatarFile, 45)}
          alt={userProfile?.username}
          className="SecondaryNav-avatar"
        />

        {acl.isConnected(userProfile) && (
          <>
            <div className="SecondaryNav-username">
              <Link
                to={`/profile/${userProfile.username}#artworks`}
                className="SecondaryNav-link flowfont-title"
                onClick={handleToggle}
              >
                {userProfile.username}
              </Link>
            </div>
            <ul className="SecondaryNav-list text-neutral text-center mt-3 flowfont-regular">
              <li className="SecondaryNav-item">
                <Link to="/profile/settings" className="SecondaryNav-link" onClick={handleToggle}>
                  {t('Settings')}
                </Link>
              </li>
              <li className="SecondaryNav-item mb-3">
                <Link to="/profile/payment" className="SecondaryNav-link" onClick={handleToggle}>
                  {t('Payment')}
                </Link>
              </li>
              {userProfile?.artworksCount > 0 && userArtworks && (
                <li className="SecondaryNav-item">
                  <Link to="/news/add" className="SecondaryNav-link" onClick={handleToggle}>
                    <CommunSVGs.News width="24px" height="24px" fill="var(--neutral)" className="mr-2" />
                    {t('Write a news')}
                  </Link>
                </li>
              )}
              <li className="SecondaryNav-item SecondaryNav-createArtwork">
                <Link to="/artwork/add" className="SecondaryNav-link">
                  {/* <div className="SecondaryNav-link" onClick={handleCreateArtwork} role="presentation"> */}
                  <CommunSVGs.AddPage width="24px" height="24px" fill="var(--primary)" className="mr-2" />
                  {t('Create an Artwork')}
                  {/* </div> */}
                </Link>
              </li>
              <li className="SecondaryNav-item mt-4">
                <Link to="/login" className="SecondaryNav-link text-neutral" onClick={handleLogout}>
                  <CommunSVGs.Logout width="24px" height="24px" fill="var(--neutral)" className="mr-2" />
                  {t('Log out')}
                </Link>
              </li>
            </ul>
          </>
        )}
        {!acl.isConnected(userProfile) && (
          <ul className="SecondaryNav-list text-neutral flowfont-regular">
            <div className="SecondaryNav-notconnected mt-3">
              <Link to="/login" onClick={showLoginModal('login')} className="SecondaryNav-link">
                <CommunSVGs.Login width="24px" height="24px" fill="var(--neutral)" className="mr-2" />
                {t('Log in')}
              </Link>
            </div>
          </ul>
        )}

        {!acl.isConnected(userProfile) && (
          <ul className="SecondaryNav-list text-neutral flowfont-regular">
            <div className="SecondaryNav-notconnected mt-3">
              <Link to="/register" onClick={showLoginModal('login')} className="SecondaryNav-link">
                {/* <CommunSVGs.Login width="24px" height="24px" fill="var(--primary)" className="mr-2" /> */}
                {t('New user : sign up')}
              </Link>
            </div>
          </ul>
        )}

        <Footer />
      </div>
      {loginModalMode && <LoginModal mode={loginModalMode} onClose={() => setLoginModalMode(false)} />}
    </>
  );
}
