import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const acl = {
  isAuthor: (user, data) => {
    if (!user || !user.id || !data || !data.author) return false;
    if (data.author.id) {
      return user.id === data.author.id;
    }
    return `/users/${user.id}` === data.author;
  },
  isConnected: user => acl.getToken() && !_isEmpty(user) && user.id,
  getToken: () => (typeof document === 'undefined' ? undefined : localStorage.getItem('user_token')),
  getUserId: () => {
    if (typeof document === 'undefined') {
      return undefined;
    }
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      return _get(user, 'id');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return undefined;
  },
};

export default acl;
