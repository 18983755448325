import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/fr';

import { initReactI18next } from 'react-i18next';

import translationEN from './global/en.json';
// const languageDetector = new LanguageDetector();
// languageDetector.addDetector(myDetector);
import translationFR from './global/fr.json';

export const DEFAULT_LANG = 'en-US';

export const ALL_LANG = ['fr-FR', 'en-US'];

export const LANGUAGE_CODES = {
  French: 'fr-FR',
  English: 'en-US',
};

export const LANG_PATH = {
  'fr-FR': 'fr',
  'en-US': 'en',
};

export const LANG_REGEX = '(en|fr)';

export function getLinkPrefix(i18nInstance) {
  if (i18nInstance.language !== DEFAULT_LANG) {
    return `/${LANG_PATH[i18nInstance.language]}`;
  }
  return '';
}

export function getLangFromPath(path) {
  let langComputed = DEFAULT_LANG;
  Object.keys(LANG_PATH).forEach(lang => {
    if (LANG_PATH[lang] === path) {
      langComputed = lang;
    }
  });
  return langComputed;
}

export function getLanguageOption(lang, t) {
  if (!lang) return null;
  return { value: lang.id, label: t ? t(`global:${lang.name}`) : lang.name };
}

export function getAvailableLanguagesOptions(languages, t) {
  return (
    languages
      ?.filter(lang => Object.keys(LANGUAGE_CODES).includes(lang.name))
      ?.map(lang => getLanguageOption(lang, t)) || []
  );
}

export function getDisplayLangFromName(code) {
  return LANGUAGE_CODES[code] || DEFAULT_LANG;
}

export function getPreferedLanguage() {
  if (typeof document === 'undefined') {
    return undefined;
  }
  return localStorage.getItem('prefered_language');
}

export function setPreferedLanguage(lang) {
  if (typeof document !== 'undefined') {
    localStorage.setItem('prefered_language', lang);
  }
}

const transFiles = {
  fr: require.context('../', true, /\**\/fr\.json$/),
  en: require.context('../', true, /\**\/en\.json$/),
};

const loadTranslations = (lang = 'fr') => {
  const req = transFiles[lang];
  const regex = /.*\/(.*)\//;

  return req.keys().reduce(
    (acc, filename) => ({
      ...acc,
      [regex.exec(filename)[1].toLowerCase()]: req(filename),
    }),
    {},
  );
};

const resources = {
  fr: { ...translationFR, ...loadTranslations('fr') },
  en: { ...translationEN, ...loadTranslations('en') },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', lng => {
  moment.locale(lng);
});

export default i18n;
