import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import LoadingSmall from '../LoadingSmall/LoadingSmall';
import './LoadingButton.scss';

type Props = {
  label: String,
  loadingLabel: String,
  isFetching: Boolean,
  noMobileLabel: Boolean,
  type: String,
  className: String,
  variant: String,
  buttonRef: Function,
  onClick: Function,
};

const LoadingButton = ({
  label,
  id,
  loadingLabel,
  type,
  variant,
  isFetching,
  className,
  buttonRef,
  noMobileLabel,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      id={id}
      variant={variant || 'primary'}
      type={type || 'submit'}
      className={className || ''}
      ref={buttonRef}
      onClick={onClick}
    >
      {isFetching ? (
        <span className="LoadingButton-loading">
          <span className={`LoadingButton-label${noMobileLabel ? ' no-mobile' : ''}`}>
            {loadingLabel || `${t('global:loading')}...`}
          </span>{' '}
          <LoadingSmall />
        </span>
      ) : (
        <span className="LoadingButton-label">{label}</span>
      )}
    </Button>
  );
};

export default React.memo(LoadingButton);
