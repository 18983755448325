import moment from 'moment';

import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import _replace from 'lodash/replace';

import { LANGUAGE_CODES } from '../i18n/i18n';
import acl from './acl';

export const UNLOCKED_PARAM = 'X-Amz-Expires';

const artworkUtils = {
  isDisclaimerNeeded: (userProfile, artwork, acceptedArtworks, accepted) => {
    if (!artwork) return false;
    const isUserArtwork = acl.isAuthor(userProfile, artwork);
    const isAccepted = _includes(acceptedArtworks, artwork.id) || accepted;
    return !!artwork.isAdult && !_get(userProfile, 'isAdult') && !isUserArtwork && !isAccepted;
  },
  hasAffinity: (favoriteArtworks, id) => {
    const favoriteArtwork = _find(favoriteArtworks, a => a.id === id);
    return (
      _get(favoriteArtwork, 'artworkAccess.isFollower') ||
      _get(favoriteArtwork, 'artworkAccess.isSubscriber') ||
      _get(favoriteArtwork, 'artworkAccess.isUnsubscribing')
    );
  },
  getEntityId: entity => {
    const regex = /[a-z/]+\//;
    return _get(entity, 'id') || parseInt(_replace(entity, regex, ''), 10) || null;
  },
  formatCount: count => {
    if (count >= 100000) {
      return `${Math.round(count / 1000)}K`;
    }
    if (count >= 1000) {
      return `${Math.round(count / 100) / 10}K`;
    }
    return count;
  },
  computePlayerParams: (artwork, visitorArtworkAccesses, visitorPackAccesses) => {
    if (!artwork) return null;
    if (!_isNil(artwork.lastFileRead)) {
      return {
        type: _includes(artwork.lastEntityRead, 'artwork') ? 'flow' : 'pack',
        id: artworkUtils.getEntityId(artwork.lastEntityRead),
        fileId: _get(artwork, 'lastFileRead.id'),
        artworkId: artwork.id,
      };
    }

    // Get visitor information from 'visitor' state
    const visitorArtworkAccess = _find(visitorArtworkAccesses, a => a.id === artwork.id);
    const visitorPackAccess = _first(
      _orderBy(
        _filter(visitorPackAccesses, p => p.artworkId === artwork.id),
        ['readingDate'],
        ['desc'],
      ),
    );
    if (visitorArtworkAccess || visitorPackAccess) {
      let visitorAccessFlow = null;
      if (visitorArtworkAccess && !visitorPackAccess) {
        visitorAccessFlow = visitorArtworkAccess;
      } else if (visitorPackAccess && !visitorArtworkAccess) {
        visitorAccessFlow = visitorPackAccess;
      } else {
        visitorAccessFlow =
          moment(visitorPackAccess.readingDate).diff(visitorArtworkAccess.readingDate) > 0
            ? visitorPackAccess
            : visitorArtworkAccess;
      }
      return {
        type: !visitorAccessFlow.artworkId ? 'flow' : 'pack',
        id: visitorAccessFlow.id,
        fileId: _get(visitorAccessFlow, 'lastFileRead.id'),
        artworkId: artwork.id,
      };
    }

    // default to older pack or flow if no pack
    if (_get(artwork, 'olderPack.id')) {
      return {
        type: 'pack',
        id: artwork.olderPack.id,
      };
    }
    if (artwork.isFlow && artwork.totalFiles > 0) {
      return {
        type: 'flow',
        id: artwork.id,
      };
    }
    return null;
  },
  getArtworkUrl: artwork => `/artwork/${_get(artwork, 'author.username') || '_'}/${_get(artwork, 'uniqueName')}`,
  getLang: artwork => LANGUAGE_CODES[_get(artwork, 'language.name')],
  getBannerUrl: (artwork, width, height, cache, mode) =>
    artworkUtils.getFileUrl(
      _get(artwork, 'bannerFile'),
      width,
      height,
      'https://flowfo.me/assets/images/default-banner.png',
      cache,
      mode,
    ),
  getCoverUrl: (artwork, width, height, cache, mode) =>
    artworkUtils.getFileUrl(
      _get(artwork, 'coverFile'),
      width,
      height,
      'https://flowfo.me/assets/images/default-cover.png',
      cache,
      mode,
    ),
  getAvatarUrl: (avatar, width, height, cache, mode) =>
    artworkUtils.getFileUrl(avatar, width, height, 'https://flowfo.me/assets/images/user-default.svg', cache, mode),
  getFileUrl: (file, width, height, defaultUrl, cache, mode) => {
    let url = false;
    const contentUrl = _get(file, 'contentUrl') || _get(file, 'publicContentUrl') || defaultUrl;
    const isUnlocked = _includes(contentUrl, UNLOCKED_PARAM);
    if ((!width && !height) || isUnlocked) {
      // no resize available use generated thumbnail image
      url = _get(file, 'thumbnailUrl') || _get(file, 'publicThumbnailUrl');
    }
    return artworkUtils.getCdnUrl(url || contentUrl, width, height, cache, mode);
  },
  isUnlocked: file => (file?.contentUrl || file?.publicContentUrl)?.includes(UNLOCKED_PARAM),
  getCdnUrl: (url, width, height, cache, mode) => {
    const isUnlocked = _includes(url, UNLOCKED_PARAM);
    if (!url || (!width && !height) || isUnlocked) {
      return url; // no resize available/needed
    }
    const maxAge = null;
    let format = artworkUtils.isWebpSupported() ? 'webp' : 'jpg';
    let quality = 85;
    let dpr = 1.5;
    let blur = null;
    if (mode) {
      dpr = 1;
      if (mode === 'LQPI') {
        quality = 25;
        blur = 100;
      } else if (mode === 'jpg') {
        format = 'jpg';
        dpr = 1.5;
      } else {
        quality = mode;
      }
    }
    let crop = '';
    if (width && height) {
      crop = '&fit=cover';
    }
    /*
     * https://images.weserv.nl/docs/
     */
    return `//images.weserv.nl/?url=${encodeURIComponent(url)}&q=${quality}&dpr=${dpr}${
      blur ? `&blur=${blur}` : ''
    }&output=${format}${width ? `&w=${width}` : ''}${height ? `&h=${height}` : ''}${crop}${
      maxAge ? `&maxage=${maxAge}` : ''
    }`;
  },
  isWebpSupported: () => {
    if (typeof document === 'undefined') return true; // SSR
    // eslint-disable-next-line no-undef
    if (typeof Modernizr !== 'undefined' && !!_get(Modernizr, 'webp')) {
      return true;
    }
    return false;
  },
};

export default artworkUtils;
