/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable jsx-a11y/label-has-for */

/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable react/no-danger */
import cx from 'classnames';

import React, { PureComponent } from 'react';

import './Checkbox.scss';

type Props = {
  label: string,
  type: string,
  helperText: string,
  field: Object,
  form: Object,
  center: Boolean,
  t: Object,
};

class Checkbox extends PureComponent<Props> {
  render() {
    const {
      label,
      type,
      helperText,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      center,
      t,
      ...props
    } = this.props;

    return (
      <div className="Checkbox form-check">
        <div className={cx('Checkbox-wrapper', { 'justify-content-center': center })}>
          <input type="checkbox" {...field} {...props} checked={field.value} className="form-check-input" />
          <label
            className="Checkbox-label flowfont-regular text-neutral form-check-label"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </div>
        {helperText && !errors[field.name] && <small className="form-text text-muted">{helperText}</small>}
        {touched[field.name] && errors[field.name] && (
          <div className={cx('Input-error flowfont-medium text-danger', { 'text-center': center })}>
            {t ? t(errors[field.name]) : errors[field.name]}
          </div>
        )}
      </div>
    );
  }
}

export default Checkbox;
