/* --- HISTORY --- */
export const HISTORY_LOCATION = 'HISTORY_LOCATION';

/* --- ANIMATIONS --- */
export const SECONDARY_NAV_OPEN = 'SECONDARY_NAV_OPEN';

/* --- LOGIN --- */
export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

/* --- LOGOUT --- */
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

/* --- REGISTER --- */
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

/* --- GET PUBLIC PROFILE --- */
export const GET_PUBLIC_PROFILE = 'GET_PUBLIC_PROFILE';
export const GET_PUBLIC_PROFILE_SUCCESS = 'GET_PUBLIC_PROFILE_SUCCESS';
export const GET_PUBLIC_PROFILE_FAILURE = 'GET_PUBLIC_PROFILE_FAILURE';

/* --- GET PRIVATE PROFILE --- */
export const GET_PRIVATE_PROFILE = 'GET_PRIVATE_PROFILE';
export const GET_PRIVATE_PROFILE_SUCCESS = 'GET_PRIVATE_PROFILE_SUCCESS';
export const GET_PRIVATE_PROFILE_FAILURE = 'GET_PRIVATE_PROFILE_FAILURE';

/* --- USER USER NOTIFICATIONS --- */
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';

/* --- USER USER NOTIFICATIONS --- */
export const GET_USER_MARK_NOTIFICATIONS_AS_READ = 'GET_USER_MARK_NOTIFICATIONS_AS_READ';
export const GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'GET_USER_MARK_NOTIFICATIONS_AS_READ_SUCCESS';
export const GET_USER_MARK_NOTIFICATIONS_AS_READ_FAILURE = 'GET_USER_MARK_NOTIFICATIONS_AS_READ_FAILURE';

/* --- MARK AS READ NEWS--- */
export const GET_USER_MARK_NEWS_AS_READ = 'GET_USER_MARK_NEWS_AS_READ';
export const GET_USER_MARK_NEWS_AS_READ_SUCCESS = 'GET_USER_MARK_NEWS_AS_READ_SUCCESS';
export const GET_USER_MARK_NEWS_AS_READ_FAILURE = 'GET_USER_MARK_NEWS_AS_READ_FAILURE';

/* --- USER ARTWORKS --- */
export const GET_USER_ARTWORKS = 'GET_USER_ARTWORKS';
export const GET_USER_ARTWORKS_SUCCESS = 'GET_USER_ARTWORKS_SUCCESS';
export const GET_USER_ARTWORKS_FAILURE = 'GET_USER_ARTWORKS_FAILURE';

/* --- USER FOLLOWED ARTWORKS --- */
export const GET_FAVORITE_ARTWORKS = 'GET_FAVORITE_ARTWORKS';
export const GET_FAVORITE_ARTWORKS_SUCCESS = 'GET_FAVORITE_ARTWORKS_SUCCESS';
export const GET_FAVORITE_ARTWORKS_FAILURE = 'GET_FAVORITE_ARTWORKS_FAILURE';

export const MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
export const MARK_ALL_AS_READ_SUCCESS = 'MARK_ALL_AS_READ_SUCCESS';
export const MARK_ALL_AS_READ_FAILURE = 'MARK_ALL_AS_READ_FAILURE';

export const REMOVE_LOCKED_FILES_ALERT = 'REMOVE_LOCKED_FILES_ALERT';
export const REMOVE_LOCKED_FILES_ALERT_SUCCESS = 'REMOVE_LOCKED_FILES_ALERT_SUCCESS';
export const REMOVE_LOCKED_FILES_ALERT_FAILURE = 'REMOVE_LOCKED_FILES_ALERT_FAILURE';

/* --- USER PURCHASED PACKS --- */
export const GET_ACCESSED_PACKS = 'GET_ACCESSED_PACKS';
export const GET_ACCESSED_PACKS_SUCCESS = 'GET_ACCESSED_PACKS_SUCCESS';
export const GET_ACCESSED_PACKS_FAILURE = 'GET_ACCESSED_PACKS_FAILURE';

/* --- USER READING FILE --- */
export const USER_MARK_AS_READ = 'USER_MARK_AS_READ';
export const USER_MARK_AS_READ_SUCCESS = 'USER_MARK_AS_READ_SUCCESS';
export const USER_MARK_AS_READ_FAILURE = 'USER_MARK_AS_READ_FAILURE';

/* --- ADD FOLLOWED ARTWORK --- */
export const ADD_FOLLOWED_ARTWORK = 'ADD_FOLLOWED_ARTWORK';
export const ADD_FOLLOWED_ARTWORK_SUCCESS = 'ADD_FOLLOWED_ARTWORK_SUCCESS';
export const ADD_FOLLOWED_ARTWORK_FAILURE = 'ADD_FOLLOWED_ARTWORK_FAILURE';

/* --- DELETE FOLLOWED ARTWORK --- */
export const DELETE_FOLLOWED_ARTWORK = 'DELETE_FOLLOWED_ARTWORK';
export const DELETE_FOLLOWED_ARTWORK_SUCCESS = 'DELETE_FOLLOWED_ARTWORK_SUCCESS';
export const DELETE_FOLLOWED_ARTWORK_FAILURE = 'DELETE_FOLLOWED_ARTWORK_FAILURE';

/* --- SUBSCRIBE ARTWORK --- */
export const SUBSCRIBE_ARTWORK = 'SUBSCRIBE_ARTWORK';
export const SUBSCRIBE_ARTWORK_SUCCESS = 'SUBSCRIBE_ARTWORK_SUCCESS';
export const SUBSCRIBE_ARTWORK_FAILURE = 'SUBSCRIBE_ARTWORK_FAILURE';

/* --- UNSUBSCRIBE ARTWORK --- */
export const UNSUBSCRIBE_ARTWORK = 'UNSUBSCRIBE_ARTWORK';
export const UNSUBSCRIBE_ARTWORK_SUCCESS = 'UNSUBSCRIBE_ARTWORK_SUCCESS';
export const UNSUBSCRIBE_ARTWORK_FAILURE = 'UNSUBSCRIBE_ARTWORK_FAILURE';

/* --- ACCEPT ARTWORK --- */
export const ACCEPT_ARTWORK = 'ACCEPT_ARTWORK';
export const ACCEPT_ARTWORK_SUCCESS = 'ACCEPT_ARTWORK_SUCCESS';
export const ACCEPT_ARTWORK_FAILURE = 'ACCEPT_ARTWORK_FAILURE';

/* --- ACCEPT ARTWORK --- */
export const ACCEPT_TUTORIAL = 'ACCEPT_TUTORIAL';
export const ACCEPT_TUTORIAL_SUCCESS = 'ACCEPT_TUTORIAL_SUCCESS';
export const ACCEPT_TUTORIAL_FAILURE = 'ACCEPT_TUTORIAL_FAILURE';

/* --- DISMISS GLOBAL NOTIFICATIONS --- */
export const USER_DISMISS_NOTIFICATIONS = 'USER_DISMISS_NOTIFICATIONS';

export const UPDATE_THEME = 'UPDATE_THEME';

/* --- UPDATE USER --- */
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

/* --- SETUP_USER_PAYMENT_METHOD --- */
export const SETUP_USER_PAYMENT_METHOD = 'SETUP_USER_PAYMENT_METHOD';
export const SETUP_USER_PAYMENT_METHOD_SUCCESS = 'SETUP_USER_PAYMENT_METHOD_SUCCESS';
export const SETUP_USER_PAYMENT_METHOD_FAILURE = 'SETUP_USER_PAYMENT_METHOD_FAILURE';

/* --- ADD CREDIT CARD --- */
export const ADD_USER_CREDITCARD = 'ADD_USER_CREDITCARD';
export const ADD_USER_CREDITCARD_SUCCESS = 'ADD_USER_CREDITCARD_SUCCESS';
export const ADD_USER_CREDITCARD_FAILURE = 'ADD_USER_CREDITCARD_FAILURE';

/* --- REMOVE CREDIT CARD --- */
export const REMOVE_USER_CREDITCARD = 'REMOVE_USER_CREDITCARD';
export const REMOVE_USER_CREDITCARD_SUCCESS = 'REMOVE_USER_CREDITCARD_SUCCESS';
export const REMOVE_USER_CREDITCARD_FAILURE = 'REMOVE_USER_CREDITCARD_FAILURE';

/* --- GET ARTWORK --- */
export const GET_ARTWORK = 'GET_ARTWORK';
export const GET_ARTWORK_SUCCESS = 'GET_ARTWORK_SUCCESS';
export const GET_ARTWORK_FAILURE = 'GET_ARTWORK_FAILURE';

/* --- GET ARTWORK FILE --- */
export const GET_ARTWORK_FILE = 'GET_ARTWORK_FILE';
export const GET_ARTWORK_FILE_SUCCESS = 'GET_ARTWORK_FILE_SUCCESS';
export const GET_ARTWORK_FILE_FAILURE = 'GET_ARTWORK_FILE_FAILURE';

/* --- GET ARTWORK FILES --- */
export const GET_ARTWORK_FILES = 'GET_ARTWORK_FILES';
export const GET_ARTWORK_FILES_SUCCESS = 'GET_ARTWORK_FILES_SUCCESS';
export const GET_ARTWORK_FILES_FAILURE = 'GET_ARTWORK_FILES_FAILURE';

/* --- ARTWORKS --- */
export const GET_ARTWORKS = 'GET_ARTWORKS';
export const GET_ARTWORKS_SUCCESS = 'GET_ARTWORKS_SUCCESS';
export const GET_ARTWORKS_FAILURE = 'GET_ARTWORKS_FAILURE';

/* --- GET MARK ARTWORK NEWS AS READ --- */
export const GET_ARTWORK_MARK_NEWS_AS_READ = 'GET_ARTWORK_MARK_NEWS_AS_READ';
export const GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS = 'GET_ARTWORK_MARK_NEWS_AS_READ_SUCCESS';
export const GET_ARTWORK_MARK_NEWS_AS_READ_FAILURE = 'GET_ARTWORK_MARK_NEWS_AS_READ_FAILURE';

/* --- ALL ARTWORKS --- */
export const GET_ALL_ARTWORKS = 'GET_ALL_ARTWORKS';
export const GET_ALL_ARTWORKS_SUCCESS = 'GET_ALL_ARTWORKS_SUCCESS';
export const GET_ALL_ARTWORKS_FAILURE = 'GET_ALL_ARTWORKS_FAILURE';

export const UPDATE_ARTWORK_PACK_SUCCESS = 'UPDATE_ARTWORK_PACK_SUCCESS';

/* --- UPSERT ARTWORK --- */
export const UPSERT_ARTWORK = 'UPSERT_ARTWORK';
export const UPSERT_ARTWORK_SUCCESS = 'UPSERT_ARTWORK_SUCCESS';
export const UPSERT_ARTWORK_FAILURE = 'UPSERT_ARTWORK_FAILURE';

/* --- DELETE ARTWORK --- */
export const DELETE_ARTWORK = 'DELETE_ARTWORK';
export const DELETE_ARTWORK_SUCCESS = 'DELETE_ARTWORK_SUCCESS';
export const DELETE_ARTWORK_FAILURE = 'DELETE_ARTWORK_FAILURE';

/* --- UPDATE FILE ARTWORK --- */
export const UPDATE_FILE_ARTWORK = 'UPDATE_FILE_ARTWORK';
export const UPDATE_FILE_ARTWORK_SUCCESS = 'UPDATE_FILE_ARTWORK_SUCCESS';
export const UPDATE_FILE_ARTWORK_FAILURE = 'UPDATE_FILE_ARTWORK_FAILURE';

/* --- REORDER FILE ARTWORK --- */
export const REORDER_FILE_ARTWORK = 'REORDER_FILE_ARTWORK';
export const REORDER_FILE_ARTWORK_SUCCESS = 'REORDER_FILE_ARTWORK_SUCCESS';
export const REORDER_FILE_ARTWORK_FAILURE = 'REORDER_FILE_ARTWORK_FAILURE';

/* --- MARK AS READ FILE ARTWORK --- */
export const MARK_AS_READ_FILE_ARTWORK = 'MARK_AS_READ_FILE_ARTWORK';
export const MARK_AS_READ_FILE_ARTWORK_SUCCESS = 'MARK_AS_READ_FILE_ARTWORK_SUCCESS';
export const MARK_AS_READ_FILE_ARTWORK_FAILURE = 'MARK_AS_READ_FILE_ARTWORK_FAILURE';

/* --- MARK AS READ FLOW --- */
export const MARK_AS_READ_FLOW = 'MARK_AS_READ_FLOW';
export const MARK_AS_READ_FLOW_SUCCESS = 'MARK_AS_READ_FLOW_SUCCESS';
export const MARK_AS_READ_FLOW_FAILURE = 'MARK_AS_READ_FLOW_FAILURE';

/* --- FLOW SUGGESTIONS --- */
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS';
export const GET_SUGGESTIONS_FAILURE = 'GET_SUGGESTIONS_FAILURE';

/* --- SUGGESTION MARK NOT INTERESTED --- */
export const MARK_NOT_INTERESTED = 'MARK_NOT_INTERESTED';
export const MARK_NOT_INTERESTED_SUCCESS = 'MARK_NOT_INTERESTED_SUCCESS';
export const MARK_NOT_INTERESTED_FAILURE = 'MARK_NOT_INTERESTED_FAILURE';

/* --- DELETE FILE ARTWORK --- */
export const DELETE_FILE_ARTWORK = 'DELETE_FILE_ARTWORK';
export const DELETE_FILE_ARTWORK_SUCCESS = 'DELETE_FILE_ARTWORK_SUCCESS';
export const DELETE_FILE_ARTWORK_FAILURE = 'DELETE_FILE_ARTWORK_FAILURE';

export const RESET_ARTWORK = 'RESET_ARTWORK';

/* --- GET USER NEWS --- */
export const GET_USER_NEWS = 'GET_USER_NEWS';
export const GET_USER_NEWS_SUCCESS = 'GET_USER_NEWS_SUCCESS';
export const GET_USER_NEWS_FAILURE = 'GET_USER_NEWS_FAILURE';

/* --- GET ARTWORK NEWS --- */
export const GET_ARTWORK_NEWS = 'GET_ARTWORK_NEWS';
export const GET_ARTWORK_NEWS_SUCCESS = 'GET_ARTWORK_NEWS_SUCCESS';
export const GET_ARTWORK_NEWS_FAILURE = 'GET_ARTWORK_NEWS_FAILURE';

/* --- GET ARTWORK NEWS SINGLE --- */
export const GET_ARTWORK_NEWS_BY_ID = 'GET_ARTWORK_NEWS_BY_ID';
export const GET_ARTWORK_NEWS_BY_ID_SUCCESS = 'GET_ARTWORK_NEWS_BY_ID_SUCCESS';
export const GET_ARTWORK_NEWS_BY_ID_FAILURE = 'GET_ARTWORK_NEWS_BY_ID_FAILURE';

/* --- UPSERT NEWS --- */
export const UPSERT_NEWS = 'UPSERT_NEWS';
export const UPSERT_NEWS_SUCCESS = 'UPSERT_NEWS_SUCCESS';
export const UPSERT_NEWS_FAILURE = 'UPSERT_NEWS_FAILURE';

/* --- DELETE NEWS --- */
export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE';

/* --- GET PACK --- */
export const GET_PACK = 'GET_PACK';
export const GET_PACK_SUCCESS = 'GET_PACK_SUCCESS';
export const GET_PACK_FAILURE = 'GET_PACK_FAILURE';

/* --- GET PACK FILE --- */
export const GET_PACK_FILE = 'GET_PACK_FILE';
export const GET_PACK_FILE_SUCCESS = 'GET_PACK_FILE_SUCCESS';
export const GET_PACK_FILE_FAILURE = 'GET_PACK_FILE_FAILURE';

/* --- GET PACK FILES --- */
export const GET_PACK_FILES = 'GET_PACK_FILES';
export const GET_PACK_FILES_SUCCESS = 'GET_PACK_FILES_SUCCESS';
export const GET_PACK_FILES_FAILURE = 'GET_PACK_FILES_FAILURE';

export const UPDATE_PACK_SUCCESS = 'UPDATE_PACK_SUCCESS';
export const UPDATE_PACK_FILES_SUCCESS = 'UPDATE_PACK_FILES_SUCCESS';

/* --- UPDATE FILE PACK --- */
export const UPDATE_FILE_PACK = 'UPDATE_FILE_PACK';
export const UPDATE_FILE_PACK_SUCCESS = 'UPDATE_FILE_PACK_SUCCESS';
export const UPDATE_FILE_PACK_FAILURE = 'UPDATE_FILE_PACK_FAILURE';

/* --- REORDER FILE PACK --- */
export const REORDER_FILE_PACK = 'REORDER_FILE_PACK';
export const REORDER_FILE_PACK_SUCCESS = 'REORDER_FILE_PACK_SUCCESS';
export const REORDER_FILE_PACK_FAILURE = 'REORDER_FILE_PACK_FAILURE';

/* --- DELETE FILE PACK --- */
export const DELETE_FILE_PACK = 'DELETE_FILE_PACK';
export const DELETE_FILE_PACK_SUCCESS = 'DELETE_FILE_PACK_SUCCESS';
export const DELETE_FILE_PACK_FAILURE = 'DELETE_FILE_PACK_FAILURE';

/* --- MARK AS READ FILE PACK --- */
export const MARK_AS_READ_FILE_PACK = 'MARK_AS_READ_FILE_PACK';
export const MARK_AS_READ_FILE_PACK_SUCCESS = 'MARK_AS_READ_FILE_PACK_SUCCESS';
export const MARK_AS_READ_FILE_PACK_FAILURE = 'MARK_AS_READ_FILE_PACK_FAILURE';

/* --- MARK AS READ PACK --- */
export const MARK_AS_READ_PACK = 'MARK_AS_READ_PACK';
export const MARK_AS_READ_PACK_SUCCESS = 'MARK_AS_READ_PACK_SUCCESS';
export const MARK_AS_READ_PACK_FAILURE = 'MARK_AS_READ_PACK_FAILURE';

/* --- UPSERT PACK --- */
export const UPSERT_PACK = 'UPSERT_PACK';
export const UPSERT_PACK_SUCCESS = 'UPSERT_PACK_SUCCESS';
export const UPSERT_PACK_FAILURE = 'UPSERT_PACK_FAILURE';

/* --- DELETE PACK --- */
export const DELETE_PACK = 'DELETE_PACK';
export const DELETE_PACK_SUCCESS = 'DELETE_PACK_SUCCESS';
export const DELETE_PACK_FAILURE = 'DELETE_PACK_FAILURE';

export const RESET_PACK = 'RESET_PACK';

/* --- BUY PACK --- */
export const BUY_PACK = 'BUY_PACK';
export const BUY_PACK_SUCCESS = 'BUY_PACK_SUCCESS';
export const BUY_PACK_FAILURE = 'BUY_PACK_FAILURE';

/* --- CART --- */
export const CART_SHOW = 'CART_SHOW';
export const CART_HIDE = 'CART_HIDE';
export const CART_RESET = 'CART_RESET';

/* --- ORDERS --- */
export const ORDERS_PLACE_QUOTE = 'ORDERS_PLACE_QUOTE';
export const ORDERS_PLACE_QUOTE_SUCCESS = 'ORDERS_PLACE_QUOTE_SUCCESS';
export const ORDERS_PLACE_QUOTE_FAILURE = 'ORDERS_PLACE_QUOTE_FAILURE';

export const ORDERS_PLACE_QUOTE_MONTHLY = 'ORDERS_PLACE_QUOTE_MONTHLY';
export const ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS = 'ORDERS_PLACE_QUOTE_MONTHLY_SUCCESS';
export const ORDERS_PLACE_QUOTE_MONTHLY_FAILURE = 'ORDERS_PLACE_QUOTE_MONTHLY_FAILURE';

export const ORDERS_PLACE_ORDER = 'ORDERS_PLACE_ORDER';
export const ORDERS_PLACE_ORDER_SUCCESS = 'ORDERS_PLACE_ORDER_SUCCESS';
export const ORDERS_PLACE_ORDER_FAILURE = 'ORDERS_PLACE_ORDER_FAILURE';

/* USER PAYMENTS */
export const GET_USER_INCOMES = 'GET_USER_INCOMES';
export const GET_USER_INCOMES_SUCCESS = 'GET_USER_INCOMES_SUCCESS';
export const GET_USER_INCOMES_FAILURE = 'GET_USER_INCOMES_FAILURE';

export const GET_USER_BILLINGS = 'GET_USER_BILLINGS';
export const GET_USER_BILLINGS_SUCCESS = 'GET_USER_BILLINGS_SUCCESS';
export const GET_USER_BILLINGS_FAILURE = 'GET_USER_BILLINGS_FAILURE';

export const GET_USER_SUBSCRIPTION_BILLINGS = 'GET_USER_SUBSCRIPTION_BILLINGS';
export const GET_USER_SUBSCRIPTION_BILLINGS_SUCCESS = 'GET_USER__SUBSCRIPTION_BILLINGS_SUCCESS';
export const GET_USER_SUBSCRIPTION_BILLINGS_FAILURE = 'GET_USER__SUBSCRIPTION_BILLINGS_FAILURE';

/* COMMENTS */
export const GET_FILE_COMMENTS = 'GET_FILE_COMMENTS';
export const GET_FILE_COMMENTS_SUCCESS = 'GET_FILE_COMMENTS_SUCCESS';
export const GET_FILE_COMMENTS_FAILURE = 'GET_FILE_COMMENTS_FAILURE';

export const GET_NEWS_COMMENTS = 'GET_NEWS_COMMENTS';
export const GET_NEWS_COMMENTS_SUCCESS = 'GET_NEWS_COMMENTS_SUCCESS';
export const GET_NEWS_COMMENTS_FAILURE = 'GET_NEWS_COMMENTS_FAILURE';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const REMOVE_COMMENT_SUCCESS = 'REMOVE_COMMENT_SUCCESS';
export const REMOVE_COMMENT_FAILURE = 'REMOVE_COMMENT_FAILURE';

export const REPORT_COMMENT = 'REPORT_COMMENT';
export const REPORT_COMMENT_SUCCESS = 'REPORT_COMMENT_SUCCESS';
export const REPORT_COMMENT_FAILURE = 'REPORT_COMMENT_FAILURE';

/* FILE FEEDBACKS */
export const GET_FILE_FEEDBACKS = 'GET_FILE_FEEDBACKS';
export const GET_FILE_FEEDBACKS_SUCCESS = 'GET_FILE_FEEDBACKS_SUCCESS';
export const GET_FILE_FEEDBACKS_FAILURE = 'GET_FILE_FEEDBACKS_FAILURE';

export const ADD_FILE_FEEDBACK = 'ADD_FILE_FEEDBACK';
export const ADD_FILE_FEEDBACK_SUCCESS = 'ADD_FILE_FEEDBACK_SUCCESS';
export const ADD_FILE_FEEDBACK_FAILURE = 'ADD_FILE_FEEDBACK_FAILURE';

/* TAXONOMY */
export const GET_TAXONOMY_CATEGORIES = 'GET_TAXONOMY_CATEGORIES';
export const GET_TAXONOMY_CATEGORIES_SUCCESS = 'GET_TAXONOMY_CATEGORIES_SUCCESS';
export const GET_TAXONOMY_CATEGORIES_FAILURE = 'GET_TAXONOMY_CATEGORIES_FAILURE';

export const GET_TAXONOMY_LANGUAGES = 'GET_TAXONOMY_LANGUAGES';
export const GET_TAXONOMY_LANGUAGES_SUCCESS = 'GET_TAXONOMY_LANGUAGES_SUCCESS';
export const GET_TAXONOMY_LANGUAGES_FAILURE = 'GET_TAXONOMY_LANGUAGES_FAILURE';

export const GET_TAXONOMY_COUNTRIES = 'GET_TAXONOMY_COUNTRIES';
export const GET_TAXONOMY_COUNTRIES_SUCCESS = 'GET_TAXONOMY_COUNTRIES_SUCCESS';
export const GET_TAXONOMY_COUNTRIES_FAILURE = 'GET_TAXONOMY_COUNTRIES_FAILURE';

export const SHOW_PLAYER = 'SHOW_PLAYER';
export const HIDE_PLAYER = 'HIDE_PLAYER';

export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT_SUCCESS = 'DELETE_USER_ACCOUNT_SUCCESS';
export const DELETE_USER_ACCOUNT_FAILURE = 'DELETE_USER_ACCOUNT_FAILURE';
