import React from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { THEME_BROWSER, THEME_DARK, THEME_LIGHT, DEFAULT_THEME } from 'constants/Constants';

export const useTheme = () => {
  const userTheme = useSelector(state => state?.user?.privateProfile?.theme) || DEFAULT_THEME;
  const isBrowserDark = useMediaQuery({ query: '(prefers-color-scheme: dark)' });

  let siteTeme = userTheme;
  if (userTheme === THEME_BROWSER) {
    siteTeme = isBrowserDark ? THEME_DARK : THEME_LIGHT;
  }

  return siteTeme;
};

export const withTheme = Component => props => {
  const theme = useTheme();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} theme={theme} />;
};
