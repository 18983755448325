import {
  LOGIN,
  REGISTER_USER,
  LOGOUT,
  GET_PUBLIC_PROFILE,
  GET_PRIVATE_PROFILE,
  GET_USER_ARTWORKS,
  GET_FAVORITE_ARTWORKS,
  MARK_ALL_AS_READ,
  REMOVE_LOCKED_FILES_ALERT,
  GET_ACCESSED_PACKS,
  ADD_FOLLOWED_ARTWORK,
  DELETE_FOLLOWED_ARTWORK,
  SUBSCRIBE_ARTWORK,
  UNSUBSCRIBE_ARTWORK,
  ACCEPT_ARTWORK,
  ACCEPT_TUTORIAL,
  BUY_PACK,
  UPDATE_USER,
  UPDATE_THEME,
  ADD_USER_CREDITCARD,
  REMOVE_USER_CREDITCARD,
  ORDERS_PLACE_QUOTE,
  ORDERS_PLACE_ORDER,
  GET_USER_INCOMES,
  GET_USER_BILLINGS,
  CART_SHOW,
  CART_HIDE,
  GET_USER_SUBSCRIPTION_BILLINGS,
  ORDERS_PLACE_QUOTE_MONTHLY,
  USER_DISMISS_NOTIFICATIONS,
  GET_USER_MARK_NOTIFICATIONS_AS_READ,
  SETUP_USER_PAYMENT_METHOD,
  GET_USER_MARK_NEWS_AS_READ,
  DELETE_USER_ACCOUNT,
} from '../constants/ActionTypes';

/**
 * return the current user public profile
 */
export function getPublicProfile(id) {
  return {
    type: GET_PUBLIC_PROFILE,
    id,
  };
}

/**
 * return the current user private profile
 */
export function getPrivateProfile() {
  return {
    type: GET_PRIVATE_PROFILE,
  };
}

export function markNoficationAsRead() {
  return {
    type: GET_USER_MARK_NOTIFICATIONS_AS_READ,
  };
}

export function markNewsAsRead() {
  return {
    type: GET_USER_MARK_NEWS_AS_READ,
  };
}

/**
 * return the user artworks
 */
export function getUserArtworks(id) {
  return {
    type: GET_USER_ARTWORKS,
    id,
  };
}

/**
 * return the user followed artworks
 */
export function getFavoriteArtworks(page) {
  return {
    type: GET_FAVORITE_ARTWORKS,
    page,
  };
}

export function markAllAsRead() {
  return {
    type: MARK_ALL_AS_READ,
  };
}

export function removeLockedFilesAlert() {
  return {
    type: REMOVE_LOCKED_FILES_ALERT,
  };
}

/**
 * return the user purchased pack
 */
export function getPackAccess(id) {
  return {
    type: GET_ACCESSED_PACKS,
    id,
  };
}

/**
 * add followed artwork
 */
export function addFollowedArtwork(params) {
  return {
    type: ADD_FOLLOWED_ARTWORK,
    params,
  };
}

/**
 * delete followed artwork
 */
export function deleteFollowedArtwork(params) {
  return {
    type: DELETE_FOLLOWED_ARTWORK,
    params,
  };
}

/**
 * subscribe to an artwork
 */
export function subscribeArtwork(params) {
  return {
    type: SUBSCRIBE_ARTWORK,
    params,
  };
}

/**
 * subscribe to an artwork
 */
export function unsubscribeArtwork(params) {
  return {
    type: UNSUBSCRIBE_ARTWORK,
    params,
  };
}

/**
 * buy a pack
 */
export function buyPack(packId, artworkId) {
  return {
    type: BUY_PACK,
    packId,
    artworkId,
  };
}

export function placeQuote(quote, purchase = {}, artwork) {
  return {
    type: ORDERS_PLACE_QUOTE,
    quote,
    purchase,
    artwork,
  };
}

export function placeQuoteMonthly() {
  return {
    type: ORDERS_PLACE_QUOTE_MONTHLY,
  };
}

export function placeOrder(quote, artworkId, isPlayer) {
  return {
    type: ORDERS_PLACE_ORDER,
    quote,
    artworkId,
    isPlayer,
  };
}

/**
 * accept adult content to an artwork
 */
export function acceptArtwork(artworkId, uniqueName, username) {
  return {
    type: ACCEPT_ARTWORK,
    artworkId,
    uniqueName,
    username,
  };
}

export function acceptTutorial(tutorialId) {
  return {
    type: ACCEPT_TUTORIAL,
    tutorialId,
  };
}

/**
 * dismiss global notifications
 */
export function dismissNotifications(date) {
  return {
    type: USER_DISMISS_NOTIFICATIONS,
    date,
  };
}

/**
 * logout the current user
 */
export function logout() {
  return {
    type: LOGOUT,
  };
}

/**
 * login with a given username and password
 * @param {string} username - the current username
 * @param {string} password - the current password
 */
export function login(formData, formikBag, isRegister, redirect) {
  return {
    type: LOGIN,
    formData,
    formikBag,
    isRegister,
    redirect,
  };
}

/**
 * create a user after the create user form
 * @param {object} registerForm - the parameters of the form
 */
export function register(formData, formikBag) {
  return {
    type: REGISTER_USER,
    formData,
    formikBag,
  };
}

export function updateTheme(theme) {
  return { type: UPDATE_THEME, theme };
}

/**
 * create a user after the create user form
 * @param {object} registerForm - the parameters of the form
 */
export function saveSettings(formData, formikBag, noRedirect, tabName) {
  return {
    type: UPDATE_USER,
    formData,
    formikBag,
    noRedirect,
    tabName,
  };
}

/**
 * setup user payment
 */
export function setupUserPaymentMethod(params) {
  return {
    type: SETUP_USER_PAYMENT_METHOD,
    params,
  };
}

/**
 * create a user after the create user form
 * @param {object} registerForm - the parameters of the form
 */
export function addCreditCard(params) {
  return {
    type: ADD_USER_CREDITCARD,
    params,
  };
}

export function removeCreditCard() {
  return {
    type: REMOVE_USER_CREDITCARD,
  };
}

/**
 * show cart
 */
export function showCart() {
  return {
    type: CART_SHOW,
  };
}

/**
 * hide cart
 */
export function hideCart() {
  return {
    type: CART_HIDE,
  };
}

export function getIncomes(page) {
  return {
    type: GET_USER_INCOMES,
    page,
  };
}

export function getBillings() {
  return {
    type: GET_USER_BILLINGS,
  };
}

export function getSubscriptionBillings() {
  return {
    type: GET_USER_SUBSCRIPTION_BILLINGS,
  };
}

export function deleteAccount() {
  return {
    type: DELETE_USER_ACCOUNT,
  };
}
