import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { THEME_DARK } from 'constants/Constants';

import { useTheme } from 'tools/theme';

import CommunSVGs from 'svgs/commun';

import ForgotForm, { MODE_FORGOT } from './Form/ForgotForm';
import LoginForm, { MODE_LOGIN } from './Form/LoginForm';
import RegisterForm, { MODE_REGISTER } from './Form/RegisterForm';
import styles from './LoginModal.module.scss';

interface IProps {
  mode: string;
  noDismiss?: boolean;
  shouldRedirect?: boolean;
  onClose(): void;
}

export default function LoginModal({ mode = MODE_LOGIN, noDismiss, shouldRedirect, onClose }: IProps) {
  const theme = useTheme();

  const [show, setShow] = useState(true);
  const [currentMode, setCurrentMode] = useState(mode);

  const handleClose = () => {
    if (onClose) onClose();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={!noDismiss ? handleClose : undefined} centered dialogClassName={styles.LoginModal}>
      <Modal.Body>
        <div className={styles.close} onClick={handleClose} role="presentation">
          <CommunSVGs.Close width="20" height="20" fill="var(--neutral)" />
        </div>
        <div className={styles.logo}>
          <img src={`/assets/images/flowfo-login${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
        </div>

        {currentMode === MODE_LOGIN && (
          <LoginForm
            onChangeMode={value => setCurrentMode(value)}
            onSuccess={onClose}
            shouldRedirect={shouldRedirect}
          />
        )}
        {currentMode === MODE_FORGOT && (
          <ForgotForm onChangeMode={value => setCurrentMode(value)} onSuccess={onClose} />
        )}
        {currentMode === MODE_REGISTER && (
          <RegisterForm
            onChangeMode={value => setCurrentMode(value)}
            onSuccess={onClose}
            shouldRedirect={shouldRedirect}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
