import { SECONDARY_NAV_OPEN } from 'constants/ActionTypes';

export default function user(state = {}, action) {
  switch (action.type) {
    case SECONDARY_NAV_OPEN:
      return {
        ...state,
        isSecondaryNavOpen: !state.isSecondaryNavOpen,
      };

    default:
      return state;
  }
}
