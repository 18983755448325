import api from './api';

const artwork = {
  getAll: (search, page, orderBy, languages, categories, exclusions, limit) =>
    api.get(
      `artworks.jsonld?page=${page}&search=${search}&orderBy=${orderBy}&languages=${languages.join(
        ',',
      )}&categories=${categories.join(',')}&exclusions=${exclusions.join(',')}${limit ? `&limit=${limit}` : ''}`,
    ),

  getByname: (userName, projectName) => api.get(`users/${userName}/artworks/${projectName}`),

  getById: id => api.get(`artworks/${id}`),

  getByAuthorId: id => api.get(`users/${id}/artworks`),

  getFile: id => api.get(`files/${id}`),

  getFiles: (artworkId, page, itemsPerPage) => {
    if (page === 'all') {
      return api.get(`artworks/${artworkId}/artwork_files.jsonld?pagination=false`);
    }
    return api.get(
      `artworks/${artworkId}/artwork_files.jsonld?page=${page}${itemsPerPage ? `&itemsPerPage=${itemsPerPage}` : ''}`,
    );
  },

  post: params => api.post('artworks', params),

  put: params => api.put(`artworks/${params.id}`, params),

  delete: id => api.delete(`artworks/${id}`),

  putFile: params => api.put(`files/${params.id}`, params),

  deleteFile: id => api.delete(`files/${id}`),

  reorderFile: (id, sort) => api.get(`files/${id}/sort/${sort}`),

  markFileAsRead: id => api.get(`files/${id}/mark_as_read`),

  validateInvitationCode: code => api.get(`artworks/valid_invitation_code/${code}`),

  markFlowAsRead: id => api.get(`artworks/${id}/mark_flow_as_read`),

  suggest: id => api.get(`artworks/${id}/suggest`),

  markNotInterested: id => api.get(`artworks/${id}/mark_not_interested`),

  markNewsAsRead: id => api.get(`artworks/${id}/mark_news_as_read`),
};

export default artwork;
